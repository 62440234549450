export const RELATED_TAB_CLIENT_DISPLAY_FILTER_LIST = [
	{ value: 'RATE_GROUP', label: 'RATE GROUPS', icon: '' },
	{ value: 'WORK_HISTORY', label: 'WORK HISTORY', icon: '' },
];

export const RELATED_TAB_DEFAULT_FILTER_LIST = [{ value: 'RATE_GROUP', label: 'RATE GROUPS', icon: '' }];

export enum RelatedTabDisplayFilterType {
	RATE_GROUP = 0,
	WORK_HISTORY = 1,
}
