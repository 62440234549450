import { Clipboard } from '@angular/cdk/clipboard';
import { formatDate, formatNumber } from '@angular/common';
import { Injectable, Injector, NgZone } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { isDate } from 'moment';
import * as moment from 'moment';
import 'moment-timezone';
import { Message, MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { ActionResult, ActionResultMessage, ActionResultMessageType } from 'src/app/shared/models/api';
import { BusinessEntityLookupValue, CodeValue } from 'src/app/shared/models/lookups';
import { LookupValue } from 'src/app/shared/models/lookups';
import { RegionSettings } from 'src/app/shared/models/settings';
import { Guid } from 'src/app/core/models';
import { SpinnerComponent } from '../components/spinner.component';
import { FileExtensions } from '../constants/file-extensions.constant';
import { ValidationConstants } from '../constants/validation.constant';
import { AppContextService } from './app-context.service';

export const convertDotNetToMomentDefinition = (format: string) => {
	const result = format
		.replace(/yyyy/g, '*01*')
		.replace(/yy/g, '*02*')
		.replace(/y/g, '*03*')
		.replace(/dddd/g, '*04*')
		.replace(/ddd/g, '*05*')
		.replace(/dd/g, '*06*')
		.replace(/d/g, '*07*')
		.replace(/tt/g, '*08*')
		.replace('*01*', 'YYYY')
		.replace('*02*', 'YY')
		.replace('*03*', 'Y')
		.replace('*04*', 'DDDD')
		.replace('*05*', 'DDD')
		.replace('*06*', 'DD')
		.replace('*07*', 'D')
		.replace('*08*', 'A');

	return result;
};
@Injectable({ providedIn: 'root' })
export class UtilService {
	spinnerComp: SpinnerComponent;
	constructor(
		private messageService: MessageService,
		private ngZone: NgZone,
		private router: Router,
		private confirmationService: ConfirmationService,
		private injector: Injector,
		private clipboard: Clipboard
	) {}

	newGuid() {
		return Guid.newGuid();
	}
	//#region  Growl Messages
	showSuccess(message: string, summary: string = null) {
		this.showMessage('success', message, summary);
	}
	showWarning(message: string, summary: string = null) {
		this.showMessage('warn', message, summary);
	}
	showInfo(message: string, summary: string = null) {
		this.showMessage('info', message, summary);
	}
	showError(message: string, summary: string = null) {
		console.log('showError', message);
		this.showMessage('error', message, summary);
	}
	showMessage(severity: string, message: string, summary: string) {
		this.showClearAllMsg(severity);
		this.messageService.add({
			severity: severity,
			summary: summary,
			detail: message,
			sticky: true,
		});
	}
	showClearAllMsg(severity: string) {
		this.messageService.add({
			severity: severity,
			sticky: true,
			key: 'clearAll',
			closable: false,
		});
	}

	calendarDateWithoutSeconds(date: string | Date): Date {
		if (date == null) return null;

		const ndate: Date = new Date(date);
		if (isDate(ndate)) {
			ndate.setSeconds(0, 0);
			return ndate;
		} else return null;
	}
	showActionResultMessages(actionResultMessages: ActionResultMessage[], summary: string = null) {
		try {
			const messages = actionResultMessages
				.filter(m => m.MessageType != ActionResultMessageType.Question)
				.sort((a, b) => a.MessageType - b.MessageType)
				.map(m => {
					let sev = 'info';
					let sticky = false;
					switch (m.MessageType) {
						case ActionResultMessageType.Error:
						case ActionResultMessageType.UnauthorizedAccess:
							sev = 'error';
							sticky = true;
							break;
						case ActionResultMessageType.Warning:
							sev = 'warn';
							sticky = true;
							break;
						case ActionResultMessageType.Notification:
							sev = 'info';
							sticky = true;
							break;
						case ActionResultMessageType.Success:
							sev = 'success';
							sticky = true;
							break;
					}
					return {
						severity: sev,
						summary: summary,
						detail: m.Message,
						sticky: sticky,
					} as Message;
				});

			if (messages.length >= 1) this.showClearAllMsg(messages[0].severity);

			this.messageService.addAll(messages);
		} catch (e) {
			console.error(e);
		}
	}
	showActionResult(actionResult: ActionResult, summary: string = null) {
		try {
			const resultSeverity = actionResult.IsSuccess ? 'success' : 'error';
			let tMsg: any;
			try {
				if (actionResult.Messages && actionResult.Messages.length === 1) {
					tMsg = JSON.parse(actionResult.Messages[0].Message);
					if (!(tMsg && tMsg.Messages)) {
						tMsg = actionResult;
					}
				} else {
					tMsg = actionResult;
				}
			} catch (e) {
				tMsg = actionResult;
			}
			const messages = tMsg.Messages.filter(m => m.MessageType != ActionResultMessageType.Question)
				.sort((a, b) => a.MessageType - b.MessageType)
				.map(m => {
					let sev = 'info';
					let sticky = false;
					switch (m.MessageType) {
						case ActionResultMessageType.Error:
						case ActionResultMessageType.UnauthorizedAccess:
							sev = 'error';
							sticky = true;
							break;
						case ActionResultMessageType.Warning:
							sev = 'warn';
							sticky = true;
							break;
						case ActionResultMessageType.Notification:
							sev = 'info';
							sticky = true;
							break;
						case ActionResultMessageType.Success:
							sev = 'success';
							sticky = true;
							break;
					}
					return {
						severity: sev,
						summary: summary,
						detail: m.Message,
						sticky: sticky,
					} as Message;
				});

			if (messages.length >= 1) this.showClearAllMsg(messages[0].severity);

			this.messageService.addAll(messages);
		} catch (e) {
			console.error(e);
		}
	}

	clearMessages() {
		this.messageService.clear();
	}

	//#endregion

	//#region  ConfimrationMessages

	private confirm(message: string, header: string, icon: string, acceptLabel = 'Yes', rejectVisible = true): Promise<boolean> {
		return new Promise(resolve => {
			this.confirmationService.confirm({
				message: message,
				header: header,
				icon: icon,
				acceptLabel: acceptLabel,
				rejectVisible: rejectVisible,
				accept: () => {
					resolve(true);
				},
				reject: () => {
					resolve(false);
				},
			});
		});
	}

	confirmDelete(message: string, header = 'Confirm Delete'): Promise<boolean> {
		return this.confirm(message, header, 'pi pi-trash');
	}

	confirmSave(message: string, header = 'Confirm Save'): Promise<boolean> {
		return this.confirm(message, header, 'pi pi-save');
	}

	confirmAmend(message: string, header = 'Confirm Amend'): Promise<boolean> {
		return this.confirm(message, header, 'pi pi-step-backward');
	}

	confirmCancel(message: string, header = 'Confirm Cancel'): Promise<boolean> {
		return this.confirm(message, header, 'pi pi-ban');
	}

	confirmQuestion(message: string, header = 'Question'): Promise<boolean> {
		return this.confirm(message, header, 'pi pi-question-circle');
	}

	confirmSaveCustomParams(message: string, header: string, icon: string): Promise<boolean> {
		return this.confirm(message, header, icon);
	}

	confirmMove(message: string, header = 'Confirm Move'): Promise<boolean> {
		return this.confirm(message, header, 'pi pi-arrows-alt');
	}
	//#endregion

	//#region TimeZone handling
	// eslint-disable-next-line
	fixDates(obj: Object, defaultTimeZoneCode: string, isIncoming = true): void {
		if (obj) {
			let timeZoneCode = obj['TimeZoneCode'];
			if (!timeZoneCode) {
				defaultTimeZoneCode = moment.tz.guess();
				timeZoneCode = defaultTimeZoneCode;
			}
			let excludeFromTimezone = obj['ExcludeFromTimezone'];
			if (!excludeFromTimezone) {
				excludeFromTimezone = [];
			}

			for (const property in obj) {
				if (property == 'DateTimeFormat' || property == 'DateFormat' || property.endsWith('Id')) continue;
				// eslint-disable-next-line
				if (obj.hasOwnProperty(property) && obj[property] != null && !excludeFromTimezone.includes(property)) {
					if (timeZoneCode && (obj[property] instanceof Date || (typeof obj[property] === 'string' && property.indexOf('Date') > -1))) {
						obj[property] = this.fixTimeZones(obj[property], timeZoneCode, isIncoming);
					} else if (typeof obj[property] === 'object') {
						this.fixDates(obj[property], timeZoneCode, isIncoming);
					}
				} else if (excludeFromTimezone.includes(property) && isIncoming) {
					obj[property] = this.setExcludeFromTimeZoneDate(obj[property]);
				}
			}
		}
	}

	fixTimeZones(inDate: any | string, timeZoneCode: string, isIncoming = true): Date {
		let resultDate: Date = inDate;

		if (inDate) {
			if (!moment(inDate).isValid()) {
				return resultDate;
			}
			const beforeDate = inDate;
			if (timeZoneCode) {
				const momentTimeZone = timeZoneCode ?? moment.tz.guess();

				if (isIncoming) {
					//parse the date written in timezone in browser.
					resultDate = moment(moment.tz(beforeDate, momentTimeZone).format('YYYY-MM-DD HH:mm:ss.SSS')).toDate();
				} else {
					//parse the date written in browser time in moment timezone.
					resultDate = moment(moment.tz(moment(beforeDate).format('YYYY-MM-DD HH:mm:ss.SSS'), momentTimeZone)).toDate();
				}
			} else {
				resultDate = beforeDate;
			}
		}
		return resultDate;
	}

	convertTextToDate(inputDate: string, timezoneCode: string): Date {
		if (inputDate) {
			if (!moment(inputDate).isValid()) {
				return null;
			}

			if (timezoneCode) return moment.utc(inputDate).tz(timezoneCode).toDate();

			return moment.utc(inputDate).toDate();
		}
	}

	formatAsDate(inputDate: any | string): string {
		if (inputDate) {
			if (!moment(inputDate).isValid()) {
				return null;
			}

			return moment(inputDate).format('YYYY-MM-DD');
		}
	}

	formatAsDateTime(inputDate: any | string): string {
		if (inputDate) {
			if (!moment(inputDate).isValid()) {
				return null;
			}

			return moment(inputDate).format('YYYY-MM-DD HH:mm');
		}
	}

	formatAsTime(inputDate: any | string): string {
		if (inputDate) {
			if (!moment(inputDate).isValid()) {
				return null;
			}

			return moment(inputDate).format('HH:mm');
		}
	}

	getMomentType(recurrenceType: string): string {
		let momentType: string;
		switch (recurrenceType) {
			case 'Weekly': {
				momentType = 'weeks';
				break;
			}
			case 'Monthly': {
				momentType = 'months';
				break;
			}
			case 'Yearly': {
				momentType = 'years';
				break;
			}
			default: {
				momentType = 'days';
			}
		}
		return momentType;
	}
	//#endregion
	stringifySubJsonFields = function (obj) {
		try {
			return JSON.stringify(obj, function (key, value) {
				if (key.length >= 4 && key.slice(-4).toUpperCase() === 'JSON' && value && typeof value === 'object') {
					return this.stringifySubJsonFields(value);
				} else {
					return value;
				}
			});
		} catch (e) {
			console.error(e);
			return obj;
		}
	};
	parseSubJsonFields = obj => {
		try {
			return JSON.parse(JSON.stringify(obj), function (key, value) {
				if (key.length >= 4 && key.slice(-4).toUpperCase() === 'JSON' && value && typeof value === 'string') {
					try {
						return this.parseSubJsonFields(JSON.parse(value));
					} catch (e) {
						console.error('Property is not a valid JSON', key, value);
						return value;
					}
				} else {
					if (key === 'DateFormat' || key === 'DateTimeFormat') {
						return value;
					}
					if (key.length >= 4 && (key.slice(-4).toUpperCase() === 'DATE' || key.slice(0, 4).toUpperCase() === 'DATE') && value && typeof value === 'string') {
						try {
							if (
								value === '0001-01-01 00:00:00.000 +00:00' ||
								value === '0001-01-01 00:00:00.000' ||
								value === '0001-01-01' ||
								value === '00:00:00.000' ||
								value === '00:00:00'
							) {
								return null;
							} else {
								return moment(value).toDate();
							}
						} catch (e) {
							console.error('Property is not a valid date', key, value);
							return value;
						}
					} else {
						if (key.length >= 2 && key.slice(-2).toUpperCase() === 'ID' && value && typeof value === 'string') {
							try {
								if (value === '00000000-0000-0000-0000-000000000000') {
									return undefined;
								} else {
									return value;
								}
							} catch (e) {
								console.error('Property is not a valid ID', key, value);
								return value;
							}
						} else {
							return value;
						}
					}
				}
			});
		} catch (e) {
			console.error(e);
			return obj;
		}
	};

	deepClone<T>(sourceObj: T): T {
		//return JSON.parse(JSON.stringify(sourceObj)) as T;
		return this.parseSubJsonFields(sourceObj) as T;
	}

	revertChanges<T>(sourceObj: T, targetObj: T) {
		//intial snapshot of the object with all properties
		const targetObjBackUp = this.deepClone(targetObj);

		//assign all properties to null values.
		Object.keys(targetObjBackUp).forEach(key => (targetObjBackUp[key] = null));

		//Copy from the sourceObj to the targetObjBackUp, if the object doesnt have any property it will still be a null value in the actualObj.
		//This ensures that the object has all the properties.
		Object.assign(targetObjBackUp, sourceObj);

		//Assign back the targetObjBackUp to actual values.
		Object.assign(targetObj, targetObjBackUp);
	}

	stringifyObject<T>(sourceObj: T): string {
		return this.stringifySubJsonFields(sourceObj);
	}

	parseObject(sourceObj: string): any {
		const retObj = this.deepClone(sourceObj);
		this.fixDates(retObj, null, false);
		return retObj;
	}

	getRecordValueIntoLookup(recordValueCode: string, recordValueName: string): LookupValue {
		if (recordValueCode !== null && recordValueCode !== '') {
			return new LookupValue({
				CodeValue: recordValueCode,
				NameValue: recordValueName,
				ExtendedCodeValue: recordValueCode,
				ExtendedNameValue: recordValueName,
			});
		}
	}

	getRecordValueIdIntoLookup(recordValueId: string, recordValueName: string, allowBlankValueId = false): LookupValue {
		if (allowBlankValueId || (recordValueId !== null && recordValueId !== '')) {
			return new LookupValue({
				LookupValueId: recordValueId,
				CodeValue: recordValueName,
				NameValue: recordValueName,
				ExtendedCodeValue: recordValueName,
				ExtendedNameValue: recordValueName,
			});
		}

		return null;
	}

	getPatchValue(isClientUser: boolean, codeValue: string, nameValue: string, lookupContainer: LookupValue[]): LookupValue {
		let patchData: LookupValue;
		if (isClientUser) {
			if (codeValue) {
				patchData = this.getRecordValueIntoLookup(codeValue, nameValue);
				lookupContainer = [patchData];
			}
		} else {
			return lookupContainer.find(x => x.CodeValue && x.CodeValue === codeValue);
		}
		return patchData;
	}

	getPatchValueConstant(isClientUser: boolean, codeValue: string, lookupContainer: LookupValue[], constantValues: LookupValue[]): LookupValue {
		const nameValue = constantValues.find(val => val.CodeValue === codeValue)?.NameValue;

		let patchData: LookupValue;
		if (isClientUser) {
			if (codeValue) {
				patchData = this.getRecordValueIntoLookup(codeValue, nameValue);
				lookupContainer = [patchData];
			}
		} else {
			return lookupContainer.find(x => x.CodeValue && x.CodeValue === codeValue);
		}
		return patchData;
	}

	getPatchValueId(isClientUser: boolean, lookupValueId: string, nameValue: string, lookupContainer: LookupValue[]): LookupValue {
		let patchData: LookupValue;
		if (isClientUser) {
			if (lookupValueId) {
				patchData = this.getRecordValueIdIntoLookup(lookupValueId, nameValue);
				lookupContainer = [patchData];
			}
		} else {
			return lookupContainer.find(x => x.LookupValueId && x.LookupValueId === lookupValueId);
		}
		return patchData;
	}

	// Sometimes a lookup may not contain the value that exists in the record, so the form component
	// may fail to patch the value onto the form. This function is used to add these values into the lookup
	addRecordValueIntoLookup(recordValueCode: string, recordValueName: string, lookupArray: LookupValue[], additionalDetails: any = null): LookupValue {
		if (recordValueCode != null && recordValueCode != '') {
			let obj = lookupArray.find(x => x.CodeValue === recordValueCode);
			if (obj == null) {
				obj = new LookupValue({
					CodeValue: recordValueCode,
					NameValue: recordValueName,
					ExtendedCodeValue: recordValueCode,
					ExtendedNameValue: recordValueName,
					AdditionalDetails: additionalDetails,
				});
				lookupArray.unshift(obj);
				return obj;
			}
		}

		return null;
	}

	addRecordValueIdIntoLookup(recordValueId: string, recordValueName: string, lookupArray: LookupValue[], allowBlankValueId = false): LookupValue {
		if (allowBlankValueId || (recordValueId != null && recordValueId != '')) {
			let obj = lookupArray.find(x => x.LookupValueId === recordValueId);
			if (obj == null) {
				obj = new LookupValue({
					LookupValueId: recordValueId,
					CodeValue: recordValueName,
					NameValue: recordValueName,
					ExtendedCodeValue: recordValueName,
					ExtendedNameValue: recordValueName,
				});
				lookupArray.unshift(obj);
				return obj;
			}
		}

		return null;
	}

	addRecordValueIdIntoLookupParent(
		recordValueId: string,
		recordValueName: string,
		lookupArray: LookupValue[],
		parentLookupValueId: string,
		parentLookupName: string
	): LookupValue {
		if (recordValueId != null && recordValueId != '') {
			let obj = lookupArray.find(x => x.LookupValueId === recordValueId);
			if (obj == null) {
				obj = new LookupValue({
					LookupValueId: recordValueId,
					CodeValue: recordValueName,
					NameValue: recordValueName,
					ExtendedCodeValue: recordValueName,
					ExtendedNameValue: recordValueName,
					ParentLookupValueId: parentLookupValueId,
					ParentLookupName: parentLookupName,
				});
				lookupArray.unshift(obj);
				return obj;
			}
		}

		return null;
	}

	addBusinessEntityIntoLookup(
		recordValueId: string,
		recordValueName: string,
		lookupArray: BusinessEntityLookupValue[],
		taxCode: string
	): BusinessEntityLookupValue {
		if (recordValueId != null && recordValueId != '') {
			let obj = lookupArray.find(x => x.LookupValueId === recordValueId);
			if (obj == null) {
				obj = new BusinessEntityLookupValue({
					LookupValueId: recordValueId,
					CodeValue: recordValueName,
					NameValue: recordValueName,
					ExtendedCodeValue: recordValueName,
					ExtendedNameValue: recordValueName,
					TaxCode: taxCode,
				});
				lookupArray.unshift(obj);
				return obj;
			}
		}

		return null;
	}

	getRecordValueId(lookup: LookupValue[] = []) {
		return (lookup ?? [])
			.filter(x => x != null)
			.map(
				x =>
					new CodeValue({
						LookupValueId: x.LookupValueId,
						NameValue: x.NameValue,
					})
			);
	}

	deriveAddressStringFromAddress(item: any) {
		if (!item) return '';
		let address = item.Street;
		if (item.AssetReference) address = address ? `${item.AssetReference}, ${address}` : item.AssetReference;
		if (item.Suburb) address = address ? `${address}, ${item.Suburb}` : item.Suburb;
		if (item.Postcode) address = address ? `${address}, ${item.Postcode}` : item.Postcode;
		return address;
	}

	//#region FileHelpers

	ConvertToCSV(objArray: any[], columns: any[] = null) {
		let dataArray = objArray;
		if (columns != null) {
			const col = columns.map(r => r.field);
			const updatedarray = dataArray.map(r => {
				const obj = {};
				for (const prop of col) {
					for (const prop1 in r) {
						if (prop === prop1) {
							obj[prop1] = r[prop1];
						}
					}
				}
				for (const prop in r) {
					if (col.includes(prop)) {
						obj[prop] = r[prop];
					}
				}
				return obj;
			});
			dataArray = updatedarray;
		}

		const array = typeof dataArray !== 'object' ? JSON.parse(dataArray) : dataArray;
		let str = '';
		let row = '';

		if (columns != null) {
			for (const index of columns) {
				// 	//Now convert each value to string and comma-separated
				row += index.header + ',';
			}
		} else {
			// eslint-disable-next-line guard-for-in
			for (const index in dataArray[0]) {
				//Now convert each value to string and comma-separated
				row += index + ',';
			}
		}

		row = row.slice(0, -1);
		//append Label row with line break
		str += row + '\r\n';

		for (let i = 0; i < array.length; i++) {
			let line = '';
			// eslint-disable-next-line guard-for-in
			for (const index in array[i]) {
				if (line !== '') {
					line += ',';
				}
				line += array[i][index];
			}
			str += line + '\r\n';
		}
		return str;
	}

	downloadFile(file: any, fileName: any) {
		const url = file;
		fetch(url)
			.then(res => res.blob())
			.then(blob => {
				const blob1 = new Blob([blob], { type: blob.type });
				this.downloadContent(blob1, fileName);
			});
	}

	downloadFileUrl(url: string, fileName: any) {
		const a = document.createElement('a');
		a.href = url;
		a.download = fileName;
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
	}

	downloadContent(content: Blob, fileName: any) {
		const url = window.URL.createObjectURL(content);
		this.downloadFileUrl(url, fileName);
		window.URL.revokeObjectURL(url);
	}

	downloadCSVFile(data: any, fileName: string) {
		const replacer = (key, value) => (value === null ? '' : value); // handle null values
		const header = Object.keys(data[0]);
		const csv = data.map(row => header.map(headerName => JSON.stringify(row[headerName], replacer)).join(','));
		csv.unshift(header.join(','));
		const csvArray = csv.join('\r\n');

		const blob = new Blob([csvArray], { type: 'text/csv' });
		this.downloadContent(blob, fileName + '.csv');
	}

	downloadDataAsCSV(data: any[], cols: any[], fileName: string) {
		const csvData = this.ConvertToCSV(data, cols);
		const blob = new Blob([csvData], { type: 'text/csv' });
		this.downloadContent(blob, fileName);
	}

	isValidImage(fileName: string): boolean {
		let flag = true;
		const fileType = fileName.substr(fileName.lastIndexOf('.') + 1);
		const validImageTypes = FileExtensions.Image_Extension;
		if (validImageTypes.filter(e => e === fileType.toLowerCase()).length === 0) {
			flag = false;
		}
		return flag;
	}

	getFileIcon(fileName: string): string {
		const iconName = '';
		const fileExtension = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();
		let fileExtensions = FileExtensions.Excel_Extension; // Excel
		if (fileExtensions.filter(e => e === fileExtension.toLowerCase()).length !== 0) {
			return 'pi pi-file-excel';
		}
		// fileExtensions = ["doc", "docx"]; // Word
		// if (fileExtensions.filter(e => e === fileExtension.toLowerCase()).length !== 0) {
		// 	return "fas fa-file-word";
		// }
		fileExtensions = FileExtensions.Pdf_Extension; // Pdf
		if (fileExtensions.filter(e => e === fileExtension.toLowerCase()).length !== 0) {
			return 'pi pi-file-pdf';
		}

		fileExtensions = FileExtensions.Image_Extension; //image
		if (fileExtensions.filter(e => e === fileExtension.toLowerCase()).length !== 0) {
			return 'pi pi-image';
		}
		return 'pi pi-file';
	}

	convertToArrayBuffer(s: any) {
		const buf = new ArrayBuffer(s.length);
		const view = new Uint8Array(buf);
		for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
		return buf;
	}
	//#endregion

	//#region Spinner

	registerSpinner(spinner: SpinnerComponent) {
		this.spinnerComp = spinner;
	}

	showWait() {
		if (this.spinnerComp) {
			this.spinnerComp.show = true;
		}
	}

	hideWait(sendTrigger = true) {
		if (this.spinnerComp) {
			this.spinnerComp.show = false;
			if (sendTrigger) {
				$(document).trigger('HideWait');
			}
		}
	}

	//#endregion

	navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
		return this.ngZone
			.run(() => {
				return this.router.navigate(commands, extras);
			})
			.then(navigateResult => {
				return navigateResult;
			})
			.catch(navigateResult => {
				return navigateResult;
			});
	}

	navigateByUrl(url: string, extras?: NavigationExtras): Promise<boolean> {
		return this.ngZone
			.run(() => {
				return this.router.navigateByUrl(url, extras);
			})
			.then(navigateResult => {
				return navigateResult;
			})
			.catch(navigateResult => {
				return navigateResult;
			});
	}
	clearObjectProperties(obj: any) {
		const props = Object.keys(obj);
		for (let i = 0; i < props.length; i++) {
			delete obj[props[i]];
		}
	}

	//inplace filtering from array
	splice<T>(arr: T[], predicate: (a: T) => boolean): T[] {
		const deletedElements = [];
		for (let i = arr.length - 1; i >= 0; i--) {
			const element = arr[i];
			if (predicate(element)) {
				deletedElements.push(arr.splice(i, 1));
			}
		}
		return deletedElements;
	}
	autoMapper(sourceObj: any, destinationObj: any) {
		Object['autoMapper'](sourceObj, destinationObj);
	}
	isEmail(value: string) {
		const emailType = new RegExp(ValidationConstants.emailRegExp);
		return emailType.test(value);
	}
	isPhoneNumber(value: string) {
		if (!value || value === null || value === '') {
			return true;
		}

		const phoneNumberType = new RegExp(ValidationConstants.phoneNumberRegExp);
		return phoneNumberType.test(value);
	}
	isPassword(value: string) {
		const passwordType = new RegExp(ValidationConstants.passwordRegExp);
		return passwordType.test(value);
	}

	convertToLocalTime(utcDateValue: string, timezoneCode: string): Date {
		if (utcDateValue) {
			let timeZone = moment.tz.guess();
			if (timezoneCode) {
				timeZone = timezoneCode ?? moment.tz.guess();
			}
			const utc = moment.utc(utcDateValue, 'YYYY-MM-DD HH:mm');
			const formattedDate = utc.tz(timeZone);
			return formattedDate.toDate();
		}
		return null;
	}

	getExcludeFromTimeZoneDate(localDate: Date): Date {
		if (!localDate) {
			return localDate;
		}

		const momentDate = moment(localDate);
		const momentNewDate = moment(momentDate.format('YYYY-MM-DDTHH:mm:ss') + 'Z');
		return momentNewDate.toDate();
	}

	setExcludeFromTimeZoneDate(localDate: Date): Date {
		if (!localDate) {
			return localDate;
		}

		const offset = moment(localDate).format('Z');
		const momentDate = moment(localDate);
		const newDateString = momentDate.utc().format('YYYY-MM-DDTHH:mm:ss') + offset;
		const momentNewDate = moment(newDateString);
		return momentNewDate.toDate();
	}

	getTimespanFromString(obj: string): Date {
		if (obj == null || obj == '') return null;

		const [hh, mm, ss] = obj.split(':');
		return new Date(2020, 1, 1, parseInt(hh), parseInt(mm), parseInt(ss));
	}

	getStringFromTimespan(obj: Date): string {
		if (obj == null) return null;

		const hours = obj.getHours().toString();
		const minutes = obj.getMinutes().toString().padStart(2, '0');

		return hours + ':' + minutes + ':00';
	}

	getFormattedValue(value: any, region: RegionSettings): string {
		if (value == null) return null;

		if (Object.prototype.toString.call(value) === '[object Date]' && !isNaN(value)) {
			return formatDate(value, region.DateTimeFormat, region.RegionCode);
		}

		if (moment(value, region.TimeFormat, true).isValid()) {
			return formatDate(value, region.TimeFormat, region.RegionCode);
		}

		if (typeof value === 'number') {
			return formatNumber(value, region.RegionCode, region.NumberFormat);
		}

		return value.toString()?.trim() ?? null;
	}

	getFormattedCurrency(value: any, region: RegionSettings): string {
		return formatNumber(value ?? 0, region.RegionCode, '1.' + region.CurrencyPrecision.toString() + '-' + region.CurrencyPrecision.toString());
	}

	getFormattedDate = (date: Date | null, format: string, timezoneCode?: string) => {
		if (!date || !format) return '';
		format = convertDotNetToMomentDefinition(format);
		const m = moment(date);

		// sometimes date presented as string (this usually happens when data is retrieved from a backend)
		// string date is in UTC format - therefore should be formatted according to timezone
		if (timezoneCode) {
			m.tz(timezoneCode);
		}
		const result = m.format(format).toUpperCase();
		return result;
	};

	ifEqualReplace(value: string, compareValue: string, replaceWithValue: string): string {
		if (value == compareValue) return replaceWithValue;

		return value;
	}

	isValidNumber(value: number): boolean {
		if (value === null || value === undefined || isNaN(value)) return false;
		else return true;
	}

	getBooleanFromString(value: string): boolean {
		if (!value) return false;

		value = value.toLowerCase();
		return value == 'true' || value == 'yes';
	}

	getViewByFilterList(code: string): string[] {
		const appContext = this.injector.get(AppContextService);
		const value = appContext.getViewByFilter();
		if (value == null) return [];
		const obj = [];
		for (const entry of value.split(';')) {
			const pair = entry.split(',');
			if (pair[0] == code) {
				if (pair[1] == '') obj.push(null);
				else obj.push(pair[1]);
			}
		}
		return obj;
	}

	getStyleForReferenceType(codeValue: string) {
		switch (codeValue) {
			case Constants.ReferenceType.WorkOrder:
				return Constants.ReferenceTypeCSSClass.WorkOrder;
			case Constants.ReferenceType.PurchaseOrder:
				return Constants.ReferenceTypeCSSClass.PurchaseOrder;
			case Constants.ReferenceType.QuoteRequest:
				return Constants.ReferenceTypeCSSClass.Quote;
			case Constants.ReferenceType.Scope:
				return Constants.ReferenceTypeCSSClass.Scope;
			case Constants.ReferenceType.ServiceRequest:
				return Constants.ReferenceTypeCSSClass.ServiceRequest;
			case Constants.ReferenceType.Invoice:
				return Constants.ReferenceTypeCSSClass.Invoice;
			case Constants.ReferenceType.Asset:
				return Constants.ReferenceTypeCSSClass.Asset;
			default:
				return Constants.ReferenceTypeCSSClass.Default;
		}
	}

	getStyleForRecordType(codeValue: string) {
		return 'rec-type-' + codeValue.toLowerCase();
	}

	getRecordTypeCodeForReferenceType(
		referenceTypeCode: string,
		businessEntityRelationTypeCode: string,
		assetTypeCode: string,
		purchaseOrderTypeCode: string,
		invoiceTypeCode: string
	) {
		switch (referenceTypeCode) {
			case Constants.ReferenceType.Client:
				return Constants.RecordType.Client;
			case Constants.ReferenceType.QuoteRequest:
				return Constants.RecordType.QuoteRequest;
			case Constants.ReferenceType.Scope:
				return Constants.RecordType.Scope;
			case Constants.ReferenceType.ServiceRequest:
				return Constants.RecordType.ServiceRequest;
			case Constants.ReferenceType.WorkOrder:
				return Constants.RecordType.WorkOrder;
			case Constants.ReferenceType.RateGroup:
				return Constants.RecordType.RateGroup;
			case Constants.ReferenceType.Sor:
				return Constants.RecordType.Sor;
			case Constants.ReferenceType.Problem:
				return Constants.RecordType.Problem;

			case Constants.ReferenceType.Invoice:
				switch (invoiceTypeCode) {
					case Constants.InvoiceType.Payable:
						return Constants.RecordType.PayableInvoice;
					case Constants.InvoiceType.PurchasesCreditNote:
						return Constants.RecordType.PayableInvoice;
					case Constants.InvoiceType.Receivable:
						return Constants.RecordType.ReceivableInvoice;
					case Constants.InvoiceType.SalesCreditNote:
						return Constants.RecordType.ReceivableInvoice;
					default:
						return null;
				}

			case Constants.ReferenceType.Asset:
				switch (assetTypeCode) {
					case Constants.AssetType.Property:
						return Constants.RecordType.Property;
					case null:
						return null;
					default:
						return Constants.RecordType.Asset;
				}

			case Constants.ReferenceType.BusinessEntity:
				switch (businessEntityRelationTypeCode) {
					case Constants.BusinessEntityRelationType.Contractor:
						return Constants.RecordType.Contractor;
					case Constants.BusinessEntityRelationType.Employee:
						return Constants.RecordType.Employee;
					case Constants.BusinessEntityRelationType.Supplier:
						return Constants.RecordType.Supplier;
					default:
						return null;
				}

			case Constants.ReferenceType.PurchaseOrder:
				switch (purchaseOrderTypeCode) {
					case Constants.PurchaseOrderType.Credit:
						return Constants.RecordType.CreditPurchaseOrder;
					case Constants.PurchaseOrderType.Defect:
						return Constants.RecordType.Defect;
					case Constants.PurchaseOrderType.DefectNotification:
						return Constants.RecordType.Defect;
					case Constants.PurchaseOrderType.Recovery:
						return Constants.RecordType.RecoveryPurchaseOrder;
					case Constants.PurchaseOrderType.Standard:
						return Constants.RecordType.PurchaseOrder;
					default:
						return null;
				}

			default:
				return null;
		}
	}

	copyContent(text: string) {
		this.clipboard.copy(text);
		return;
	}

	// https://stackoverflow.com/questions/20712419/get-utc-offset-from-timezone-in-javascript
	public getOffset(date: Date, timezoneCode: string): string {
		const timeZoneName = Intl.DateTimeFormat('ia', {
			timeZoneName: 'short',
			timeZone: timezoneCode,
		})
			.formatToParts(date)
			.find(i => i.type === 'timeZoneName').value;

		return timeZoneName;
	}

	public getOffsets(startDate: Date, endDate: Date, timezoneCode: string): string {
		const startOffset = this.getOffset(startDate, timezoneCode);
		const endOffset = this.getOffset(endDate, timezoneCode);

		if (startOffset == endOffset) return startOffset;
		else return `${startOffset} - ${endOffset}`;
	}

	public pTableCurrencyFilter(value: any, id: any, table: Table) {
		let filterValue = value;
		if (value !== null) {
			if (value === 0) {
				filterValue = '0.00';
			}
			table.filters[id][0].value = filterValue.toString();
		}
	}

	pTableNumericFilter(value: any, id: any, table: Table) {
		const filterValue = value;
		if (value !== null) {
			table.filters[id][0].value = filterValue.toString();
		}
	}

	pTableDateFilter(value: any, id: any, table: Table) {
		if (value != null) table.filters[id][0].value = value;
	}

	pTableCheckboxFilter(value: any, id: any, table: Table) {
		if (value != null) table.filters[id][0].value = value;
	}
}
