import { LookupValue } from 'src/app/shared/models/lookups';

export class Constants {
	public static GuidEmpty = '00000000-0000-0000-0000-000000000000';
	public static SearchDelay = 500;

	// #region -- App Constants --

	public static BasePageCSSClass = {
		Default: '',
		Scope: 'main-scope',
		Quote: 'main-quote',
		WorkOrder: 'main-workorder',
		Asset: 'main-asset',
		BusinessEntity: 'main-contact',
		Client: 'main-contact',
		Invoice: 'main-invoice',
		Sor: 'main-sor',
		PurchaseOrder: 'main-purchaseorder',
		ServiceRequest: 'main-service-request',
		Supplier: 'main-contact',
		Employee: 'main-contact',
		Receivables: 'main-receivables',
		Payables: 'main-payables',
		Calendar: 'main-calendar',
		Property: 'main-property',
	};

	public static ReferenceTypeCSSClass = {
		Default: '',
		Scope: 'ref-type-scope',
		Quote: 'ref-type-quote',
		WorkOrder: 'ref-type-workorder',
		Invoice: 'ref-type-invoice',
		PurchaseOrder: 'ref-type-purchaseorder',
		ServiceRequest: 'ref-type-servicerequest',
		Property: 'ref-type-property',
		Asset: 'ref-type-asset',
		Defect: 'ref-type-defect',
		Client: 'ref-type-contact',
		Contractor: 'ref-type-contact',
		Supplier: 'ref-type-contact',
		Employee: 'ref-type-contact',
		CalendarEvent: 'ref-type-calendar',
	};

	public static NotificationQueueAction = {
		NotificationTest: 'NotificationTest',
		SendSystemMessage: 'SendSystemMessage',
		SubscriberChanged: 'SubscriberChanged',
		UserChanged: 'UserChanged',
		UserRoleChanged: 'UserRoleChanged',
		CalendarChanged: 'CalendarChanged',
	};

	// #endregion

	// #region -- Specific Service Constants --

	public static AssetType = {
		Property: 'PROPERTY',
		General: 'GENERAL',
		Vehicle: 'VEHICLE',
	};

	// #endregion

	// #region -- Server Constants --

	public static DomainType = {
		AssetAttachmentType: 'Asset Attachment Type',
		AssetCategory: 'Asset Category',
		AssetDisposalMethod: 'Asset Disposal Method',
		AssetEndofLife: 'Asset End of Life',
		AssetNoteCategory: 'Asset Note Category',
		AssetType: 'Asset Type',
		BillingAssetType: 'Billing Asset Type',
		BusinessEntityAttachmentType: 'Business Entity Attachment Type',
		BusinessEntityNoteCategory: 'Business Entity Note Category',
		BusinessEntityRelationType: 'Business Entity Relation Type',
		BusinessEntityStatus: 'Business Entity Status',
		CalendarEventType: 'Calendar Event Type',
		ClientAttachmentType: 'Client Attachment Type',
		ClientNoteCategory: 'Client Note Category',
		ClientStatus: 'Client Status',
		Colour: 'Colour',
		CompanyStatus: 'Company Status',
		ContactType: 'Contact Type',
		ContractorEntityType: 'Contractor Entity Type',
		Country: 'Country',
		DashboardObjectClassification: 'Dashboard Object Classification',
		DashboardRefreshPeriod: 'Dashboard Refresh Period',
		DashboardWidget: 'Dashboard Widget',
		DashboardWidgetSource: 'Dashboard Widget Source',
		DataTemplateQuestionType: 'Data Template Question Type',
		DataTemplateSystemType: 'Data Template System Type',
		DayofWeek: 'Day of Week',
		DefectPurchaseOrderPriority: 'Defect Purchase Order Priority',
		DocumentActionType: 'Document Action Type',
		DocumentDestinationType: 'Document Destination Type',
		DocumentOutputType: 'Document Output Type',
		DocumentSourceType: 'Document Source Type',
		DomainDetailType: 'Domain Detail Type',
		DomainSystemType: 'Domain System Type',
		DurationType: 'Duration Type',
		EntitySize: 'Entity Size',
		Estimator: 'Estimator',
		ExternalFinanceType: 'External Finance Type',
		ExternalNotificationType: 'External Notification Type',
		FieldVisibility: 'Field Visibility',
		FileStoreType: 'File Store Type',
		FinancialEntityType: 'Financial Entity Type',
		FlagAlertType: 'Flag Alert Type',
		GroupLookupType: 'Group Lookup Type',
		HistoryFieldType: 'History Field Type',
		Inspector: 'Inspector',
		InsuranceType: 'Insurance Type',
		IntegrationEventFileType: 'Integration Event File Type',
		IntegrationEventStatus: 'Integration Event Status',
		IntegrationEventType: 'Integration Event Type',
		IntegrationModuleStatus: 'Integration Module Status',
		IntegrationModuleType: 'Integration Module Type',
		IntegrationQueueType: 'Integration Queue Type',
		InvoiceAttachmentType: 'Invoice Attachment Type',
		InvoiceCategory: 'Invoice Category',
		InvoiceCreationRule: 'Invoice Creation Rule',
		InvoiceIssueType: 'Invoice Issue Type',
		InvoiceNoteCategory: 'Invoice Note Category',
		InvoiceStatus: 'Invoice Status',
		InvoiceSubtype: 'Invoice Subtype',
		InvoiceType: 'Invoice Type',
		ItemLocation: 'Item Location',
		ItemReviewResult: 'Item Review Result',
		Language: 'Language',
		LiabilityType: 'Liability Type',
		LicenceCertificateType: 'Licence / Certificate Type',
		MapMarkerStyle: 'Map Marker Style',
		NoteAlertType: 'Note Alert Type',
		OrderItemReviewStatus: 'Order Item Review Status',
		OrderPriority: 'Order Priority',
		Owner: 'Owner',
		PaymentTerms: 'Payment Terms',
		PriceOverrideReason: 'Price Override Reason',
		PriceType: 'Price Type',
		PurchaseOrderAttachmentType: 'Purchase Order Attachment Type',
		PurchaseOrderNoteCategory: 'Purchase Order Note Category',
		PurchaseOrderStatus: 'Purchase Order Status',
		PurchaseOrderType: 'Purchase Order Type',
		QuoteRequestAttachmentType: 'Quote Request Attachment Type',
		QuoteRequestNoteCategory: 'Quote Request Note Category',
		QuoteRequestRecipientStatus: 'Quote Request Recipient Status',
		QuoteRequestStatus: 'Quote Request Status',
		RateGroupStatus: 'Rate Group Status',
		RecordType: 'Record Type',
		RecurrencePattern: 'Recurrence Pattern',
		ReferenceType: 'Reference Type',
		Region: 'Region',
		ScopeAttachmentType: 'Scope Attachment Type',
		ScopeNoteCategory: 'Scope Note Category',
		ScopeStatus: 'Scope Status',
		SearchFilterType: 'Search Filter Type',
		ServiceRequestAttachmentType: 'Service Request Attachment Type',
		ServiceRequestNoteCategory: 'Service Request Note Category',
		ServiceRequestStatus: 'Service Request Status',
		ServiceRequestType: 'Service Request Type',
		SubscriberDomainType: 'Subscriber Domain Type',
		SubscriberStatus: 'Subscriber Status',
		SubscriptionAccessType: 'Subscription Access Type',
		SystemDomainType: 'System Domain Type',
		SystemReport: 'System Report',
		TaxCode: 'Tax Code',
		Timezone: 'Timezone',
		TradeType: 'Trade Type',
		UnitofMeasure: 'Unit of Measure',
		UserPermission: 'User Permission',
		UserProfileStatus: 'User Profile Status',
		UserRelationType: 'User Relation Type',
		VehicleAssetAxleCode: 'Vehicle Asset Axle Code',
		VehicleAssetColour: 'Vehicle Asset Colour',
		VehicleAssetFuelType: 'Vehicle Asset Fuel Type',
		VehicleAssetMake: 'Vehicle Asset Make',
		VehicleAssetShape: 'Vehicle Asset Shape',
		WeeklyRecurrenceOption: 'Weekly Recurrence Option',
		WorkOrderAttachmentType: 'Work Order Attachment Type',
		WorkOrderNoteCategory: 'Work Order Note Category',
		WorkOrderStatus: 'Work Order Status',
		WorkOrderType: 'Work Order Type',
		WorkServiceType: 'Work Service Type',
	};

	public static BillingAssetType = {
		Asset: 'ASSET',
		FileStorage: 'STORAGE',
		Invoice: 'INVOICE',
		QuoteRequest: 'QUOTE',
		Scope: 'SCOPE',
		Subscription: 'SUB',
		User: 'USER',
		WorkOrder: 'WO',

		Names: {
			Asset: 'Asset',
			FileStorage: 'File Storage',
			Invoice: 'Invoice',
			QuoteRequest: 'Quote Request',
			Scope: 'Scope',
			Subscription: 'Subscription',
			User: 'User',
			WorkOrder: 'Work Order',
		},

		Values: [
			new LookupValue({ CodeValue: 'ASSET', NameValue: 'Asset' }),
			new LookupValue({ CodeValue: 'STORAGE', NameValue: 'File Storage' }),
			new LookupValue({ CodeValue: 'INVOICE', NameValue: 'Invoice' }),
			new LookupValue({ CodeValue: 'QUOTE', NameValue: 'Quote Request' }),
			new LookupValue({ CodeValue: 'SCOPE', NameValue: 'Scope' }),
			new LookupValue({ CodeValue: 'SUB', NameValue: 'Subscription' }),
			new LookupValue({ CodeValue: 'USER', NameValue: 'User' }),
			new LookupValue({ CodeValue: 'WO', NameValue: 'Work Order' }),
		],
	};

	public static BusinessEntityRelationType = {
		Contractor: 'CONTRACTOR',
		Employee: 'EMPLOYEE',
		Supplier: 'SUPPLIER',

		Names: {
			Contractor: 'Contractor',
			Employee: 'Employee',
			Supplier: 'Supplier',
		},

		Values: [
			new LookupValue({ CodeValue: 'CONTRACTOR', NameValue: 'Contractor' }),
			new LookupValue({ CodeValue: 'EMPLOYEE', NameValue: 'Employee' }),
			new LookupValue({ CodeValue: 'SUPPLIER', NameValue: 'Supplier' }),
		],
	};

	public static BusinessEntityStatus = {
		Active: 'ACTIVE',
		Disabled: 'DISABLED',
		OnHold: 'ONHOLD',

		Names: {
			Active: 'Active',
			Disabled: 'Disabled',
			OnHold: 'On Hold',
		},

		Values: [
			new LookupValue({ CodeValue: 'ACTIVE', NameValue: 'Active' }),
			new LookupValue({ CodeValue: 'DISABLED', NameValue: 'Disabled' }),
			new LookupValue({ CodeValue: 'ONHOLD', NameValue: 'On Hold' }),
		],
	};

	public static ClientStatus = {
		Active: 'ACTIVE',
		Disabled: 'DISABLED',

		Names: {
			Active: 'Active',
			Disabled: 'Disabled',
		},

		Values: [new LookupValue({ CodeValue: 'ACTIVE', NameValue: 'Active' }), new LookupValue({ CodeValue: 'DISABLED', NameValue: 'Disabled' })],
	};

	public static CompanyStatus = {
		Active: 'ACTIVE',
		Disabled: 'DISABLED',

		Names: {
			Active: 'Active',
			Disabled: 'Disabled',
		},

		Values: [new LookupValue({ CodeValue: 'ACTIVE', NameValue: 'Active' }), new LookupValue({ CodeValue: 'DISABLED', NameValue: 'Disabled' })],
	};

	public static DashboardObjectClassification = {
		All: 'ALL',
		Open: 'OPEN',
		Overdue: 'OVERDUE',

		Names: {
			All: 'All',
			Open: 'Open',
			Overdue: 'Overdue',
		},

		Values: [
			new LookupValue({ CodeValue: 'ALL', NameValue: 'All' }),
			new LookupValue({ CodeValue: 'OPEN', NameValue: 'Open' }),
			new LookupValue({ CodeValue: 'OVERDUE', NameValue: 'Overdue' }),
		],
	};

	public static DashboardRefreshPeriod = {
		Every1Minute: '2',
		Every10Minutes: '5',
		Every12Hours: '14',
		Every15Minutes: '6',
		Every2Hours: '10',
		Every2Minutes: '3',
		Every3Hours: '11',
		Every30Minutes: '7',
		Every45Minutes: '8',
		Every5Minutes: '4',
		Every6Hours: '12',
		Every8Hours: '13',
		EveryDay: '15',
		EveryHour: '9',
		EveryTime: '0',

		Names: {
			Every1Minute: 'Every 1 Minute',
			Every10Minutes: 'Every 10 Minutes',
			Every12Hours: 'Every 12 Hours',
			Every15Minutes: 'Every 15 Minutes',
			Every2Hours: 'Every 2 Hours',
			Every2Minutes: 'Every 2 Minutes',
			Every3Hours: 'Every 3 Hours',
			Every30Minutes: 'Every 30 Minutes',
			Every45Minutes: 'Every 45 Minutes',
			Every5Minutes: 'Every 5 Minutes',
			Every6Hours: 'Every 6 Hours',
			Every8Hours: 'Every 8 Hours',
			EveryDay: 'Every Day',
			EveryHour: 'Every Hour',
			EveryTime: 'Every Time',
		},

		Values: [
			new LookupValue({ CodeValue: '2', NameValue: 'Every 1 Minute' }),
			new LookupValue({ CodeValue: '5', NameValue: 'Every 10 Minutes' }),
			new LookupValue({ CodeValue: '14', NameValue: 'Every 12 Hours' }),
			new LookupValue({ CodeValue: '6', NameValue: 'Every 15 Minutes' }),
			new LookupValue({ CodeValue: '10', NameValue: 'Every 2 Hours' }),
			new LookupValue({ CodeValue: '3', NameValue: 'Every 2 Minutes' }),
			new LookupValue({ CodeValue: '11', NameValue: 'Every 3 Hours' }),
			new LookupValue({ CodeValue: '7', NameValue: 'Every 30 Minutes' }),
			new LookupValue({ CodeValue: '8', NameValue: 'Every 45 Minutes' }),
			new LookupValue({ CodeValue: '4', NameValue: 'Every 5 Minutes' }),
			new LookupValue({ CodeValue: '12', NameValue: 'Every 6 Hours' }),
			new LookupValue({ CodeValue: '13', NameValue: 'Every 8 Hours' }),
			new LookupValue({ CodeValue: '15', NameValue: 'Every Day' }),
			new LookupValue({ CodeValue: '9', NameValue: 'Every Hour' }),
			new LookupValue({ CodeValue: '0', NameValue: 'Every Time' }),
		],
	};

	public static DashboardWidget = {
		ContractorStatistics: 'CNTRSTATS',
		InterfaceEvents: 'INTERFACE',
		LicenceInsuranceExpiry: 'LICINSURANCE',
		Map: 'MAP',
		OpenServiceRequests: 'SRQ',
		POBreakdowns: 'POBREAK',
		POVolumesbyPriority: 'POVOLUME',
		ReadyforAction: 'RDYACT',
		Scorecard: 'SCORECARD',
		SpendTrend: 'SPNDTREND',
		TimeKpi: 'TIMEKPI',
		UnpaidInvoices: 'INVOICE',
		WorkTypeAnalysis: 'WORKTYPE',

		Names: {
			ContractorStatistics: 'Contractor Statistics',
			InterfaceEvents: 'Interface Events',
			LicenceInsuranceExpiry: 'Licence & Insurance Expiry',
			Map: 'Plan',
			OpenServiceRequests: 'Open Service Requests',
			POBreakdowns: 'PO Breakdowns',
			POVolumesbyPriority: 'PO Volumes by Priority',
			ReadyforAction: 'Ready for Action',
			Scorecard: 'Scorecard',
			SpendTrend: 'Spend Trend',
			TimeKpi: 'Time Kpi %',
			UnpaidInvoices: 'Unpaid Invoices',
			WorkTypeAnalysis: 'Work Type Analysis',
		},

		Values: [
			new LookupValue({ CodeValue: 'CNTRSTATS', NameValue: 'Contractor Statistics' }),
			new LookupValue({ CodeValue: 'INTERFACE', NameValue: 'Interface Events' }),
			new LookupValue({ CodeValue: 'LICINSURANCE', NameValue: 'Licence & Insurance Expiry' }),
			new LookupValue({ CodeValue: 'MAP', NameValue: 'Plan' }),
			new LookupValue({ CodeValue: 'SRQ', NameValue: 'Open Service Requests' }),
			new LookupValue({ CodeValue: 'POBREAK', NameValue: 'PO Breakdowns' }),
			new LookupValue({ CodeValue: 'POVOLUME', NameValue: 'PO Volumes by Priority' }),
			new LookupValue({ CodeValue: 'RDYACT', NameValue: 'Ready for Action' }),
			new LookupValue({ CodeValue: 'SCORECARD', NameValue: 'Scorecard' }),
			new LookupValue({ CodeValue: 'SPNDTREND', NameValue: 'Spend Trend' }),
			new LookupValue({ CodeValue: 'TIMEKPI', NameValue: 'Time Kpi %' }),
			new LookupValue({ CodeValue: 'INVOICE', NameValue: 'Unpaid Invoices' }),
			new LookupValue({ CodeValue: 'WORKTYPE', NameValue: 'Work Type Analysis' }),
		],
	};

	public static DashboardWidgetSource = {
		Defects: 'DPO',
		Payables: 'PAY',
		PurchaseOrders: 'PO',
		QuoteRequests: 'QR',
		Receivables: 'REC',
		Scopes: 'SR',
		ServiceRequests: 'SRQ',
		WorkOrders: 'WO',

		Names: {
			Defects: 'Defects',
			Payables: 'Payables',
			PurchaseOrders: 'Purchase Orders',
			QuoteRequests: 'Quotes',
			Receivables: 'Receivables',
			Scopes: 'Scopes',
			ServiceRequests: 'Service Requests',
			WorkOrders: 'Work Orders',
		},

		Values: [
			new LookupValue({ CodeValue: 'DPO', NameValue: 'Defects' }),
			new LookupValue({ CodeValue: 'PAY', NameValue: 'Payables' }),
			new LookupValue({ CodeValue: 'PO', NameValue: 'Purchase Orders' }),
			new LookupValue({ CodeValue: 'QR', NameValue: 'Quotes' }),
			new LookupValue({ CodeValue: 'REC', NameValue: 'Receivables' }),
			new LookupValue({ CodeValue: 'SR', NameValue: 'Scopes' }),
			new LookupValue({ CodeValue: 'SRQ', NameValue: 'Service Requests' }),
			new LookupValue({ CodeValue: 'WO', NameValue: 'Work Orders' }),
		],
	};

	public static DataTemplateQuestionType = {
		Calendardateandtime: 'Calendar',
		Calendardateonly: 'CalendarDate',
		Calendartimeonly: 'CalendarTime',
		Checkbox: 'CheckBox',
		DropdownList: 'Autocomplete',
		MultilineText: 'Textarea',
		MultipleSelectionList: 'MultiSelect',
		Number: 'Numeric',
		SinglelineText: 'Textbox',

		Names: {
			Calendardateandtime: 'Calendar (date and time)',
			Calendardateonly: 'Calendar (date only)',
			Calendartimeonly: 'Calendar (time only)',
			Checkbox: 'Checkbox',
			DropdownList: 'Dropdown List',
			MultilineText: 'Multiline Text',
			MultipleSelectionList: 'Multiple Selection List',
			Number: 'Number',
			SinglelineText: 'Singleline Text',
		},

		Values: [
			new LookupValue({ CodeValue: 'Calendar', NameValue: 'Calendar (date and time)' }),
			new LookupValue({ CodeValue: 'CalendarDate', NameValue: 'Calendar (date only)' }),
			new LookupValue({ CodeValue: 'CalendarTime', NameValue: 'Calendar (time only)' }),
			new LookupValue({ CodeValue: 'CheckBox', NameValue: 'Checkbox' }),
			new LookupValue({ CodeValue: 'Autocomplete', NameValue: 'Dropdown List' }),
			new LookupValue({ CodeValue: 'Textarea', NameValue: 'Multiline Text' }),
			new LookupValue({ CodeValue: 'MultiSelect', NameValue: 'Multiple Selection List' }),
			new LookupValue({ CodeValue: 'Numeric', NameValue: 'Number' }),
			new LookupValue({ CodeValue: 'Textbox', NameValue: 'Singleline Text' }),
		],
	};

	public static DataTemplateSystemType = {
		DefaultGeneralAssetTemplate: 'Asset_General',
		DefaultPropertyAssetTemplate: 'Asset_Property',
		DefaultVehicleAssetTemplate: 'Asset_Vehicle',

		Names: {
			DefaultGeneralAssetTemplate: 'Default General Asset Template',
			DefaultPropertyAssetTemplate: 'Default Property Asset Template',
			DefaultVehicleAssetTemplate: 'Default Vehicle Asset Template',
		},

		Values: [
			new LookupValue({ CodeValue: 'Asset_General', NameValue: 'Default General Asset Template' }),
			new LookupValue({ CodeValue: 'Asset_Property', NameValue: 'Default Property Asset Template' }),
			new LookupValue({ CodeValue: 'Asset_Vehicle', NameValue: 'Default Vehicle Asset Template' }),
		],
	};

	public static DayofWeek = {
		Friday: 'FRI',
		Monday: 'MON',
		Saturday: 'SAT',
		Sunday: 'SUN',
		Thursday: 'THU',
		Tuesday: 'TUE',
		Wednesday: 'WED',

		Names: {
			Friday: 'Friday',
			Monday: 'Monday',
			Saturday: 'Saturday',
			Sunday: 'Sunday',
			Thursday: 'Thursday',
			Tuesday: 'Tuesday',
			Wednesday: 'Wednesday',
		},

		Values: [
			new LookupValue({ CodeValue: 'FRI', NameValue: 'Friday' }),
			new LookupValue({ CodeValue: 'MON', NameValue: 'Monday' }),
			new LookupValue({ CodeValue: 'SAT', NameValue: 'Saturday' }),
			new LookupValue({ CodeValue: 'SUN', NameValue: 'Sunday' }),
			new LookupValue({ CodeValue: 'THU', NameValue: 'Thursday' }),
			new LookupValue({ CodeValue: 'TUE', NameValue: 'Tuesday' }),
			new LookupValue({ CodeValue: 'WED', NameValue: 'Wednesday' }),
		],
	};

	public static DocumentActionType = {
		Approved: 'APPROVED',
		Cancelled: 'CANCELLED',
		Closed: 'CLOSED',
		New: 'NEW',
		Paused: 'PAUSED',
		Preferred: 'PREFERRED',
		Reviewed: 'REVIEWED',
		Unsuccessful: 'UNSUCCESSFUL',
		Updated: 'UPDATED',
		Verify: 'VERIFY',
		Voided: 'VOIDED',

		Names: {
			Approved: 'Approved',
			Cancelled: 'Cancelled',
			Closed: 'Closed',
			New: 'New',
			Paused: 'Paused',
			Preferred: 'Preferred',
			Reviewed: 'Reviewed',
			Unsuccessful: 'Unsuccessful',
			Updated: 'Updated',
			Verify: 'Verify',
			Voided: 'Voided',
		},

		Values: [
			new LookupValue({ CodeValue: 'APPROVED', NameValue: 'Approved' }),
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CLOSED', NameValue: 'Closed' }),
			new LookupValue({ CodeValue: 'NEW', NameValue: 'New' }),
			new LookupValue({ CodeValue: 'PAUSED', NameValue: 'Paused' }),
			new LookupValue({ CodeValue: 'PREFERRED', NameValue: 'Preferred' }),
			new LookupValue({ CodeValue: 'REVIEWED', NameValue: 'Reviewed' }),
			new LookupValue({ CodeValue: 'UNSUCCESSFUL', NameValue: 'Unsuccessful' }),
			new LookupValue({ CodeValue: 'UPDATED', NameValue: 'Updated' }),
			new LookupValue({ CodeValue: 'VERIFY', NameValue: 'Verify' }),
			new LookupValue({ CodeValue: 'VOIDED', NameValue: 'Voided' }),
		],
	};

	public static DocumentDestinationType = {
		Client: 'CLIENT',
		Contractor: 'CONTRACTOR',
		Generic: 'GENERIC',
		Internal: 'INTERNAL',
		Supplier: 'SUPPLIER',

		Names: {
			Client: 'Client',
			Contractor: 'Contractor',
			Generic: 'Generic',
			Internal: 'Internal',
			Supplier: 'Supplier',
		},

		Values: [
			new LookupValue({ CodeValue: 'CLIENT', NameValue: 'Client' }),
			new LookupValue({ CodeValue: 'CONTRACTOR', NameValue: 'Contractor' }),
			new LookupValue({ CodeValue: 'GENERIC', NameValue: 'Generic' }),
			new LookupValue({ CodeValue: 'INTERNAL', NameValue: 'Internal' }),
			new LookupValue({ CodeValue: 'SUPPLIER', NameValue: 'Supplier' }),
		],
	};

	public static DocumentOutputType = {
		Email: 'EMAIL',
		PDF: 'PDF',
		SMS: 'SMS',

		Names: {
			Email: 'E-mail',
			PDF: 'PDF',
			SMS: 'SMS',
		},

		Values: [
			new LookupValue({ CodeValue: 'EMAIL', NameValue: 'E-mail' }),
			new LookupValue({ CodeValue: 'PDF', NameValue: 'PDF' }),
			new LookupValue({ CodeValue: 'SMS', NameValue: 'SMS' }),
		],
	};

	public static DocumentSourceType = {
		ContractorDefect: 'DEFECTPO',
		ContractorDefectNotification: 'DEFECTNOTIFYPO',
		CreditPurchaseOrder: 'CREDITPO',
		PayableInvoice: 'PAYABLE',
		PurchaseOrder: 'PO',
		PurchasesCreditNote: 'PURCHASECREDIT',
		QuoteRequestRecipient: 'QUOTEREC',
		ReceivableInvoice: 'RECEIVABLE',
		RecoveryPurchaseOrder: 'RECOVERYPO',
		SalesCreditNote: 'SALESCREDIT',
		Scope: 'SCOPE',
		ServiceRequest: 'SRQ',
		UserProfile: 'USERPROFILE',

		Names: {
			ContractorDefect: 'Contractor Defect',
			ContractorDefectNotification: 'Contractor Defect Notification',
			CreditPurchaseOrder: 'Credit Purchase Order',
			PayableInvoice: 'Payable Invoice',
			PurchaseOrder: 'Purchase Order',
			PurchasesCreditNote: 'Purchases Credit Note',
			QuoteRequestRecipient: 'Quote Request Recipient',
			ReceivableInvoice: 'Receivable Invoice',
			RecoveryPurchaseOrder: 'Recovery Purchase Order',
			SalesCreditNote: 'Sales Credit Note',
			Scope: 'Scope',
			ServiceRequest: 'Service Request',
			UserProfile: 'User Profile',
		},

		Values: [
			new LookupValue({ CodeValue: 'DEFECTPO', NameValue: 'Contractor Defect' }),
			new LookupValue({ CodeValue: 'DEFECTNOTIFYPO', NameValue: 'Contractor Defect Notification' }),
			new LookupValue({ CodeValue: 'CREDITPO', NameValue: 'Credit Purchase Order' }),
			new LookupValue({ CodeValue: 'PAYABLE', NameValue: 'Payable Invoice' }),
			new LookupValue({ CodeValue: 'PO', NameValue: 'Purchase Order' }),
			new LookupValue({ CodeValue: 'PURCHASECREDIT', NameValue: 'Purchases Credit Note' }),
			new LookupValue({ CodeValue: 'QUOTEREC', NameValue: 'Quote Request Recipient' }),
			new LookupValue({ CodeValue: 'RECEIVABLE', NameValue: 'Receivable Invoice' }),
			new LookupValue({ CodeValue: 'RECOVERYPO', NameValue: 'Recovery Purchase Order' }),
			new LookupValue({ CodeValue: 'SALESCREDIT', NameValue: 'Sales Credit Note' }),
			new LookupValue({ CodeValue: 'SCOPE', NameValue: 'Scope' }),
			new LookupValue({ CodeValue: 'SRQ', NameValue: 'Service Request' }),
			new LookupValue({ CodeValue: 'USERPROFILE', NameValue: 'User Profile' }),
		],
	};

	public static DomainDetailType = {
		AssetType: 'ASSETTYPE',
		AttachmentType: 'ATTACHTYPE',
		Contact: 'CONTACT',
		DataTemplate: 'DATATEMPLATE',
		FlagAlert: 'FLAGALERT',
		Insurance: 'INSURANCE',
		OrderPriorityConfiguration: 'ORDERPRIORITYCONFIG',
		PaymentTerms: 'PAYMENTTERMS',
		Reference: 'REFERENCE',
		Region: 'REGION',
		ServiceRequestTypeConfiguration: 'SRQTYPECONFIG',
		System: 'SYS',
		TargetDateCalculation: 'TARGETDATECALC',
		TaxRate: 'TAXRATE',

		Names: {
			AssetType: 'Asset Type',
			AttachmentType: 'Attachment Type',
			Contact: 'Contact',
			DataTemplate: 'Data Template',
			FlagAlert: 'Flag Alert',
			Insurance: 'Insurance',
			OrderPriorityConfiguration: 'Order Priority Configuration',
			PaymentTerms: 'Payment Terms',
			Reference: 'Reference',
			Region: 'Region',
			ServiceRequestTypeConfiguration: 'Service Request Type Configuration',
			System: 'System',
			TargetDateCalculation: 'Target Date Calculation',
			TaxRate: 'Tax Rate',
		},

		Values: [
			new LookupValue({ CodeValue: 'ASSETTYPE', NameValue: 'Asset Type' }),
			new LookupValue({ CodeValue: 'ATTACHTYPE', NameValue: 'Attachment Type' }),
			new LookupValue({ CodeValue: 'CONTACT', NameValue: 'Contact' }),
			new LookupValue({ CodeValue: 'DATATEMPLATE', NameValue: 'Data Template' }),
			new LookupValue({ CodeValue: 'FLAGALERT', NameValue: 'Flag Alert' }),
			new LookupValue({ CodeValue: 'INSURANCE', NameValue: 'Insurance' }),
			new LookupValue({ CodeValue: 'ORDERPRIORITYCONFIG', NameValue: 'Order Priority Configuration' }),
			new LookupValue({ CodeValue: 'PAYMENTTERMS', NameValue: 'Payment Terms' }),
			new LookupValue({ CodeValue: 'REFERENCE', NameValue: 'Reference' }),
			new LookupValue({ CodeValue: 'REGION', NameValue: 'Region' }),
			new LookupValue({ CodeValue: 'SRQTYPECONFIG', NameValue: 'Service Request Type Configuration' }),
			new LookupValue({ CodeValue: 'SYS', NameValue: 'System' }),
			new LookupValue({ CodeValue: 'TARGETDATECALC', NameValue: 'Target Date Calculation' }),
			new LookupValue({ CodeValue: 'TAXRATE', NameValue: 'Tax Rate' }),
		],
	};

	public static DomainSystemType = {
		EditableOptions: 'OPTIONS',
		Readonly: 'READONLY',

		Names: {
			EditableOptions: 'Editable Options',
			Readonly: 'Readonly',
		},

		Values: [new LookupValue({ CodeValue: 'OPTIONS', NameValue: 'Editable Options' }), new LookupValue({ CodeValue: 'READONLY', NameValue: 'Readonly' })],
	};

	public static DurationType = {
		BusinessDay: 'BusinessDay',
		Day: 'Day',
		Hour: 'Hour',

		Names: {
			BusinessDay: 'Business Day',
			Day: 'Day',
			Hour: 'Hour',
		},

		Values: [
			new LookupValue({ CodeValue: 'BusinessDay', NameValue: 'Business Day' }),
			new LookupValue({ CodeValue: 'Day', NameValue: 'Day' }),
			new LookupValue({ CodeValue: 'Hour', NameValue: 'Hour' }),
		],
	};

	public static EntitySize = {
		_13: '1-3',
		_1130: '11-30',
		_31: '31+',
		_410: '4-10',

		Names: {
			_13: '1 - 3',
			_1130: '11 - 30',
			_31: '31+',
			_410: '4 - 10',
		},

		Values: [
			new LookupValue({ CodeValue: '1-3', NameValue: '1 - 3' }),
			new LookupValue({ CodeValue: '11-30', NameValue: '11 - 30' }),
			new LookupValue({ CodeValue: '31+', NameValue: '31+' }),
			new LookupValue({ CodeValue: '4-10', NameValue: '4 - 10' }),
		],
	};

	public static ExternalFinanceType = {
		Contact: 'CONTACT',
		CreditNote: 'CREDITNOTE',
		Invoice: 'INVOICE',
		Notification: 'NOTIFICATION',

		Names: {
			Contact: 'Contact',
			CreditNote: 'Credit Note',
			Invoice: 'Invoice',
			Notification: 'Notification',
		},

		Values: [
			new LookupValue({ CodeValue: 'CONTACT', NameValue: 'Contact' }),
			new LookupValue({ CodeValue: 'CREDITNOTE', NameValue: 'Credit Note' }),
			new LookupValue({ CodeValue: 'INVOICE', NameValue: 'Invoice' }),
			new LookupValue({ CodeValue: 'NOTIFICATION', NameValue: 'Notification' }),
		],
	};

	public static ExternalNotificationType = {
		Approved: 'APPROVED',
		Cancelled: 'CANCELLED',
		Closed: 'CLOSED',
		New: 'NEW',
		Paused: 'PAUSED',
		Preferred: 'PREFERRED',
		Reviewed: 'REVIEWED',
		Unsuccessful: 'UNSUCCESSFUL',
		Updated: 'UPDATED',
		Void: 'VOID',

		Names: {
			Approved: 'Approved',
			Cancelled: 'Cancelled',
			Closed: 'Closed',
			New: 'New',
			Paused: 'Paused',
			Preferred: 'Preferred',
			Reviewed: 'Reviewed',
			Unsuccessful: 'Unsuccessful',
			Updated: 'Updated',
			Void: 'Void',
		},

		Values: [
			new LookupValue({ CodeValue: 'APPROVED', NameValue: 'Approved' }),
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CLOSED', NameValue: 'Closed' }),
			new LookupValue({ CodeValue: 'NEW', NameValue: 'New' }),
			new LookupValue({ CodeValue: 'PAUSED', NameValue: 'Paused' }),
			new LookupValue({ CodeValue: 'PREFERRED', NameValue: 'Preferred' }),
			new LookupValue({ CodeValue: 'REVIEWED', NameValue: 'Reviewed' }),
			new LookupValue({ CodeValue: 'UNSUCCESSFUL', NameValue: 'Unsuccessful' }),
			new LookupValue({ CodeValue: 'UPDATED', NameValue: 'Updated' }),
			new LookupValue({ CodeValue: 'VOID', NameValue: 'Void' }),
		],
	};

	public static FieldVisibility = {
		Mandatory: 'MANDATORY',
		Optional: 'OPTIONAL',

		Names: {
			Mandatory: 'Mandatory',
			Optional: 'Optional',
		},

		Values: [new LookupValue({ CodeValue: 'MANDATORY', NameValue: 'Mandatory' }), new LookupValue({ CodeValue: 'OPTIONAL', NameValue: 'Optional' })],
	};

	public static FileStoreType = {
		AttachmentFiles: 'AttachmentFiles',
		DataLoadFiles: 'DataLoadFiles',
		EmailTemplates: 'EmailTemplates',
		IntegrationExportEvents: 'IntegrationExportEvents',
		IntegrationImportEvents: 'IntegrationImportEvents',
		Maps: 'Maps',

		Names: {
			AttachmentFiles: 'Attachment Files',
			DataLoadFiles: 'Data Load Files',
			EmailTemplates: 'Email Templates',
			IntegrationExportEvents: 'Integration Export Events',
			IntegrationImportEvents: 'Integration Import Events',
			Maps: 'Plans',
		},

		Values: [
			new LookupValue({ CodeValue: 'AttachmentFiles', NameValue: 'Attachment Files' }),
			new LookupValue({ CodeValue: 'DataLoadFiles', NameValue: 'Data Load Files' }),
			new LookupValue({ CodeValue: 'EmailTemplates', NameValue: 'Email Templates' }),
			new LookupValue({ CodeValue: 'IntegrationExportEvents', NameValue: 'Integration Export Events' }),
			new LookupValue({ CodeValue: 'IntegrationImportEvents', NameValue: 'Integration Import Events' }),
			new LookupValue({ CodeValue: 'Maps', NameValue: 'Plans' }),
		],
	};

	public static FinancialEntityType = {
		Client: 'CLIENT',
		Contractor: 'CONTRACTOR',
		Supplier: 'SUPPLIER',

		Names: {
			Client: 'Client',
			Contractor: 'Contractor',
			Supplier: 'Supplier',
		},

		Values: [
			new LookupValue({ CodeValue: 'CLIENT', NameValue: 'Client' }),
			new LookupValue({ CodeValue: 'CONTRACTOR', NameValue: 'Contractor' }),
			new LookupValue({ CodeValue: 'SUPPLIER', NameValue: 'Supplier' }),
		],
	};

	public static GroupLookupType = {
		Client: 'CLIENT',
		Company: 'COMPANY',

		Names: {
			Client: 'Client',
			Company: 'Company',
		},

		Values: [new LookupValue({ CodeValue: 'CLIENT', NameValue: 'Client' }), new LookupValue({ CodeValue: 'COMPANY', NameValue: 'Company' })],
	};

	public static HistoryFieldType = {
		Date: 'Date',
		DateTime: 'DateTime',
		LocalDate: 'LocalDate',
		LocalDateTime: 'LocalDateTime',
		Price: 'Price',

		Names: {
			Date: 'Date',
			DateTime: 'Date/Time',
			LocalDate: 'Local Date',
			LocalDateTime: 'Local Date/Time',
			Price: 'Price',
		},

		Values: [
			new LookupValue({ CodeValue: 'Date', NameValue: 'Date' }),
			new LookupValue({ CodeValue: 'DateTime', NameValue: 'Date/Time' }),
			new LookupValue({ CodeValue: 'LocalDate', NameValue: 'Local Date' }),
			new LookupValue({ CodeValue: 'LocalDateTime', NameValue: 'Local Date/Time' }),
			new LookupValue({ CodeValue: 'Price', NameValue: 'Price' }),
		],
	};

	public static IntegrationEventFileType = {
		Attachment: 'ATTACHMENT',
		Content: 'CONTENT',
		Markdown: 'MARKDOWN',

		Names: {
			Attachment: 'Attachment',
			Content: 'Content',
			Markdown: 'Markdown',
		},

		Values: [
			new LookupValue({ CodeValue: 'ATTACHMENT', NameValue: 'Attachment' }),
			new LookupValue({ CodeValue: 'CONTENT', NameValue: 'Content' }),
			new LookupValue({ CodeValue: 'MARKDOWN', NameValue: 'Markdown' }),
		],
	};

	public static IntegrationEventStatus = {
		Cancelled: 'CANCELLED',
		Created: 'CREATED',
		Failed: 'FAILED',
		Processed: 'PROCESSED',

		Names: {
			Cancelled: 'Cancelled',
			Created: 'Created',
			Failed: 'Failed',
			Processed: 'Processed',
		},

		Values: [
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CREATED', NameValue: 'Created' }),
			new LookupValue({ CodeValue: 'FAILED', NameValue: 'Failed' }),
			new LookupValue({ CodeValue: 'PROCESSED', NameValue: 'Processed' }),
		],
	};

	public static IntegrationEventType = {
		Asset: 'ASSET',
		Attachment: 'ATTACH',
		AttachmentFile: 'ATTACHFILE',
		Client: 'CLIENT',
		Company: 'COM',
		Contractor: 'CONTRACTOR',
		DomainTypeValue: 'DOMVAL',
		Employee: 'EMPLOYEE',
		ExternalFinance: 'EXTERNALFINANCE',
		Invoice: 'INVOICE',
		InvoicePayment: 'INVOICEPAY',
		Mail: 'MAIL',
		Problem: 'PROBLEM',
		PurchaseOrder: 'PO',
		QuoteRequest: 'QUOTE',
		QuoteRequestRecipient: 'QUOTEREC',
		RateGroup: 'RATEGROUP',
		Scope: 'SCOPE',
		ServiceArea: 'SVCAREA',
		ServiceProject: 'SVCPRJ',
		ServiceRequest: 'SRQ',
		SMS: 'SMS',
		Sor: 'SOR',
		Supplier: 'SUPPLIER',
		WorkOrder: 'WO',

		Names: {
			Asset: 'Asset',
			Attachment: 'Attachment',
			AttachmentFile: 'Attachment File',
			Client: 'Client',
			Company: 'Company',
			Contractor: 'Contractor',
			DomainTypeValue: 'Field',
			Employee: 'Employee',
			ExternalFinance: 'External Finance',
			Invoice: 'Invoice',
			InvoicePayment: 'Invoice Payment',
			Mail: 'Mail',
			Problem: 'Request',
			PurchaseOrder: 'Purchase Order',
			QuoteRequest: 'Quote',
			QuoteRequestRecipient: 'Quote Recipient',
			RateGroup: 'Rate Group',
			Scope: 'Scope',
			ServiceArea: 'Area',
			ServiceProject: 'Project',
			ServiceRequest: 'Service Request',
			SMS: 'SMS',
			Sor: 'Item',
			Supplier: 'Supplier',
			WorkOrder: 'Work Order',
		},

		Values: [
			new LookupValue({ CodeValue: 'ASSET', NameValue: 'Asset' }),
			new LookupValue({ CodeValue: 'ATTACH', NameValue: 'Attachment' }),
			new LookupValue({ CodeValue: 'ATTACHFILE', NameValue: 'Attachment File' }),
			new LookupValue({ CodeValue: 'CLIENT', NameValue: 'Client' }),
			new LookupValue({ CodeValue: 'COM', NameValue: 'Company' }),
			new LookupValue({ CodeValue: 'CONTRACTOR', NameValue: 'Contractor' }),
			new LookupValue({ CodeValue: 'DOMVAL', NameValue: 'Field' }),
			new LookupValue({ CodeValue: 'EMPLOYEE', NameValue: 'Employee' }),
			new LookupValue({ CodeValue: 'EXTERNALFINANCE', NameValue: 'External Finance' }),
			new LookupValue({ CodeValue: 'INVOICE', NameValue: 'Invoice' }),
			new LookupValue({ CodeValue: 'INVOICEPAY', NameValue: 'Invoice Payment' }),
			new LookupValue({ CodeValue: 'MAIL', NameValue: 'Mail' }),
			new LookupValue({ CodeValue: 'PROBLEM', NameValue: 'Request' }),
			new LookupValue({ CodeValue: 'PO', NameValue: 'Purchase Order' }),
			new LookupValue({ CodeValue: 'QUOTE', NameValue: 'Quote' }),
			new LookupValue({ CodeValue: 'QUOTEREC', NameValue: 'Quote Recipient' }),
			new LookupValue({ CodeValue: 'RATEGROUP', NameValue: 'Rate Group' }),
			new LookupValue({ CodeValue: 'SCOPE', NameValue: 'Scope' }),
			new LookupValue({ CodeValue: 'SVCAREA', NameValue: 'Area' }),
			new LookupValue({ CodeValue: 'SVCPRJ', NameValue: 'Project' }),
			new LookupValue({ CodeValue: 'SRQ', NameValue: 'Service Request' }),
			new LookupValue({ CodeValue: 'SMS', NameValue: 'SMS' }),
			new LookupValue({ CodeValue: 'SOR', NameValue: 'Item' }),
			new LookupValue({ CodeValue: 'SUPPLIER', NameValue: 'Supplier' }),
			new LookupValue({ CodeValue: 'WO', NameValue: 'Work Order' }),
		],
	};

	public static IntegrationModuleStatus = {
		Active: 'ACTIVE',
		Disabled: 'DISABLED',

		Names: {
			Active: 'Active',
			Disabled: 'Disabled',
		},

		Values: [new LookupValue({ CodeValue: 'ACTIVE', NameValue: 'Active' }), new LookupValue({ CodeValue: 'DISABLED', NameValue: 'Disabled' })],
	};

	public static IntegrationModuleType = {
		Xero: 'XERO',

		Names: {
			Xero: 'Xero',
		},

		Values: [new LookupValue({ CodeValue: 'XERO', NameValue: 'Xero' })],
	};

	public static IntegrationQueueType = {
		Api: 'API',
		Assets: 'ASSETS',
		Contacts: 'CONTACTS',
		Settings: 'SETTINGS',
		Shared: 'SHARED',
		Standard: 'STANDARD',

		Names: {
			Api: 'Api',
			Assets: 'Assets',
			Contacts: 'Contacts',
			Settings: 'Settings',
			Shared: 'Shared',
			Standard: 'Standard',
		},

		Values: [
			new LookupValue({ CodeValue: 'API', NameValue: 'Api' }),
			new LookupValue({ CodeValue: 'ASSETS', NameValue: 'Assets' }),
			new LookupValue({ CodeValue: 'CONTACTS', NameValue: 'Contacts' }),
			new LookupValue({ CodeValue: 'SETTINGS', NameValue: 'Settings' }),
			new LookupValue({ CodeValue: 'SHARED', NameValue: 'Shared' }),
			new LookupValue({ CodeValue: 'STANDARD', NameValue: 'Standard' }),
		],
	};

	public static InvoiceCreationRule = {
		AdhocAmount: 'ADHOC',
		CreditNote: 'CREDIT',
		FullPayment: 'FULL',
		Items: 'ITEMS',
		RemainingAmount: 'REMAMT',
		RemainingItems: 'REMITEM',
		SingleAmount: 'SINGLE',

		Names: {
			AdhocAmount: 'Adhoc Amount',
			CreditNote: 'Credit Note',
			FullPayment: 'Full Payment',
			Items: 'Items',
			RemainingAmount: 'Remaining Amount',
			RemainingItems: 'Remaining Items',
			SingleAmount: 'Single Amount',
		},

		Values: [
			new LookupValue({ CodeValue: 'ADHOC', NameValue: 'Adhoc Amount' }),
			new LookupValue({ CodeValue: 'CREDIT', NameValue: 'Credit Note' }),
			new LookupValue({ CodeValue: 'FULL', NameValue: 'Full Payment' }),
			new LookupValue({ CodeValue: 'ITEMS', NameValue: 'Items' }),
			new LookupValue({ CodeValue: 'REMAMT', NameValue: 'Remaining Amount' }),
			new LookupValue({ CodeValue: 'REMITEM', NameValue: 'Remaining Items' }),
			new LookupValue({ CodeValue: 'SINGLE', NameValue: 'Single Amount' }),
		],
	};

	public static InvoiceIssueType = {
		InterfaceExport: 'INTERFACE_EXPORT',

		Names: {
			InterfaceExport: 'Interface Export',
		},

		Values: [new LookupValue({ CodeValue: 'INTERFACE_EXPORT', NameValue: 'Interface Export' })],
	};

	public static InvoiceStatus = {
		Approved: 'APPROVED',
		Cancelled: 'CANCELLED',
		Created: 'CREATED',
		Issued: 'ISSUED',
		OnHold: 'ONHOLD',
		Paid: 'PAID',
		Void: 'VOID',

		Names: {
			Approved: 'Approved',
			Cancelled: 'Cancelled',
			Created: 'Created',
			Issued: 'Issued',
			OnHold: 'On Hold',
			Paid: 'Paid',
			Void: 'Void',
		},

		Values: [
			new LookupValue({ CodeValue: 'APPROVED', NameValue: 'Approved' }),
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CREATED', NameValue: 'Created' }),
			new LookupValue({ CodeValue: 'ISSUED', NameValue: 'Issued' }),
			new LookupValue({ CodeValue: 'ONHOLD', NameValue: 'On Hold' }),
			new LookupValue({ CodeValue: 'PAID', NameValue: 'Paid' }),
			new LookupValue({ CodeValue: 'VOID', NameValue: 'Void' }),
		],
	};

	public static InvoiceSubtype = {
		Amount: 'AMOUNT',
		Items: 'ITEMS',

		Names: {
			Amount: 'Amount',
			Items: 'Items',
		},

		Values: [new LookupValue({ CodeValue: 'AMOUNT', NameValue: 'Amount' }), new LookupValue({ CodeValue: 'ITEMS', NameValue: 'Items' })],
	};

	public static InvoiceType = {
		Payable: 'PAYABLE',
		PurchasesCreditNote: 'PURCHASECREDIT',
		Receivable: 'RECEIVABLE',
		SalesCreditNote: 'SALESCREDIT',

		Names: {
			Payable: 'Payable',
			PurchasesCreditNote: 'Purchases Credit Note',
			Receivable: 'Receivable',
			SalesCreditNote: 'Sales Credit Note',
		},

		Values: [
			new LookupValue({ CodeValue: 'PAYABLE', NameValue: 'Payable' }),
			new LookupValue({ CodeValue: 'PURCHASECREDIT', NameValue: 'Purchases Credit Note' }),
			new LookupValue({ CodeValue: 'RECEIVABLE', NameValue: 'Receivable' }),
			new LookupValue({ CodeValue: 'SALESCREDIT', NameValue: 'Sales Credit Note' }),
		],
	};

	public static MapMarkerStyle = {
		FireExtinguisher: 'FE',
		LargeCircle: 'LGECIR',
		MapMarker: 'MM',
		SmallCircle: 'SMLCIR',
		SquareDown: 'SQD',
		SquareLeft: 'SQL',
		SquarePlus: 'SQ+',
		SquareRight: 'SQR',
		SquareUp: 'SQU',
		Tree: 'TREE',
		Wheelchair: 'WC',
		Wifi: 'WIFI',

		Names: {
			FireExtinguisher: 'Fire Extinguisher',
			LargeCircle: 'Large Circle',
			MapMarker: 'Map Marker',
			SmallCircle: 'Small Circle',
			SquareDown: 'Square - Down',
			SquareLeft: 'Square - Left',
			SquarePlus: 'Square - Plus',
			SquareRight: 'Square - Right',
			SquareUp: 'Square - Up',
			Tree: 'Tree',
			Wheelchair: 'Wheelchair',
			Wifi: 'Wi-fi',
		},

		Values: [
			new LookupValue({ CodeValue: 'FE', NameValue: 'Fire Extinguisher' }),
			new LookupValue({ CodeValue: 'LGECIR', NameValue: 'Large Circle' }),
			new LookupValue({ CodeValue: 'MM', NameValue: 'Map Marker' }),
			new LookupValue({ CodeValue: 'SMLCIR', NameValue: 'Small Circle' }),
			new LookupValue({ CodeValue: 'SQD', NameValue: 'Square - Down' }),
			new LookupValue({ CodeValue: 'SQL', NameValue: 'Square - Left' }),
			new LookupValue({ CodeValue: 'SQ+', NameValue: 'Square - Plus' }),
			new LookupValue({ CodeValue: 'SQR', NameValue: 'Square - Right' }),
			new LookupValue({ CodeValue: 'SQU', NameValue: 'Square - Up' }),
			new LookupValue({ CodeValue: 'TREE', NameValue: 'Tree' }),
			new LookupValue({ CodeValue: 'WC', NameValue: 'Wheelchair' }),
			new LookupValue({ CodeValue: 'WIFI', NameValue: 'Wi-fi' }),
		],
	};

	public static OrderItemReviewStatus = {
		Cancelled: 'CANCELLED',
		Closed: 'CLOSED',
		Open: 'OPEN',
		Pending: 'PENDING',
		Withdrawn: 'WITHDRAWN',

		Names: {
			Cancelled: 'Cancelled',
			Closed: 'Closed',
			Open: 'Open',
			Pending: 'Pending',
			Withdrawn: 'Withdrawn',
		},

		Values: [
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CLOSED', NameValue: 'Closed' }),
			new LookupValue({ CodeValue: 'OPEN', NameValue: 'Open' }),
			new LookupValue({ CodeValue: 'PENDING', NameValue: 'Pending' }),
			new LookupValue({ CodeValue: 'WITHDRAWN', NameValue: 'Withdrawn' }),
		],
	};

	public static PriceType = {
		Override: 'OVERRIDE',
		Quoted: 'QUOTED',
		Recovery: 'RECOVERY',
		Standard: 'STANDARD',

		Names: {
			Override: 'Override',
			Quoted: 'Quoted',
			Recovery: 'Recovery',
			Standard: 'Standard',
		},

		Values: [
			new LookupValue({ CodeValue: 'OVERRIDE', NameValue: 'Override' }),
			new LookupValue({ CodeValue: 'QUOTED', NameValue: 'Quoted' }),
			new LookupValue({ CodeValue: 'RECOVERY', NameValue: 'Recovery' }),
			new LookupValue({ CodeValue: 'STANDARD', NameValue: 'Standard' }),
		],
	};

	public static PurchaseOrderStatus = {
		Allocated: 'ALLOCATED',
		Cancelled: 'CANCELLED',
		Created: 'CREATED',
		DataEntryRequired: 'DATAENTRYREQ',
		InProgress: 'INPROGRESS',
		Paused: 'PAUSED',
		ReviewRequired: 'REVIEWREQ',
		ReviewedApproved: 'REVIEWED',

		Names: {
			Allocated: 'Allocated',
			Cancelled: 'Cancelled',
			Created: 'Created',
			DataEntryRequired: 'Data Entry Required',
			InProgress: 'In Progress',
			Paused: 'Paused',
			ReviewRequired: 'Review Required',
			ReviewedApproved: 'Reviewed & Approved',
		},

		Values: [
			new LookupValue({ CodeValue: 'ALLOCATED', NameValue: 'Allocated' }),
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CREATED', NameValue: 'Created' }),
			new LookupValue({ CodeValue: 'DATAENTRYREQ', NameValue: 'Data Entry Required' }),
			new LookupValue({ CodeValue: 'INPROGRESS', NameValue: 'In Progress' }),
			new LookupValue({ CodeValue: 'PAUSED', NameValue: 'Paused' }),
			new LookupValue({ CodeValue: 'REVIEWREQ', NameValue: 'Review Required' }),
			new LookupValue({ CodeValue: 'REVIEWED', NameValue: 'Reviewed & Approved' }),
		],
	};

	public static PurchaseOrderType = {
		Credit: 'CREDIT',
		Defect: 'DEFECT',
		DefectNotification: 'DEFECTNOTIFY',
		Recovery: 'RECOVERY',
		Standard: 'STANDARD',

		Names: {
			Credit: 'Credit',
			Defect: 'Defect',
			DefectNotification: 'Defect Notification',
			Recovery: 'Recovery',
			Standard: 'Standard',
		},

		Values: [
			new LookupValue({ CodeValue: 'CREDIT', NameValue: 'Credit' }),
			new LookupValue({ CodeValue: 'DEFECT', NameValue: 'Defect' }),
			new LookupValue({ CodeValue: 'DEFECTNOTIFY', NameValue: 'Defect Notification' }),
			new LookupValue({ CodeValue: 'RECOVERY', NameValue: 'Recovery' }),
			new LookupValue({ CodeValue: 'STANDARD', NameValue: 'Standard' }),
		],
	};

	public static QuoteRequestRecipientStatus = {
		Awarded: 'AWARDED',
		Cancelled: 'CANCELLED',
		Created: 'CREATED',
		DeclinedtoQuote: 'DECLINED',
		Responded: 'RESPONDED',
		Sent: 'SENT',
		Unsuccessful: 'UNSUC',

		Names: {
			Awarded: 'Awarded',
			Cancelled: 'Cancelled',
			Created: 'Created',
			DeclinedtoQuote: 'Declined to Quote',
			Responded: 'Responded',
			Sent: 'Sent',
			Unsuccessful: 'Unsuccessful',
		},

		Values: [
			new LookupValue({ CodeValue: 'AWARDED', NameValue: 'Awarded' }),
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CREATED', NameValue: 'Created' }),
			new LookupValue({ CodeValue: 'DECLINED', NameValue: 'Declined to Quote' }),
			new LookupValue({ CodeValue: 'RESPONDED', NameValue: 'Responded' }),
			new LookupValue({ CodeValue: 'SENT', NameValue: 'Sent' }),
			new LookupValue({ CodeValue: 'UNSUC', NameValue: 'Unsuccessful' }),
		],
	};

	public static QuoteRequestStatus = {
		Cancelled: 'CANCELLED',
		Created: 'CREATED',
		Paused: 'PAUSED',
		QuoteAwarded: 'QUOTEAWD',
		QuoteReceived: 'QUOTEREC',
		QuoteRequestSent: 'QUOTESENT',
		QuoteReviewRequired: 'REVIEWREQ',
		Unsuccessful: 'UNSUCCESSFUL',

		Names: {
			Cancelled: 'Cancelled',
			Created: 'Created',
			Paused: 'Paused',
			QuoteAwarded: 'Quote Awarded',
			QuoteReceived: 'Quote Received',
			QuoteRequestSent: 'Quote Sent',
			QuoteReviewRequired: 'Quote Review Required',
			Unsuccessful: 'Unsuccessful',
		},

		Values: [
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CREATED', NameValue: 'Created' }),
			new LookupValue({ CodeValue: 'PAUSED', NameValue: 'Paused' }),
			new LookupValue({ CodeValue: 'QUOTEAWD', NameValue: 'Quote Awarded' }),
			new LookupValue({ CodeValue: 'QUOTEREC', NameValue: 'Quote Received' }),
			new LookupValue({ CodeValue: 'QUOTESENT', NameValue: 'Quote Sent' }),
			new LookupValue({ CodeValue: 'REVIEWREQ', NameValue: 'Quote Review Required' }),
			new LookupValue({ CodeValue: 'UNSUCCESSFUL', NameValue: 'Unsuccessful' }),
		],
	};

	public static RateGroupStatus = {
		Active: 'ACTIVE',
		Disabled: 'DISABLED',
		Retired: 'RETIRED',

		Names: {
			Active: 'Active',
			Disabled: 'Disabled',
			Retired: 'Retired',
		},

		Values: [
			new LookupValue({ CodeValue: 'ACTIVE', NameValue: 'Active' }),
			new LookupValue({ CodeValue: 'DISABLED', NameValue: 'Disabled' }),
			new LookupValue({ CodeValue: 'RETIRED', NameValue: 'Retired' }),
		],
	};

	public static RecordType = {
		Asset: 'ASSET',
		Client: 'CLIENT',
		Contractor: 'CONTRACTOR',
		CreditPurchaseOrder: 'CREDITPO',
		Defect: 'DEFECT',
		Employee: 'EMPLOYEE',
		PayableInvoice: 'PAYINV',
		Problem: 'PROBLEM',
		Property: 'PROPERTY',
		PurchaseOrder: 'PO',
		QuoteRequest: 'QUOTE',
		RateGroup: 'RATEGROUP',
		ReceivableInvoice: 'RECINV',
		RecoveryPurchaseOrder: 'RECOVERYPO',
		Scope: 'SCOPE',
		ServiceRequest: 'SRQ',
		Sor: 'SOR',
		Supplier: 'SUPPLIER',
		WorkOrder: 'WO',

		Names: {
			Asset: 'Asset',
			Client: 'Client',
			Contractor: 'Contractor',
			CreditPurchaseOrder: 'Credit Purchase Order',
			Defect: 'Defect',
			Employee: 'Employee',
			PayableInvoice: 'Payable Invoice',
			Problem: 'Request',
			Property: 'Property',
			PurchaseOrder: 'Purchase Order',
			QuoteRequest: 'Quote',
			RateGroup: 'Rate Group',
			ReceivableInvoice: 'Receivable Invoice',
			RecoveryPurchaseOrder: 'Recovery Purchase Order',
			Scope: 'Scope',
			ServiceRequest: 'Service Request',
			Sor: 'Item',
			Supplier: 'Supplier',
			WorkOrder: 'Work Order',
		},

		Values: [
			new LookupValue({ CodeValue: 'ASSET', NameValue: 'Asset' }),
			new LookupValue({ CodeValue: 'CLIENT', NameValue: 'Client' }),
			new LookupValue({ CodeValue: 'CONTRACTOR', NameValue: 'Contractor' }),
			new LookupValue({ CodeValue: 'CREDITPO', NameValue: 'Credit Purchase Order' }),
			new LookupValue({ CodeValue: 'DEFECT', NameValue: 'Defect' }),
			new LookupValue({ CodeValue: 'EMPLOYEE', NameValue: 'Employee' }),
			new LookupValue({ CodeValue: 'PAYINV', NameValue: 'Payable Invoice' }),
			new LookupValue({ CodeValue: 'PROBLEM', NameValue: 'Request' }),
			new LookupValue({ CodeValue: 'PROPERTY', NameValue: 'Property' }),
			new LookupValue({ CodeValue: 'PO', NameValue: 'Purchase Order' }),
			new LookupValue({ CodeValue: 'QUOTE', NameValue: 'Quote' }),
			new LookupValue({ CodeValue: 'RATEGROUP', NameValue: 'Rate Group' }),
			new LookupValue({ CodeValue: 'RECINV', NameValue: 'Receivable Invoice' }),
			new LookupValue({ CodeValue: 'RECOVERYPO', NameValue: 'Recovery Purchase Order' }),
			new LookupValue({ CodeValue: 'SCOPE', NameValue: 'Scope' }),
			new LookupValue({ CodeValue: 'SRQ', NameValue: 'Service Request' }),
			new LookupValue({ CodeValue: 'SOR', NameValue: 'Item' }),
			new LookupValue({ CodeValue: 'SUPPLIER', NameValue: 'Supplier' }),
			new LookupValue({ CodeValue: 'WO', NameValue: 'Work Order' }),
		],
	};

	public static RecurrencePattern = {
		Daily: 'DAILY',
		DoesNotRepeat: 'NONE',
		Monthly: 'MONTHLY',
		Weekly: 'WEEKLY',
		Yearly: 'YEARLY',

		Names: {
			Daily: 'Daily',
			DoesNotRepeat: 'Does Not Repeat',
			Monthly: 'Monthly',
			Weekly: 'Weekly',
			Yearly: 'Yearly',
		},

		Values: [
			new LookupValue({ CodeValue: 'DAILY', NameValue: 'Daily' }),
			new LookupValue({ CodeValue: 'NONE', NameValue: 'Does Not Repeat' }),
			new LookupValue({ CodeValue: 'MONTHLY', NameValue: 'Monthly' }),
			new LookupValue({ CodeValue: 'WEEKLY', NameValue: 'Weekly' }),
			new LookupValue({ CodeValue: 'YEARLY', NameValue: 'Yearly' }),
		],
	};

	public static ReferenceType = {
		Asset: 'ASSET',
		Attachment: 'ATTACH',
		AttachmentFile: 'ATTACHFILE',
		BusinessEntity: 'BUSINESSENTITY',
		BusinessEntityInsurance: 'BUSINESSENTITYINSURANCE',
		BusinessEntityLicence: 'BUSINESSENTITYLICENCE',
		CalendarEvent: 'CALENDAREVENT',
		Client: 'CLIENT',
		Company: 'COMPANY',
		Contact: 'CONTACT',
		DataTemplate: 'DATATEMPLATE',
		DataTemplateAnswer: 'DATATEMPLATEANS',
		DataTemplateAnswerSet: 'DATATEMPLATEANSSET',
		DataTemplateQuestion: 'DATATEMPLATEQUESTION',
		DataTemplateQuestionSet: 'DATATEMPLATEQUESTIONSET',
		DataTemplateResult: 'DATATEMPLATERES',
		DocumentTemplate: 'DOCTEMPLATE',
		DocumentTemplateConfiguration: 'DOCTEMPLATECONFIG',
		DomainTypeValue: 'DOMVAL',
		FinancialEntity: 'FINANCIALENTITY',
		FlagAlert: 'FLAGALERT',
		IntegrationModule: 'INTMODULE',
		InterfaceExportEvent: 'INTEGEXPORT',
		InterfaceImportEvent: 'INTEGIMPORT',
		Invoice: 'INVOICE',
		InvoiceIssue: 'INVOICEISSUE',
		InvoiceItem: 'INVOICEITEM',
		InvoicePayment: 'INVOICEPAY',
		Map: 'MAP',
		MapMarker: 'MAPMARKER',
		Note: 'NOTE',
		Problem: 'PROBLEM',
		PurchaseOrder: 'PO',
		PurchaseOrderItem: 'POITEM',
		PurchaseOrderItemReview: 'POITEMREVIEW',
		QuoteRequest: 'QUOTE',
		QuoteRequestAsset: 'QUOTEASSET',
		QuoteRequestItem: 'QUOTEITEM',
		QuoteRequestItemResponse: 'QUOTEITEMRES',
		QuoteRequestRecipient: 'QUOTEREC',
		RateGroup: 'RATEGROUP',
		Scope: 'SCOPE',
		ScopeAsset: 'SCOPEASSET',
		ScopeItem: 'SCOPEITEM',
		ServiceArea: 'SVCAREA',
		ServiceProject: 'SVCPRJ',
		ServiceRequest: 'SRQ',
		ServiceRequestAsset: 'SRQASSET',
		ServiceRequestItem: 'SRQITEM',
		Sor: 'SOR',
		SorCostPrice: 'SORCOSTPRICE',
		Subscriber: 'SUB',
		SubscriberContract: 'SUBCONTRACT',
		SubscriptionPlan: 'SUBPLAN',
		SystemDataTemplate: 'SYSDATATEMPLATE',
		SystemDataTemplateQuestion: 'SYSDATATEMPLATEQUESTION',
		SystemDataTemplateQuestionSet: 'SYSDATATEMPLATEQUESTIONSET',
		SystemDocumentTemplate: 'SYSDOCTEMPLATE',
		SystemDocumentTemplateConfiguration: 'SYSDOCTEMPLATECONFIG',
		SystemUserProfile: 'SYSUSER',
		SystemUserRole: 'SYSUSERROLE',
		SystemUserRolePermission: 'SYSUSERROLEPERM',
		UserProfile: 'USER',
		UserRole: 'USERROLE',
		UserRolePermission: 'USERROLEPERM',
		WorkOrder: 'WO',
		WorkOrderAsset: 'WOASSET',
		WorkOrderItem: 'WOITEM',
		WorkOrderItemReview: 'WOITEMREVIEW',

		Names: {
			Asset: 'Asset',
			Attachment: 'Attachment',
			AttachmentFile: 'Attachment File',
			BusinessEntity: 'Business Contact',
			BusinessEntityInsurance: 'Business Contact Insurance',
			BusinessEntityLicence: 'Business Contact Licence',
			CalendarEvent: 'Calendar Event',
			Client: 'Client',
			Company: 'Company',
			Contact: 'Contact',
			DataTemplate: 'Data Template',
			DataTemplateAnswer: 'Data Template Answer',
			DataTemplateAnswerSet: 'Data Template Answer Set',
			DataTemplateQuestion: 'Data Template Question',
			DataTemplateQuestionSet: 'Data Template Question Set',
			DataTemplateResult: 'Data Template Result',
			DocumentTemplate: 'Document Template',
			DocumentTemplateConfiguration: 'Document Template Configuration',
			DomainTypeValue: 'Field',
			FinancialEntity: 'Financial Entity',
			FlagAlert: 'Flag Alert',
			IntegrationModule: 'Integration Module',
			InterfaceExportEvent: 'Interface Export Event',
			InterfaceImportEvent: 'Interface Import Event',
			Invoice: 'Invoice',
			InvoiceIssue: 'Invoice Issue',
			InvoiceItem: 'Invoice Item',
			InvoicePayment: 'Invoice Payment',
			Map: 'Plan',
			MapMarker: 'Plan Marker',
			Note: 'Note',
			Problem: 'Request',
			PurchaseOrder: 'Purchase Order',
			PurchaseOrderItem: 'Purchase Order Item',
			PurchaseOrderItemReview: 'Purchase Order Item Review',
			QuoteRequest: 'Quote',
			QuoteRequestAsset: 'Quote Asset',
			QuoteRequestItem: 'Quote Item',
			QuoteRequestItemResponse: 'Quote Item Response',
			QuoteRequestRecipient: 'Quote Recipient',
			RateGroup: 'Rate Group',
			Scope: 'Scope',
			ScopeAsset: 'Scope Asset',
			ScopeItem: 'Scope Item',
			ServiceArea: 'Area',
			ServiceProject: 'Project',
			ServiceRequest: 'Service Request',
			ServiceRequestAsset: 'Service Request Asset',
			ServiceRequestItem: 'Service Request Problem',
			Sor: 'Item',
			SorCostPrice: 'Item Cost Price',
			Subscriber: 'Subscriber',
			SubscriberContract: 'Subscriber Contract',
			SubscriptionPlan: 'Subscription Plan',
			SystemDataTemplate: 'System Data Template',
			SystemDataTemplateQuestion: 'System Data Template Question',
			SystemDataTemplateQuestionSet: 'System Data Template Question Set',
			SystemDocumentTemplate: 'System Document Template',
			SystemDocumentTemplateConfiguration: 'System Document Template Configuration',
			SystemUserProfile: 'System User Profile',
			SystemUserRole: 'System User Role',
			SystemUserRolePermission: 'System User Role Permission',
			UserProfile: 'User Profile',
			UserRole: 'User Role',
			UserRolePermission: 'User Role Permission',
			WorkOrder: 'Work Order',
			WorkOrderAsset: 'Work Order Asset',
			WorkOrderItem: 'Work Order Item',
			WorkOrderItemReview: 'Work Order Item Review',
		},

		Values: [
			new LookupValue({ CodeValue: 'ASSET', NameValue: 'Asset' }),
			new LookupValue({ CodeValue: 'ATTACH', NameValue: 'Attachment' }),
			new LookupValue({ CodeValue: 'ATTACHFILE', NameValue: 'Attachment File' }),
			new LookupValue({ CodeValue: 'BUSINESSENTITY', NameValue: 'Business Contact' }),
			new LookupValue({ CodeValue: 'BUSINESSENTITYINSURANCE', NameValue: 'Business Contact Insurance' }),
			new LookupValue({ CodeValue: 'BUSINESSENTITYLICENCE', NameValue: 'Business Contact Licence' }),
			new LookupValue({ CodeValue: 'CALENDAREVENT', NameValue: 'Calendar Event' }),
			new LookupValue({ CodeValue: 'CLIENT', NameValue: 'Client' }),
			new LookupValue({ CodeValue: 'COMPANY', NameValue: 'Company' }),
			new LookupValue({ CodeValue: 'CONTACT', NameValue: 'Contact' }),
			new LookupValue({ CodeValue: 'DATATEMPLATE', NameValue: 'Data Template' }),
			new LookupValue({ CodeValue: 'DATATEMPLATEANS', NameValue: 'Data Template Answer' }),
			new LookupValue({ CodeValue: 'DATATEMPLATEANSSET', NameValue: 'Data Template Answer Set' }),
			new LookupValue({ CodeValue: 'DATATEMPLATEQUESTION', NameValue: 'Data Template Question' }),
			new LookupValue({ CodeValue: 'DATATEMPLATEQUESTIONSET', NameValue: 'Data Template Question Set' }),
			new LookupValue({ CodeValue: 'DATATEMPLATERES', NameValue: 'Data Template Result' }),
			new LookupValue({ CodeValue: 'DOCTEMPLATE', NameValue: 'Document Template' }),
			new LookupValue({ CodeValue: 'DOCTEMPLATECONFIG', NameValue: 'Document Template Configuration' }),
			new LookupValue({ CodeValue: 'DOMVAL', NameValue: 'Field' }),
			new LookupValue({ CodeValue: 'FINANCIALENTITY', NameValue: 'Financial Entity' }),
			new LookupValue({ CodeValue: 'FLAGALERT', NameValue: 'Flag Alert' }),
			new LookupValue({ CodeValue: 'INTMODULE', NameValue: 'Integration Module' }),
			new LookupValue({ CodeValue: 'INTEGEXPORT', NameValue: 'Interface Export Event' }),
			new LookupValue({ CodeValue: 'INTEGIMPORT', NameValue: 'Interface Import Event' }),
			new LookupValue({ CodeValue: 'INVOICE', NameValue: 'Invoice' }),
			new LookupValue({ CodeValue: 'INVOICEISSUE', NameValue: 'Invoice Issue' }),
			new LookupValue({ CodeValue: 'INVOICEITEM', NameValue: 'Invoice Item' }),
			new LookupValue({ CodeValue: 'INVOICEPAY', NameValue: 'Invoice Payment' }),
			new LookupValue({ CodeValue: 'MAP', NameValue: 'Plan' }),
			new LookupValue({ CodeValue: 'MAPMARKER', NameValue: 'Plan Marker' }),
			new LookupValue({ CodeValue: 'NOTE', NameValue: 'Note' }),
			new LookupValue({ CodeValue: 'PROBLEM', NameValue: 'Request' }),
			new LookupValue({ CodeValue: 'PO', NameValue: 'Purchase Order' }),
			new LookupValue({ CodeValue: 'POITEM', NameValue: 'Purchase Order Item' }),
			new LookupValue({ CodeValue: 'POITEMREVIEW', NameValue: 'Purchase Order Item Review' }),
			new LookupValue({ CodeValue: 'QUOTE', NameValue: 'Quote' }),
			new LookupValue({ CodeValue: 'QUOTEASSET', NameValue: 'Quote Asset' }),
			new LookupValue({ CodeValue: 'QUOTEITEM', NameValue: 'Quote Item' }),
			new LookupValue({ CodeValue: 'QUOTEITEMRES', NameValue: 'Quote Item Response' }),
			new LookupValue({ CodeValue: 'QUOTEREC', NameValue: 'Quote Recipient' }),
			new LookupValue({ CodeValue: 'RATEGROUP', NameValue: 'Rate Group' }),
			new LookupValue({ CodeValue: 'SCOPE', NameValue: 'Scope' }),
			new LookupValue({ CodeValue: 'SCOPEASSET', NameValue: 'Scope Asset' }),
			new LookupValue({ CodeValue: 'SCOPEITEM', NameValue: 'Scope Item' }),
			new LookupValue({ CodeValue: 'SVCAREA', NameValue: 'Area' }),
			new LookupValue({ CodeValue: 'SVCPRJ', NameValue: 'Project' }),
			new LookupValue({ CodeValue: 'SRQ', NameValue: 'Service Request' }),
			new LookupValue({ CodeValue: 'SRQASSET', NameValue: 'Service Request Asset' }),
			new LookupValue({ CodeValue: 'SRQITEM', NameValue: 'Service Request Problem' }),
			new LookupValue({ CodeValue: 'SOR', NameValue: 'Item' }),
			new LookupValue({ CodeValue: 'SORCOSTPRICE', NameValue: 'Item Cost Price' }),
			new LookupValue({ CodeValue: 'SUB', NameValue: 'Subscriber' }),
			new LookupValue({ CodeValue: 'SUBCONTRACT', NameValue: 'Subscriber Contract' }),
			new LookupValue({ CodeValue: 'SUBPLAN', NameValue: 'Subscription Plan' }),
			new LookupValue({ CodeValue: 'SYSDATATEMPLATE', NameValue: 'System Data Template' }),
			new LookupValue({ CodeValue: 'SYSDATATEMPLATEQUESTION', NameValue: 'System Data Template Question' }),
			new LookupValue({ CodeValue: 'SYSDATATEMPLATEQUESTIONSET', NameValue: 'System Data Template Question Set' }),
			new LookupValue({ CodeValue: 'SYSDOCTEMPLATE', NameValue: 'System Document Template' }),
			new LookupValue({ CodeValue: 'SYSDOCTEMPLATECONFIG', NameValue: 'System Document Template Configuration' }),
			new LookupValue({ CodeValue: 'SYSUSER', NameValue: 'System User Profile' }),
			new LookupValue({ CodeValue: 'SYSUSERROLE', NameValue: 'System User Role' }),
			new LookupValue({ CodeValue: 'SYSUSERROLEPERM', NameValue: 'System User Role Permission' }),
			new LookupValue({ CodeValue: 'USER', NameValue: 'User Profile' }),
			new LookupValue({ CodeValue: 'USERROLE', NameValue: 'User Role' }),
			new LookupValue({ CodeValue: 'USERROLEPERM', NameValue: 'User Role Permission' }),
			new LookupValue({ CodeValue: 'WO', NameValue: 'Work Order' }),
			new LookupValue({ CodeValue: 'WOASSET', NameValue: 'Work Order Asset' }),
			new LookupValue({ CodeValue: 'WOITEM', NameValue: 'Work Order Item' }),
			new LookupValue({ CodeValue: 'WOITEMREVIEW', NameValue: 'Work Order Item Review' }),
		],
	};

	public static Region = {
		EnglishAustralia: 'en-au',
		EnglishGreatBritain: 'en-gb',
		EnglishUnitedStates: 'en-us',

		Names: {
			EnglishAustralia: 'English (Australia)',
			EnglishGreatBritain: 'English (Great Britain)',
			EnglishUnitedStates: 'English (United States)',
		},

		Values: [
			new LookupValue({ CodeValue: 'en-au', NameValue: 'English (Australia)' }),
			new LookupValue({ CodeValue: 'en-gb', NameValue: 'English (Great Britain)' }),
			new LookupValue({ CodeValue: 'en-us', NameValue: 'English (United States)' }),
		],
	};

	public static ScopeStatus = {
		Allocated: 'ALLOCATED',
		Cancelled: 'CANCELLED',
		Created: 'CREATED',
		DataEntryRequired: 'DATAENTRYREQ',
		Paused: 'PAUSED',
		ReviewRequired: 'REVIEWREQ',
		ReviewedApproved: 'REVIEWED',

		Names: {
			Allocated: 'Allocated',
			Cancelled: 'Cancelled',
			Created: 'Created',
			DataEntryRequired: 'Data Entry Required',
			Paused: 'Paused',
			ReviewRequired: 'Review Required',
			ReviewedApproved: 'Reviewed & Approved',
		},

		Values: [
			new LookupValue({ CodeValue: 'ALLOCATED', NameValue: 'Allocated' }),
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CREATED', NameValue: 'Created' }),
			new LookupValue({ CodeValue: 'DATAENTRYREQ', NameValue: 'Data Entry Required' }),
			new LookupValue({ CodeValue: 'PAUSED', NameValue: 'Paused' }),
			new LookupValue({ CodeValue: 'REVIEWREQ', NameValue: 'Review Required' }),
			new LookupValue({ CodeValue: 'REVIEWED', NameValue: 'Reviewed & Approved' }),
		],
	};

	public static SearchFilterType = {
		DashboardContractorStatistics: 'DB_CONSTAT',
		DashboardInterfaceEvents: 'DB_INTERFACE',
		DashboardOpenServiceRequests: 'DB_SRQ',
		DashboardPOBreakdown: 'DB_POBREAK',
		DashboardPOVolumebyPriority: 'DB_POVOLUME',
		DashboardReadyforAction: 'DB_RFA',
		DashboardScorecard: 'DB_SC',
		DashboardSpendTrend: 'DB_ST',
		DashboardTimeKPI: 'DB_TIME',
		DashboardUnpaidInvoices: 'DB_INV',

		Names: {
			DashboardContractorStatistics: 'Dashboard - Contractor Statistics',
			DashboardInterfaceEvents: 'Dashboard - Interface Events',
			DashboardOpenServiceRequests: 'Dashboard - Open Service Requests',
			DashboardPOBreakdown: 'Dashboard - PO Breakdown',
			DashboardPOVolumebyPriority: 'Dashboard - PO Volume by Priority',
			DashboardReadyforAction: 'Dashboard - Ready for Action',
			DashboardScorecard: 'Dashboard - Scorecard',
			DashboardSpendTrend: 'Dashboard - Spend Trend',
			DashboardTimeKPI: 'Dashboard - Time KPI %',
			DashboardUnpaidInvoices: 'Dashboard - Unpaid Invoices',
		},

		Values: [
			new LookupValue({ CodeValue: 'DB_CONSTAT', NameValue: 'Dashboard - Contractor Statistics' }),
			new LookupValue({ CodeValue: 'DB_INTERFACE', NameValue: 'Dashboard - Interface Events' }),
			new LookupValue({ CodeValue: 'DB_SRQ', NameValue: 'Dashboard - Open Service Requests' }),
			new LookupValue({ CodeValue: 'DB_POBREAK', NameValue: 'Dashboard - PO Breakdown' }),
			new LookupValue({ CodeValue: 'DB_POVOLUME', NameValue: 'Dashboard - PO Volume by Priority' }),
			new LookupValue({ CodeValue: 'DB_RFA', NameValue: 'Dashboard - Ready for Action' }),
			new LookupValue({ CodeValue: 'DB_SC', NameValue: 'Dashboard - Scorecard' }),
			new LookupValue({ CodeValue: 'DB_ST', NameValue: 'Dashboard - Spend Trend' }),
			new LookupValue({ CodeValue: 'DB_TIME', NameValue: 'Dashboard - Time KPI %' }),
			new LookupValue({ CodeValue: 'DB_INV', NameValue: 'Dashboard - Unpaid Invoices' }),
		],
	};

	public static ServiceRequestStatus = {
		Accepted: 'ACCEPTED',
		Cancelled: 'CANCELLED',
		Closed: 'CLOSED',
		Created: 'CREATED',
		Issued: 'ISSUED',
		Rejected: 'REJECTED',

		Names: {
			Accepted: 'Accepted',
			Cancelled: 'Cancelled',
			Closed: 'Closed',
			Created: 'Created',
			Issued: 'Issued',
			Rejected: 'Rejected',
		},

		Values: [
			new LookupValue({ CodeValue: 'ACCEPTED', NameValue: 'Accepted' }),
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CLOSED', NameValue: 'Closed' }),
			new LookupValue({ CodeValue: 'CREATED', NameValue: 'Created' }),
			new LookupValue({ CodeValue: 'ISSUED', NameValue: 'Issued' }),
			new LookupValue({ CodeValue: 'REJECTED', NameValue: 'Rejected' }),
		],
	};

	public static SubscriberDomainType = {
		AssetAttachmentType: 'Asset Attachment Type',
		AssetCategory: 'Asset Category',
		AssetDisposalMethod: 'Asset Disposal Method',
		AssetEndofLife: 'Asset End of Life',
		AssetNoteCategory: 'Asset Note Category',
		AssetType: 'Asset Type',
		BusinessEntityAttachmentType: 'Business Entity Attachment Type',
		BusinessEntityNoteCategory: 'Business Entity Note Category',
		CalendarEventType: 'Calendar Event Type',
		ClientAttachmentType: 'Client Attachment Type',
		ClientNoteCategory: 'Client Note Category',
		Colour: 'Colour',
		ContactType: 'Contact Type',
		ContractorEntityType: 'Contractor Entity Type',
		Country: 'Country',
		DefectPurchaseOrderPriority: 'Defect Purchase Order Priority',
		Estimator: 'Estimator',
		FlagAlertType: 'Flag Alert Type',
		Inspector: 'Inspector',
		InsuranceType: 'Insurance Type',
		InvoiceAttachmentType: 'Invoice Attachment Type',
		InvoiceCategory: 'Invoice Category',
		InvoiceNoteCategory: 'Invoice Note Category',
		ItemLocation: 'Item Location',
		ItemReviewResult: 'Item Review Result',
		Language: 'Language',
		LiabilityType: 'Liability Type',
		LicenceCertificateType: 'Licence / Certificate Type',
		NoteAlertType: 'Note Alert Type',
		OrderPriority: 'Order Priority',
		Owner: 'Owner',
		PaymentTerms: 'Payment Terms',
		PriceOverrideReason: 'Price Override Reason',
		PurchaseOrderAttachmentType: 'Purchase Order Attachment Type',
		PurchaseOrderNoteCategory: 'Purchase Order Note Category',
		QuoteRequestAttachmentType: 'Quote Request Attachment Type',
		QuoteRequestNoteCategory: 'Quote Request Note Category',
		ScopeAttachmentType: 'Scope Attachment Type',
		ScopeNoteCategory: 'Scope Note Category',
		ServiceRequestAttachmentType: 'Service Request Attachment Type',
		ServiceRequestNoteCategory: 'Service Request Note Category',
		ServiceRequestType: 'Service Request Type',
		TaxCode: 'Tax Code',
		TradeType: 'Trade Type',
		UnitofMeasure: 'Unit of Measure',
		VehicleAssetAxleCode: 'Vehicle Asset Axle Code',
		VehicleAssetColour: 'Vehicle Asset Colour',
		VehicleAssetFuelType: 'Vehicle Asset Fuel Type',
		VehicleAssetMake: 'Vehicle Asset Make',
		VehicleAssetShape: 'Vehicle Asset Shape',
		WorkOrderAttachmentType: 'Work Order Attachment Type',
		WorkOrderNoteCategory: 'Work Order Note Category',
		WorkServiceType: 'Work Service Type',

		Names: {
			AssetAttachmentType: 'Asset Attachment Type',
			AssetCategory: 'Asset Category',
			AssetDisposalMethod: 'Asset Disposal Method',
			AssetEndofLife: 'Asset End of Life',
			AssetNoteCategory: 'Asset Note Category',
			AssetType: 'Asset Type',
			BusinessEntityAttachmentType: 'Business Contact Attachment Type',
			BusinessEntityNoteCategory: 'Business Contact Note Category',
			CalendarEventType: 'Calendar Event Type',
			ClientAttachmentType: 'Client Attachment Type',
			ClientNoteCategory: 'Client Note Category',
			Colour: 'Colour',
			ContactType: 'Contact Type',
			ContractorEntityType: 'Contractor Entity Type',
			Country: 'Country',
			DefectPurchaseOrderPriority: 'Defect Purchase Order Priority',
			Estimator: 'Estimator',
			FlagAlertType: 'Flag Alert Type',
			Inspector: 'Inspector',
			InsuranceType: 'Insurance Type',
			InvoiceAttachmentType: 'Invoice Attachment Type',
			InvoiceCategory: 'Invoice Category',
			InvoiceNoteCategory: 'Invoice Note Category',
			ItemLocation: 'Item Location',
			ItemReviewResult: 'Item Review Result',
			Language: 'Language',
			LiabilityType: 'Liability Type',
			LicenceCertificateType: 'Licence / Certificate Type',
			NoteAlertType: 'Note Alert Type',
			OrderPriority: 'Order Priority',
			Owner: 'Owner',
			PaymentTerms: 'Payment Terms',
			PriceOverrideReason: 'Price Override Reason',
			PurchaseOrderAttachmentType: 'Purchase Order Attachment Type',
			PurchaseOrderNoteCategory: 'Purchase Order Note Category',
			QuoteRequestAttachmentType: 'Quote Attachment Type',
			QuoteRequestNoteCategory: 'Quote Note Category',
			ScopeAttachmentType: 'Scope Attachment Type',
			ScopeNoteCategory: 'Scope Note Category',
			ServiceRequestAttachmentType: 'Service Request Attachment Type',
			ServiceRequestNoteCategory: 'Service Request Note Category',
			ServiceRequestType: 'Service Request Type',
			TaxCode: 'Tax Code',
			TradeType: 'Trade Type',
			UnitofMeasure: 'Unit of Measure',
			VehicleAssetAxleCode: 'Vehicle Asset Axle Code',
			VehicleAssetColour: 'Vehicle Asset Colour',
			VehicleAssetFuelType: 'Vehicle Asset Fuel Type',
			VehicleAssetMake: 'Vehicle Asset Make',
			VehicleAssetShape: 'Vehicle Asset Shape',
			WorkOrderAttachmentType: 'Work Order Attachment Type',
			WorkOrderNoteCategory: 'Work Order Note Category',
			WorkServiceType: 'Work Service Type',
		},

		Values: [
			new LookupValue({ CodeValue: 'Asset Attachment Type', NameValue: 'Asset Attachment Type' }),
			new LookupValue({ CodeValue: 'Asset Category', NameValue: 'Asset Category' }),
			new LookupValue({ CodeValue: 'Asset Disposal Method', NameValue: 'Asset Disposal Method' }),
			new LookupValue({ CodeValue: 'Asset End of Life', NameValue: 'Asset End of Life' }),
			new LookupValue({ CodeValue: 'Asset Note Category', NameValue: 'Asset Note Category' }),
			new LookupValue({ CodeValue: 'Asset Type', NameValue: 'Asset Type' }),
			new LookupValue({ CodeValue: 'Business Entity Attachment Type', NameValue: 'Business Contact Attachment Type' }),
			new LookupValue({ CodeValue: 'Business Entity Note Category', NameValue: 'Business Contact Note Category' }),
			new LookupValue({ CodeValue: 'Calendar Event Type', NameValue: 'Calendar Event Type' }),
			new LookupValue({ CodeValue: 'Client Attachment Type', NameValue: 'Client Attachment Type' }),
			new LookupValue({ CodeValue: 'Client Note Category', NameValue: 'Client Note Category' }),
			new LookupValue({ CodeValue: 'Colour', NameValue: 'Colour' }),
			new LookupValue({ CodeValue: 'Contact Type', NameValue: 'Contact Type' }),
			new LookupValue({ CodeValue: 'Contractor Entity Type', NameValue: 'Contractor Entity Type' }),
			new LookupValue({ CodeValue: 'Country', NameValue: 'Country' }),
			new LookupValue({ CodeValue: 'Defect Purchase Order Priority', NameValue: 'Defect Purchase Order Priority' }),
			new LookupValue({ CodeValue: 'Estimator', NameValue: 'Estimator' }),
			new LookupValue({ CodeValue: 'Flag Alert Type', NameValue: 'Flag Alert Type' }),
			new LookupValue({ CodeValue: 'Inspector', NameValue: 'Inspector' }),
			new LookupValue({ CodeValue: 'Insurance Type', NameValue: 'Insurance Type' }),
			new LookupValue({ CodeValue: 'Invoice Attachment Type', NameValue: 'Invoice Attachment Type' }),
			new LookupValue({ CodeValue: 'Invoice Category', NameValue: 'Invoice Category' }),
			new LookupValue({ CodeValue: 'Invoice Note Category', NameValue: 'Invoice Note Category' }),
			new LookupValue({ CodeValue: 'Item Location', NameValue: 'Item Location' }),
			new LookupValue({ CodeValue: 'Item Review Result', NameValue: 'Item Review Result' }),
			new LookupValue({ CodeValue: 'Language', NameValue: 'Language' }),
			new LookupValue({ CodeValue: 'Liability Type', NameValue: 'Liability Type' }),
			new LookupValue({ CodeValue: 'Licence / Certificate Type', NameValue: 'Licence / Certificate Type' }),
			new LookupValue({ CodeValue: 'Note Alert Type', NameValue: 'Note Alert Type' }),
			new LookupValue({ CodeValue: 'Order Priority', NameValue: 'Order Priority' }),
			new LookupValue({ CodeValue: 'Owner', NameValue: 'Owner' }),
			new LookupValue({ CodeValue: 'Payment Terms', NameValue: 'Payment Terms' }),
			new LookupValue({ CodeValue: 'Price Override Reason', NameValue: 'Price Override Reason' }),
			new LookupValue({ CodeValue: 'Purchase Order Attachment Type', NameValue: 'Purchase Order Attachment Type' }),
			new LookupValue({ CodeValue: 'Purchase Order Note Category', NameValue: 'Purchase Order Note Category' }),
			new LookupValue({ CodeValue: 'Quote Request Attachment Type', NameValue: 'Quote Attachment Type' }),
			new LookupValue({ CodeValue: 'Quote Request Note Category', NameValue: 'Quote Note Category' }),
			new LookupValue({ CodeValue: 'Scope Attachment Type', NameValue: 'Scope Attachment Type' }),
			new LookupValue({ CodeValue: 'Scope Note Category', NameValue: 'Scope Note Category' }),
			new LookupValue({ CodeValue: 'Service Request Attachment Type', NameValue: 'Service Request Attachment Type' }),
			new LookupValue({ CodeValue: 'Service Request Note Category', NameValue: 'Service Request Note Category' }),
			new LookupValue({ CodeValue: 'Service Request Type', NameValue: 'Service Request Type' }),
			new LookupValue({ CodeValue: 'Tax Code', NameValue: 'Tax Code' }),
			new LookupValue({ CodeValue: 'Trade Type', NameValue: 'Trade Type' }),
			new LookupValue({ CodeValue: 'Unit of Measure', NameValue: 'Unit of Measure' }),
			new LookupValue({ CodeValue: 'Vehicle Asset Axle Code', NameValue: 'Vehicle Asset Axle Code' }),
			new LookupValue({ CodeValue: 'Vehicle Asset Colour', NameValue: 'Vehicle Asset Colour' }),
			new LookupValue({ CodeValue: 'Vehicle Asset Fuel Type', NameValue: 'Vehicle Asset Fuel Type' }),
			new LookupValue({ CodeValue: 'Vehicle Asset Make', NameValue: 'Vehicle Asset Make' }),
			new LookupValue({ CodeValue: 'Vehicle Asset Shape', NameValue: 'Vehicle Asset Shape' }),
			new LookupValue({ CodeValue: 'Work Order Attachment Type', NameValue: 'Work Order Attachment Type' }),
			new LookupValue({ CodeValue: 'Work Order Note Category', NameValue: 'Work Order Note Category' }),
			new LookupValue({ CodeValue: 'Work Service Type', NameValue: 'Work Service Type' }),
		],
	};

	public static SubscriberStatus = {
		Active: 'Active',
		Disabled: 'Disabled',
		New: 'New',
		Readonly: 'Readonly',

		Names: {
			Active: 'Active',
			Disabled: 'Disabled',
			New: 'New',
			Readonly: 'Readonly',
		},

		Values: [
			new LookupValue({ CodeValue: 'Active', NameValue: 'Active' }),
			new LookupValue({ CodeValue: 'Disabled', NameValue: 'Disabled' }),
			new LookupValue({ CodeValue: 'New', NameValue: 'New' }),
			new LookupValue({ CodeValue: 'Readonly', NameValue: 'Readonly' }),
		],
	};

	public static SubscriptionAccessType = {
		Full: 'Full',
		Mobile: 'Mobile',
		Web: 'Web',

		Names: {
			Full: 'Full',
			Mobile: 'Mobile',
			Web: 'Web',
		},

		Values: [
			new LookupValue({ CodeValue: 'Full', NameValue: 'Full' }),
			new LookupValue({ CodeValue: 'Mobile', NameValue: 'Mobile' }),
			new LookupValue({ CodeValue: 'Web', NameValue: 'Web' }),
		],
	};

	public static SystemDomainType = {
		BillingAssetType: 'Billing Asset Type',
		BusinessEntityRelationType: 'Business Entity Relation Type',
		BusinessEntityStatus: 'Business Entity Status',
		ClientStatus: 'Client Status',
		CompanyStatus: 'Company Status',
		DashboardObjectClassification: 'Dashboard Object Classification',
		DashboardRefreshPeriod: 'Dashboard Refresh Period',
		DashboardWidget: 'Dashboard Widget',
		DashboardWidgetSource: 'Dashboard Widget Source',
		DataTemplateQuestionType: 'Data Template Question Type',
		DataTemplateSystemType: 'Data Template System Type',
		DayofWeek: 'Day of Week',
		DocumentActionType: 'Document Action Type',
		DocumentDestinationType: 'Document Destination Type',
		DocumentOutputType: 'Document Output Type',
		DocumentSourceType: 'Document Source Type',
		DomainDetailType: 'Domain Detail Type',
		DomainSystemType: 'Domain System Type',
		DurationType: 'Duration Type',
		EntitySize: 'Entity Size',
		ExternalFinanceType: 'External Finance Type',
		ExternalNotificationType: 'External Notification Type',
		FieldVisibility: 'Field Visibility',
		FileStoreType: 'File Store Type',
		FinancialEntityType: 'Financial Entity Type',
		GroupLookupType: 'Group Lookup Type',
		HistoryFieldType: 'History Field Type',
		IntegrationEventFileType: 'Integration Event File Type',
		IntegrationEventStatus: 'Integration Event Status',
		IntegrationEventType: 'Integration Event Type',
		IntegrationModuleStatus: 'Integration Module Status',
		IntegrationModuleType: 'Integration Module Type',
		IntegrationQueueType: 'Integration Queue Type',
		InvoiceCreationRule: 'Invoice Creation Rule',
		InvoiceIssueType: 'Invoice Issue Type',
		InvoiceStatus: 'Invoice Status',
		InvoiceSubtype: 'Invoice Subtype',
		InvoiceType: 'Invoice Type',
		MapMarkerStyle: 'Map Marker Style',
		OrderItemReviewStatus: 'Order Item Review Status',
		PriceType: 'Price Type',
		PurchaseOrderStatus: 'Purchase Order Status',
		PurchaseOrderType: 'Purchase Order Type',
		QuoteRequestRecipientStatus: 'Quote Request Recipient Status',
		QuoteRequestStatus: 'Quote Request Status',
		RateGroupStatus: 'Rate Group Status',
		RecordType: 'Record Type',
		RecurrencePattern: 'Recurrence Pattern',
		ReferenceType: 'Reference Type',
		Region: 'Region',
		ScopeStatus: 'Scope Status',
		SearchFilterType: 'Search Filter Type',
		ServiceRequestStatus: 'Service Request Status',
		SubscriberDomainType: 'Subscriber Domain Type',
		SubscriberStatus: 'Subscriber Status',
		SubscriptionAccessType: 'Subscription Access Type',
		SystemConstantType: 'System Domain Type',
		SystemReport: 'System Report',
		Timezone: 'Timezone',
		UserPermission: 'User Permission',
		UserProfileStatus: 'User Profile Status',
		UserRelationType: 'User Relation Type',
		WeeklyRecurrenceOption: 'Weekly Recurrence Option',
		WorkOrderStatus: 'Work Order Status',
		WorkOrderType: 'Work Order Type',

		Names: {
			BillingAssetType: 'Billing Asset Type',
			BusinessEntityRelationType: 'Business Contact Relation Type',
			BusinessEntityStatus: 'Business Contact Status',
			ClientStatus: 'Client Status',
			CompanyStatus: 'Company Status',
			DashboardObjectClassification: 'Dashboard Object Classification',
			DashboardRefreshPeriod: 'Dashboard Refresh Period',
			DashboardWidget: 'Dashboard Widget',
			DashboardWidgetSource: 'Dashboard Widget Source',
			DataTemplateQuestionType: 'Data Template Question Type',
			DataTemplateSystemType: 'Data Template System Type',
			DayofWeek: 'Day of Week',
			DocumentActionType: 'Document Action Type',
			DocumentDestinationType: 'Document Destination Type',
			DocumentOutputType: 'Document Output Type',
			DocumentSourceType: 'Document Source Type',
			DomainDetailType: 'Field Detail Type',
			DomainSystemType: 'Field System Type',
			DurationType: 'Duration Type',
			EntitySize: 'Entity Size',
			ExternalFinanceType: 'External Finance Type',
			ExternalNotificationType: 'External Notification Type',
			FieldVisibility: 'Field Visibility',
			FileStoreType: 'File Store Type',
			FinancialEntityType: 'Financial Entity Type',
			GroupLookupType: 'Group Lookup Type',
			HistoryFieldType: 'History Field Type',
			IntegrationEventFileType: 'Integration Event File Type',
			IntegrationEventStatus: 'Integration Event Status',
			IntegrationEventType: 'Integration Event Type',
			IntegrationModuleStatus: 'Integration Module Status',
			IntegrationModuleType: 'Integration Module Type',
			IntegrationQueueType: 'Integration Queue Type',
			InvoiceCreationRule: 'Invoice Creation Rule',
			InvoiceIssueType: 'Invoice Issue Type',
			InvoiceStatus: 'Invoice Status',
			InvoiceSubtype: 'Invoice Subtype',
			InvoiceType: 'Invoice Type',
			MapMarkerStyle: 'Plan Marker Style',
			OrderItemReviewStatus: 'Order Item Review Status',
			PriceType: 'Price Type',
			PurchaseOrderStatus: 'Purchase Order Status',
			PurchaseOrderType: 'Purchase Order Type',
			QuoteRequestRecipientStatus: 'Quote Request Recipient Status',
			QuoteRequestStatus: 'Quote Request Status',
			RateGroupStatus: 'Rate Group Status',
			RecordType: 'Record Type',
			RecurrencePattern: 'Recurrence Pattern',
			ReferenceType: 'Reference Type',
			Region: 'Region',
			ScopeStatus: 'Scope Status',
			SearchFilterType: 'Search Filter Type',
			ServiceRequestStatus: 'Service Request Status',
			SubscriberDomainType: 'Subscriber Domain Type',
			SubscriberStatus: 'Subscriber Status',
			SubscriptionAccessType: 'Subscription Access Type',
			SystemConstantType: 'System Domain Type',
			SystemReport: 'System Report',
			Timezone: 'Timezone',
			UserPermission: 'User Permission',
			UserProfileStatus: 'User Profile Status',
			UserRelationType: 'User Relation Type',
			WeeklyRecurrenceOption: 'Weekly Recurrence Option',
			WorkOrderStatus: 'Work Order Status',
			WorkOrderType: 'Work Order Type',
		},

		Values: [
			new LookupValue({ CodeValue: 'Billing Asset Type', NameValue: 'Billing Asset Type' }),
			new LookupValue({ CodeValue: 'Business Entity Relation Type', NameValue: 'Business Contact Relation Type' }),
			new LookupValue({ CodeValue: 'Business Entity Status', NameValue: 'Business Contact Status' }),
			new LookupValue({ CodeValue: 'Client Status', NameValue: 'Client Status' }),
			new LookupValue({ CodeValue: 'Company Status', NameValue: 'Company Status' }),
			new LookupValue({ CodeValue: 'Dashboard Object Classification', NameValue: 'Dashboard Object Classification' }),
			new LookupValue({ CodeValue: 'Dashboard Refresh Period', NameValue: 'Dashboard Refresh Period' }),
			new LookupValue({ CodeValue: 'Dashboard Widget', NameValue: 'Dashboard Widget' }),
			new LookupValue({ CodeValue: 'Dashboard Widget Source', NameValue: 'Dashboard Widget Source' }),
			new LookupValue({ CodeValue: 'Data Template Question Type', NameValue: 'Data Template Question Type' }),
			new LookupValue({ CodeValue: 'Data Template System Type', NameValue: 'Data Template System Type' }),
			new LookupValue({ CodeValue: 'Day of Week', NameValue: 'Day of Week' }),
			new LookupValue({ CodeValue: 'Document Action Type', NameValue: 'Document Action Type' }),
			new LookupValue({ CodeValue: 'Document Destination Type', NameValue: 'Document Destination Type' }),
			new LookupValue({ CodeValue: 'Document Output Type', NameValue: 'Document Output Type' }),
			new LookupValue({ CodeValue: 'Document Source Type', NameValue: 'Document Source Type' }),
			new LookupValue({ CodeValue: 'Domain Detail Type', NameValue: 'Field Detail Type' }),
			new LookupValue({ CodeValue: 'Domain System Type', NameValue: 'Field System Type' }),
			new LookupValue({ CodeValue: 'Duration Type', NameValue: 'Duration Type' }),
			new LookupValue({ CodeValue: 'Entity Size', NameValue: 'Entity Size' }),
			new LookupValue({ CodeValue: 'External Finance Type', NameValue: 'External Finance Type' }),
			new LookupValue({ CodeValue: 'External Notification Type', NameValue: 'External Notification Type' }),
			new LookupValue({ CodeValue: 'Field Visibility', NameValue: 'Field Visibility' }),
			new LookupValue({ CodeValue: 'File Store Type', NameValue: 'File Store Type' }),
			new LookupValue({ CodeValue: 'Financial Entity Type', NameValue: 'Financial Entity Type' }),
			new LookupValue({ CodeValue: 'Group Lookup Type', NameValue: 'Group Lookup Type' }),
			new LookupValue({ CodeValue: 'History Field Type', NameValue: 'History Field Type' }),
			new LookupValue({ CodeValue: 'Integration Event File Type', NameValue: 'Integration Event File Type' }),
			new LookupValue({ CodeValue: 'Integration Event Status', NameValue: 'Integration Event Status' }),
			new LookupValue({ CodeValue: 'Integration Event Type', NameValue: 'Integration Event Type' }),
			new LookupValue({ CodeValue: 'Integration Module Status', NameValue: 'Integration Module Status' }),
			new LookupValue({ CodeValue: 'Integration Module Type', NameValue: 'Integration Module Type' }),
			new LookupValue({ CodeValue: 'Integration Queue Type', NameValue: 'Integration Queue Type' }),
			new LookupValue({ CodeValue: 'Invoice Creation Rule', NameValue: 'Invoice Creation Rule' }),
			new LookupValue({ CodeValue: 'Invoice Issue Type', NameValue: 'Invoice Issue Type' }),
			new LookupValue({ CodeValue: 'Invoice Status', NameValue: 'Invoice Status' }),
			new LookupValue({ CodeValue: 'Invoice Subtype', NameValue: 'Invoice Subtype' }),
			new LookupValue({ CodeValue: 'Invoice Type', NameValue: 'Invoice Type' }),
			new LookupValue({ CodeValue: 'Map Marker Style', NameValue: 'Plan Marker Style' }),
			new LookupValue({ CodeValue: 'Order Item Review Status', NameValue: 'Order Item Review Status' }),
			new LookupValue({ CodeValue: 'Price Type', NameValue: 'Price Type' }),
			new LookupValue({ CodeValue: 'Purchase Order Status', NameValue: 'Purchase Order Status' }),
			new LookupValue({ CodeValue: 'Purchase Order Type', NameValue: 'Purchase Order Type' }),
			new LookupValue({ CodeValue: 'Quote Request Recipient Status', NameValue: 'Quote Request Recipient Status' }),
			new LookupValue({ CodeValue: 'Quote Request Status', NameValue: 'Quote Request Status' }),
			new LookupValue({ CodeValue: 'Rate Group Status', NameValue: 'Rate Group Status' }),
			new LookupValue({ CodeValue: 'Record Type', NameValue: 'Record Type' }),
			new LookupValue({ CodeValue: 'Recurrence Pattern', NameValue: 'Recurrence Pattern' }),
			new LookupValue({ CodeValue: 'Reference Type', NameValue: 'Reference Type' }),
			new LookupValue({ CodeValue: 'Region', NameValue: 'Region' }),
			new LookupValue({ CodeValue: 'Scope Status', NameValue: 'Scope Status' }),
			new LookupValue({ CodeValue: 'Search Filter Type', NameValue: 'Search Filter Type' }),
			new LookupValue({ CodeValue: 'Service Request Status', NameValue: 'Service Request Status' }),
			new LookupValue({ CodeValue: 'Subscriber Domain Type', NameValue: 'Subscriber Domain Type' }),
			new LookupValue({ CodeValue: 'Subscriber Status', NameValue: 'Subscriber Status' }),
			new LookupValue({ CodeValue: 'Subscription Access Type', NameValue: 'Subscription Access Type' }),
			new LookupValue({ CodeValue: 'System Domain Type', NameValue: 'System Domain Type' }),
			new LookupValue({ CodeValue: 'System Report', NameValue: 'System Report' }),
			new LookupValue({ CodeValue: 'Timezone', NameValue: 'Timezone' }),
			new LookupValue({ CodeValue: 'User Permission', NameValue: 'User Permission' }),
			new LookupValue({ CodeValue: 'User Profile Status', NameValue: 'User Profile Status' }),
			new LookupValue({ CodeValue: 'User Relation Type', NameValue: 'User Relation Type' }),
			new LookupValue({ CodeValue: 'Weekly Recurrence Option', NameValue: 'Weekly Recurrence Option' }),
			new LookupValue({ CodeValue: 'Work Order Status', NameValue: 'Work Order Status' }),
			new LookupValue({ CodeValue: 'Work Order Type', NameValue: 'Work Order Type' }),
		],
	};

	public static SystemReport = {
		ContractorStatistics: 'Contractor Statistics',
		InterfaceEvents: 'Interface Events',
		LicenceInsuranceExpiry: 'Licence & Insurance Expiry',
		NDIABudgetReport: 'NDIA - Budget Report',
		NDIABulkClaim: 'NDIA - Bulk Claim',
		NDIAMonthlyStatement: 'NDIA - Monthly Statement',
		POBreakdown: 'PO Breakdown',
		POVolumesbyPriority: 'PO Volumes by Priority',
		PropertyandAssetExtract: 'Property and Asset Extract',
		ReadyforAction: 'Ready for Action',
		Scorecard: 'Scorecard',
		SpendTrend: 'Spend Trend',
		SRQScorecard: 'SRQ Scorecard',
		TimeKPI: 'Time KPI %',
		UnpaidInvoices: 'Unpaid Invoices',
		WorkTypeAnalysis: 'Work Type Analysis',

		Names: {
			ContractorStatistics: 'Contractor Statistics',
			InterfaceEvents: 'Interface Events',
			LicenceInsuranceExpiry: 'Licence & Insurance Expiry',
			NDIABudgetReport: 'NDIA - Budget Report',
			NDIABulkClaim: 'NDIA - Bulk Claim',
			NDIAMonthlyStatement: 'NDIA - Monthly Statement',
			POBreakdown: 'PO Breakdown',
			POVolumesbyPriority: 'PO Volumes by Priority',
			PropertyandAssetExtract: 'Property and Asset Extract',
			ReadyforAction: 'Ready for Action',
			Scorecard: 'Scorecard',
			SpendTrend: 'Spend Trend',
			SRQScorecard: 'SRQ Scorecard',
			TimeKPI: 'Time KPI %',
			UnpaidInvoices: 'Unpaid Invoices',
			WorkTypeAnalysis: 'Work Type Analysis',
		},

		Values: [
			new LookupValue({ CodeValue: 'Contractor Statistics', NameValue: 'Contractor Statistics' }),
			new LookupValue({ CodeValue: 'Interface Events', NameValue: 'Interface Events' }),
			new LookupValue({ CodeValue: 'Licence & Insurance Expiry', NameValue: 'Licence & Insurance Expiry' }),
			new LookupValue({ CodeValue: 'NDIA - Budget Report', NameValue: 'NDIA - Budget Report' }),
			new LookupValue({ CodeValue: 'NDIA - Bulk Claim', NameValue: 'NDIA - Bulk Claim' }),
			new LookupValue({ CodeValue: 'NDIA - Monthly Statement', NameValue: 'NDIA - Monthly Statement' }),
			new LookupValue({ CodeValue: 'PO Breakdown', NameValue: 'PO Breakdown' }),
			new LookupValue({ CodeValue: 'PO Volumes by Priority', NameValue: 'PO Volumes by Priority' }),
			new LookupValue({ CodeValue: 'Property and Asset Extract', NameValue: 'Property and Asset Extract' }),
			new LookupValue({ CodeValue: 'Ready for Action', NameValue: 'Ready for Action' }),
			new LookupValue({ CodeValue: 'Scorecard', NameValue: 'Scorecard' }),
			new LookupValue({ CodeValue: 'Spend Trend', NameValue: 'Spend Trend' }),
			new LookupValue({ CodeValue: 'SRQ Scorecard', NameValue: 'SRQ Scorecard' }),
			new LookupValue({ CodeValue: 'Time KPI %', NameValue: 'Time KPI %' }),
			new LookupValue({ CodeValue: 'Unpaid Invoices', NameValue: 'Unpaid Invoices' }),
			new LookupValue({ CodeValue: 'Work Type Analysis', NameValue: 'Work Type Analysis' }),
		],
	};

	public static Timezone = {
		AfricaAbidjanGMT: 'Africa/Abidjan',
		AfricaAlgiersCET: 'Africa/Algiers',
		AfricaBissauGMT: 'Africa/Bissau',
		AfricaCairoEET: 'Africa/Cairo',
		AfricaCasablanca01000000: 'Africa/Casablanca',
		AfricaCeutaCETCEST: 'Africa/Ceuta',
		AfricaEl_Aaiun01000000: 'Africa/El_Aaiun',
		AfricaJohannesburgSAST: 'Africa/Johannesburg',
		AfricaJubaCAT: 'Africa/Juba',
		AfricaKhartoumCAT: 'Africa/Khartoum',
		AfricaLagosWAT: 'Africa/Lagos',
		AfricaMaputoCAT: 'Africa/Maputo',
		AfricaMonroviaGMT: 'Africa/Monrovia',
		AfricaNairobiEAT: 'Africa/Nairobi',
		AfricaNdjamenaWAT: 'Africa/Ndjamena',
		AfricaSao_TomeGMT: 'Africa/Sao_Tome',
		AfricaTripoliEET: 'Africa/Tripoli',
		AfricaTunisCET: 'Africa/Tunis',
		AfricaWindhoekCAT: 'Africa/Windhoek',
		AmericaAdakHSTHDT: 'America/Adak',
		AmericaAnchorageAKSTAKDT: 'America/Anchorage',
		AmericaAraguaina0300: 'America/Araguaina',
		AmericaArgentinaBuenos_Aires0300: 'America/Argentina/Buenos_Aires',
		AmericaArgentinaCatamarca0300: 'America/Argentina/Catamarca',
		AmericaArgentinaCordoba0300: 'America/Argentina/Cordoba',
		AmericaArgentinaJujuy0300: 'America/Argentina/Jujuy',
		AmericaArgentinaLa_Rioja0300: 'America/Argentina/La_Rioja',
		AmericaArgentinaMendoza0300: 'America/Argentina/Mendoza',
		AmericaArgentinaRio_Gallegos0300: 'America/Argentina/Rio_Gallegos',
		AmericaArgentinaSalta0300: 'America/Argentina/Salta',
		AmericaArgentinaSan_Juan0300: 'America/Argentina/San_Juan',
		AmericaArgentinaSan_Luis0300: 'America/Argentina/San_Luis',
		AmericaArgentinaTucuman0300: 'America/Argentina/Tucuman',
		AmericaArgentinaUshuaia0300: 'America/Argentina/Ushuaia',
		AmericaAsuncion04000300: 'America/Asuncion',
		AmericaBahia0300: 'America/Bahia',
		AmericaBahia_BanderasCST: 'America/Bahia_Banderas',
		AmericaBarbadosAST: 'America/Barbados',
		AmericaBelem0300: 'America/Belem',
		AmericaBelizeCST: 'America/Belize',
		AmericaBoa_Vista0400: 'America/Boa_Vista',
		AmericaBogota0500: 'America/Bogota',
		AmericaBoiseMSTMDT: 'America/Boise',
		AmericaCambridge_BayMSTMDT: 'America/Cambridge_Bay',
		AmericaCampo_Grande0400: 'America/Campo_Grande',
		AmericaCancunEST: 'America/Cancun',
		AmericaCaracas0400: 'America/Caracas',
		AmericaCayenne0300: 'America/Cayenne',
		AmericaChicagoCSTCDT: 'America/Chicago',
		AmericaChihuahuaCST: 'America/Chihuahua',
		AmericaCiudad_JuarezMSTMDT: 'America/Ciudad_Juarez',
		AmericaCosta_RicaCST: 'America/Costa_Rica',
		AmericaCuiaba0400: 'America/Cuiaba',
		AmericaDanmarkshavnGMT: 'America/Danmarkshavn',
		AmericaDawsonMST: 'America/Dawson',
		AmericaDawson_CreekMST: 'America/Dawson_Creek',
		AmericaDenverMSTMDT: 'America/Denver',
		AmericaDetroitESTEDT: 'America/Detroit',
		AmericaEdmontonMSTMDT: 'America/Edmonton',
		AmericaEirunepe0500: 'America/Eirunepe',
		AmericaEl_SalvadorCST: 'America/El_Salvador',
		AmericaFort_NelsonMST: 'America/Fort_Nelson',
		AmericaFortaleza0300: 'America/Fortaleza',
		AmericaGlace_BayASTADT: 'America/Glace_Bay',
		AmericaGoose_BayASTADT: 'America/Goose_Bay',
		AmericaGrand_TurkESTEDT: 'America/Grand_Turk',
		AmericaGuatemalaCST: 'America/Guatemala',
		AmericaGuayaquil0500: 'America/Guayaquil',
		AmericaGuyana0400: 'America/Guyana',
		AmericaHalifaxASTADT: 'America/Halifax',
		AmericaHavanaCSTCDT: 'America/Havana',
		AmericaHermosilloMST: 'America/Hermosillo',
		AmericaIndianaIndianapolisESTEDT: 'America/Indiana/Indianapolis',
		AmericaIndianaKnoxCSTCDT: 'America/Indiana/Knox',
		AmericaIndianaMarengoESTEDT: 'America/Indiana/Marengo',
		AmericaIndianaPetersburgESTEDT: 'America/Indiana/Petersburg',
		AmericaIndianaTell_CityCSTCDT: 'America/Indiana/Tell_City',
		AmericaIndianaVevayESTEDT: 'America/Indiana/Vevay',
		AmericaIndianaVincennesESTEDT: 'America/Indiana/Vincennes',
		AmericaIndianaWinamacESTEDT: 'America/Indiana/Winamac',
		AmericaInuvikMSTMDT: 'America/Inuvik',
		AmericaIqaluitESTEDT: 'America/Iqaluit',
		AmericaJamaicaEST: 'America/Jamaica',
		AmericaJuneauAKSTAKDT: 'America/Juneau',
		AmericaKentuckyLouisvilleESTEDT: 'America/Kentucky/Louisville',
		AmericaKentuckyMonticelloESTEDT: 'America/Kentucky/Monticello',
		AmericaLa_Paz0400: 'America/La_Paz',
		AmericaLima0500: 'America/Lima',
		AmericaLos_AngelesPSTPDT: 'America/Los_Angeles',
		AmericaMaceio0300: 'America/Maceio',
		AmericaManaguaCST: 'America/Managua',
		AmericaManaus0400: 'America/Manaus',
		AmericaMartiniqueAST: 'America/Martinique',
		AmericaMatamorosCSTCDT: 'America/Matamoros',
		AmericaMazatlanMST: 'America/Mazatlan',
		AmericaMenomineeCSTCDT: 'America/Menominee',
		AmericaMeridaCST: 'America/Merida',
		AmericaMetlakatlaAKSTAKDT: 'America/Metlakatla',
		AmericaMexico_CityCST: 'America/Mexico_City',
		AmericaMiquelon03000200: 'America/Miquelon',
		AmericaMonctonASTADT: 'America/Moncton',
		AmericaMonterreyCST: 'America/Monterrey',
		AmericaMontevideo0300: 'America/Montevideo',
		AmericaNew_YorkESTEDT: 'America/New_York',
		AmericaNomeAKSTAKDT: 'America/Nome',
		AmericaNoronha0200: 'America/Noronha',
		AmericaNorth_DakotaBeulahCSTCDT: 'America/North_Dakota/Beulah',
		AmericaNorth_DakotaCenterCSTCDT: 'America/North_Dakota/Center',
		AmericaNorth_DakotaNew_SalemCSTCDT: 'America/North_Dakota/New_Salem',
		AmericaNuuk0300: 'America/Nuuk',
		AmericaOjinagaCSTCDT: 'America/Ojinaga',
		AmericaPanamaEST: 'America/Panama',
		AmericaParamaribo0300: 'America/Paramaribo',
		AmericaPhoenixMST: 'America/Phoenix',
		AmericaPortauPrinceESTEDT: 'America/Port-au-Prince',
		AmericaPorto_Velho0400: 'America/Porto_Velho',
		AmericaPuerto_RicoAST: 'America/Puerto_Rico',
		AmericaPunta_Arenas0300: 'America/Punta_Arenas',
		AmericaRankin_InletCSTCDT: 'America/Rankin_Inlet',
		AmericaRecife0300: 'America/Recife',
		AmericaReginaCST: 'America/Regina',
		AmericaResoluteCSTCDT: 'America/Resolute',
		AmericaRio_Branco0500: 'America/Rio_Branco',
		AmericaSantarem0300: 'America/Santarem',
		AmericaSantiago04000300: 'America/Santiago',
		AmericaSanto_DomingoAST: 'America/Santo_Domingo',
		AmericaSao_Paulo0300: 'America/Sao_Paulo',
		AmericaScoresbysund01000000: 'America/Scoresbysund',
		AmericaSitkaAKSTAKDT: 'America/Sitka',
		AmericaSt_JohnsNSTNDT: 'America/St_Johns',
		AmericaSwift_CurrentCST: 'America/Swift_Current',
		AmericaTegucigalpaCST: 'America/Tegucigalpa',
		AmericaThuleASTADT: 'America/Thule',
		AmericaTijuanaPSTPDT: 'America/Tijuana',
		AmericaTorontoESTEDT: 'America/Toronto',
		AmericaVancouverPSTPDT: 'America/Vancouver',
		AmericaWhitehorseMST: 'America/Whitehorse',
		AmericaWinnipegCSTCDT: 'America/Winnipeg',
		AmericaYakutatAKSTAKDT: 'America/Yakutat',
		AmericaYellowknifeMSTMDT: 'America/Yellowknife',
		AntarcticaCasey1100: 'Antarctica/Casey',
		AntarcticaDavis0700: 'Antarctica/Davis',
		AntarcticaMacquarieAESTAEDT: 'Antarctica/Macquarie',
		AntarcticaMawson0500: 'Antarctica/Mawson',
		AntarcticaPalmer0300: 'Antarctica/Palmer',
		AntarcticaRothera0300: 'Antarctica/Rothera',
		AntarcticaTroll00000200: 'Antarctica/Troll',
		AsiaAlmaty0600: 'Asia/Almaty',
		AsiaAmman0300: 'Asia/Amman',
		AsiaAnadyr1200: 'Asia/Anadyr',
		AsiaAqtau0500: 'Asia/Aqtau',
		AsiaAqtobe0500: 'Asia/Aqtobe',
		AsiaAshgabat0500: 'Asia/Ashgabat',
		AsiaAtyrau0500: 'Asia/Atyrau',
		AsiaBaghdad0300: 'Asia/Baghdad',
		AsiaBaku0400: 'Asia/Baku',
		AsiaBangkok0700: 'Asia/Bangkok',
		AsiaBarnaul0700: 'Asia/Barnaul',
		AsiaBeirutEETEEST: 'Asia/Beirut',
		AsiaBishkek0600: 'Asia/Bishkek',
		AsiaChita0900: 'Asia/Chita',
		AsiaChoibalsan0800: 'Asia/Choibalsan',
		AsiaColombo0530: 'Asia/Colombo',
		AsiaDamascus0300: 'Asia/Damascus',
		AsiaDhaka0600: 'Asia/Dhaka',
		AsiaDili0900: 'Asia/Dili',
		AsiaDubai0400: 'Asia/Dubai',
		AsiaDushanbe0500: 'Asia/Dushanbe',
		AsiaFamagustaEETEEST: 'Asia/Famagusta',
		AsiaGazaEETEEST: 'Asia/Gaza',
		AsiaHebronEETEEST: 'Asia/Hebron',
		AsiaHo_Chi_Minh0700: 'Asia/Ho_Chi_Minh',
		AsiaHong_KongHKT: 'Asia/Hong_Kong',
		AsiaHovd0700: 'Asia/Hovd',
		AsiaIrkutsk0800: 'Asia/Irkutsk',
		AsiaJakartaWIB: 'Asia/Jakarta',
		AsiaJayapuraWIT: 'Asia/Jayapura',
		AsiaJerusalemISTIDT: 'Asia/Jerusalem',
		AsiaKabul0430: 'Asia/Kabul',
		AsiaKamchatka1200: 'Asia/Kamchatka',
		AsiaKarachiPKT: 'Asia/Karachi',
		AsiaKathmandu0545: 'Asia/Kathmandu',
		AsiaKhandyga0900: 'Asia/Khandyga',
		AsiaKolkataIST: 'Asia/Kolkata',
		AsiaKrasnoyarsk0700: 'Asia/Krasnoyarsk',
		AsiaKuching0800: 'Asia/Kuching',
		AsiaMacauCST: 'Asia/Macau',
		AsiaMagadan1100: 'Asia/Magadan',
		AsiaMakassarWITA: 'Asia/Makassar',
		AsiaManilaPST: 'Asia/Manila',
		AsiaNicosiaEETEEST: 'Asia/Nicosia',
		AsiaNovokuznetsk0700: 'Asia/Novokuznetsk',
		AsiaNovosibirsk0700: 'Asia/Novosibirsk',
		AsiaOmsk0600: 'Asia/Omsk',
		AsiaOral0500: 'Asia/Oral',
		AsiaPontianakWIB: 'Asia/Pontianak',
		AsiaPyongyangKST: 'Asia/Pyongyang',
		AsiaQatar0300: 'Asia/Qatar',
		AsiaQostanay0600: 'Asia/Qostanay',
		AsiaQyzylorda0500: 'Asia/Qyzylorda',
		AsiaRiyadh0300: 'Asia/Riyadh',
		AsiaSakhalin1100: 'Asia/Sakhalin',
		AsiaSamarkand0500: 'Asia/Samarkand',
		AsiaSeoulKST: 'Asia/Seoul',
		AsiaShanghaiCST: 'Asia/Shanghai',
		AsiaSingapore0800: 'Asia/Singapore',
		AsiaSrednekolymsk1100: 'Asia/Srednekolymsk',
		AsiaTaipeiCST: 'Asia/Taipei',
		AsiaTashkent0500: 'Asia/Tashkent',
		AsiaTbilisi0400: 'Asia/Tbilisi',
		AsiaTehran0330: 'Asia/Tehran',
		AsiaThimphu0600: 'Asia/Thimphu',
		AsiaTokyoJST: 'Asia/Tokyo',
		AsiaTomsk0700: 'Asia/Tomsk',
		AsiaUlaanbaatar0800: 'Asia/Ulaanbaatar',
		AsiaUrumqi0600: 'Asia/Urumqi',
		AsiaUstNera1000: 'Asia/Ust-Nera',
		AsiaVladivostok1000: 'Asia/Vladivostok',
		AsiaYakutsk0900: 'Asia/Yakutsk',
		AsiaYangon0630: 'Asia/Yangon',
		AsiaYekaterinburg0500: 'Asia/Yekaterinburg',
		AsiaYerevan0400: 'Asia/Yerevan',
		AtlanticAzores01000000: 'Atlantic/Azores',
		AtlanticBermudaASTADT: 'Atlantic/Bermuda',
		AtlanticCanaryWETWEST: 'Atlantic/Canary',
		AtlanticCape_Verde0100: 'Atlantic/Cape_Verde',
		AtlanticFaroeWETWEST: 'Atlantic/Faroe',
		AtlanticMadeiraWETWEST: 'Atlantic/Madeira',
		AtlanticSouth_Georgia0200: 'Atlantic/South_Georgia',
		AtlanticStanley0300: 'Atlantic/Stanley',
		AustraliaAdelaideACSTACDT: 'Australia/Adelaide',
		AustraliaBrisbaneAEST: 'Australia/Brisbane',
		AustraliaBroken_HillACSTACDT: 'Australia/Broken_Hill',
		AustraliaDarwinACST: 'Australia/Darwin',
		AustraliaEucla0845: 'Australia/Eucla',
		AustraliaHobartAESTAEDT: 'Australia/Hobart',
		AustraliaLindemanAEST: 'Australia/Lindeman',
		AustraliaLord_Howe10301100: 'Australia/Lord_Howe',
		AustraliaMelbourneAESTAEDT: 'Australia/Melbourne',
		AustraliaPerthAWST: 'Australia/Perth',
		AustraliaSydneyAESTAEDT: 'Australia/Sydney',
		EuropeAndorraCETCEST: 'Europe/Andorra',
		EuropeAstrakhan0400: 'Europe/Astrakhan',
		EuropeAthensEETEEST: 'Europe/Athens',
		EuropeBelgradeCETCEST: 'Europe/Belgrade',
		EuropeBerlinCETCEST: 'Europe/Berlin',
		EuropeBrusselsCETCEST: 'Europe/Brussels',
		EuropeBucharestEETEEST: 'Europe/Bucharest',
		EuropeBudapestCETCEST: 'Europe/Budapest',
		EuropeChisinauEETEEST: 'Europe/Chisinau',
		EuropeDublinISTGMT: 'Europe/Dublin',
		EuropeGibraltarCETCEST: 'Europe/Gibraltar',
		EuropeHelsinkiEETEEST: 'Europe/Helsinki',
		EuropeIstanbul0300: 'Europe/Istanbul',
		EuropeKaliningradEET: 'Europe/Kaliningrad',
		EuropeKirov0300: 'Europe/Kirov',
		EuropeKyivEETEEST: 'Europe/Kyiv',
		EuropeLisbonWETWEST: 'Europe/Lisbon',
		EuropeLondonGMTBST: 'Europe/London',
		EuropeMadridCETCEST: 'Europe/Madrid',
		EuropeMaltaCETCEST: 'Europe/Malta',
		EuropeMinsk0300: 'Europe/Minsk',
		EuropeMoscowMSK: 'Europe/Moscow',
		EuropeParisCETCEST: 'Europe/Paris',
		EuropePragueCETCEST: 'Europe/Prague',
		EuropeRigaEETEEST: 'Europe/Riga',
		EuropeRomeCETCEST: 'Europe/Rome',
		EuropeSamara0400: 'Europe/Samara',
		EuropeSaratov0400: 'Europe/Saratov',
		EuropeSimferopolMSK: 'Europe/Simferopol',
		EuropeSofiaEETEEST: 'Europe/Sofia',
		EuropeTallinnEETEEST: 'Europe/Tallinn',
		EuropeTiraneCETCEST: 'Europe/Tirane',
		EuropeUlyanovsk0400: 'Europe/Ulyanovsk',
		EuropeViennaCETCEST: 'Europe/Vienna',
		EuropeVilniusEETEEST: 'Europe/Vilnius',
		EuropeVolgograd0300: 'Europe/Volgograd',
		EuropeWarsawCETCEST: 'Europe/Warsaw',
		EuropeZurichCETCEST: 'Europe/Zurich',
		IndianChagos0600: 'Indian/Chagos',
		IndianMaldives0500: 'Indian/Maldives',
		IndianMauritius0400: 'Indian/Mauritius',
		PacificApia1300: 'Pacific/Apia',
		PacificAucklandNZSTNZDT: 'Pacific/Auckland',
		PacificBougainville1100: 'Pacific/Bougainville',
		PacificChatham12451345: 'Pacific/Chatham',
		PacificEaster06000500: 'Pacific/Easter',
		PacificEfate1100: 'Pacific/Efate',
		PacificFakaofo1300: 'Pacific/Fakaofo',
		PacificFiji1200: 'Pacific/Fiji',
		PacificGalapagos0600: 'Pacific/Galapagos',
		PacificGambier0900: 'Pacific/Gambier',
		PacificGuadalcanal1100: 'Pacific/Guadalcanal',
		PacificGuamChST: 'Pacific/Guam',
		PacificHonoluluHST: 'Pacific/Honolulu',
		PacificKanton1300: 'Pacific/Kanton',
		PacificKiritimati1400: 'Pacific/Kiritimati',
		PacificKosrae1100: 'Pacific/Kosrae',
		PacificKwajalein1200: 'Pacific/Kwajalein',
		PacificMarquesas0930: 'Pacific/Marquesas',
		PacificNauru1200: 'Pacific/Nauru',
		PacificNiue1100: 'Pacific/Niue',
		PacificNorfolk11001200: 'Pacific/Norfolk',
		PacificNoumea1100: 'Pacific/Noumea',
		PacificPago_PagoSST: 'Pacific/Pago_Pago',
		PacificPalau0900: 'Pacific/Palau',
		PacificPitcairn0800: 'Pacific/Pitcairn',
		PacificPort_Moresby1000: 'Pacific/Port_Moresby',
		PacificRarotonga1000: 'Pacific/Rarotonga',
		PacificTahiti1000: 'Pacific/Tahiti',
		PacificTarawa1200: 'Pacific/Tarawa',
		PacificTongatapu1300: 'Pacific/Tongatapu',
		UTCGMT: 'Etc/GMT',

		Names: {
			AfricaAbidjanGMT: 'Africa/Abidjan (GMT)',
			AfricaAlgiersCET: 'Africa/Algiers (CET)',
			AfricaBissauGMT: 'Africa/Bissau (GMT)',
			AfricaCairoEET: 'Africa/Cairo (EET)',
			AfricaCasablanca01000000: 'Africa/Casablanca (+01:00/+00:00)',
			AfricaCeutaCETCEST: 'Africa/Ceuta (CET/CEST)',
			AfricaEl_Aaiun01000000: 'Africa/El_Aaiun (+01:00/+00:00)',
			AfricaJohannesburgSAST: 'Africa/Johannesburg (SAST)',
			AfricaJubaCAT: 'Africa/Juba (CAT)',
			AfricaKhartoumCAT: 'Africa/Khartoum (CAT)',
			AfricaLagosWAT: 'Africa/Lagos (WAT)',
			AfricaMaputoCAT: 'Africa/Maputo (CAT)',
			AfricaMonroviaGMT: 'Africa/Monrovia (GMT)',
			AfricaNairobiEAT: 'Africa/Nairobi (EAT)',
			AfricaNdjamenaWAT: 'Africa/Ndjamena (WAT)',
			AfricaSao_TomeGMT: 'Africa/Sao_Tome (GMT)',
			AfricaTripoliEET: 'Africa/Tripoli (EET)',
			AfricaTunisCET: 'Africa/Tunis (CET)',
			AfricaWindhoekCAT: 'Africa/Windhoek (CAT)',
			AmericaAdakHSTHDT: 'America/Adak (HST/HDT)',
			AmericaAnchorageAKSTAKDT: 'America/Anchorage (AKST/AKDT)',
			AmericaAraguaina0300: 'America/Araguaina (-03:00)',
			AmericaArgentinaBuenos_Aires0300: 'America/Argentina/Buenos_Aires (-03:00)',
			AmericaArgentinaCatamarca0300: 'America/Argentina/Catamarca (-03:00)',
			AmericaArgentinaCordoba0300: 'America/Argentina/Cordoba (-03:00)',
			AmericaArgentinaJujuy0300: 'America/Argentina/Jujuy (-03:00)',
			AmericaArgentinaLa_Rioja0300: 'America/Argentina/La_Rioja (-03:00)',
			AmericaArgentinaMendoza0300: 'America/Argentina/Mendoza (-03:00)',
			AmericaArgentinaRio_Gallegos0300: 'America/Argentina/Rio_Gallegos (-03:00)',
			AmericaArgentinaSalta0300: 'America/Argentina/Salta (-03:00)',
			AmericaArgentinaSan_Juan0300: 'America/Argentina/San_Juan (-03:00)',
			AmericaArgentinaSan_Luis0300: 'America/Argentina/San_Luis (-03:00)',
			AmericaArgentinaTucuman0300: 'America/Argentina/Tucuman (-03:00)',
			AmericaArgentinaUshuaia0300: 'America/Argentina/Ushuaia (-03:00)',
			AmericaAsuncion04000300: 'America/Asuncion (-04:00/-03:00)',
			AmericaBahia0300: 'America/Bahia (-03:00)',
			AmericaBahia_BanderasCST: 'America/Bahia_Banderas (CST)',
			AmericaBarbadosAST: 'America/Barbados (AST)',
			AmericaBelem0300: 'America/Belem (-03:00)',
			AmericaBelizeCST: 'America/Belize (CST)',
			AmericaBoa_Vista0400: 'America/Boa_Vista (-04:00)',
			AmericaBogota0500: 'America/Bogota (-05:00)',
			AmericaBoiseMSTMDT: 'America/Boise (MST/MDT)',
			AmericaCambridge_BayMSTMDT: 'America/Cambridge_Bay (MST/MDT)',
			AmericaCampo_Grande0400: 'America/Campo_Grande (-04:00)',
			AmericaCancunEST: 'America/Cancun (EST)',
			AmericaCaracas0400: 'America/Caracas (-04:00)',
			AmericaCayenne0300: 'America/Cayenne (-03:00)',
			AmericaChicagoCSTCDT: 'America/Chicago (CST/CDT)',
			AmericaChihuahuaCST: 'America/Chihuahua (CST)',
			AmericaCiudad_JuarezMSTMDT: 'America/Ciudad_Juarez (MST/MDT)',
			AmericaCosta_RicaCST: 'America/Costa_Rica (CST)',
			AmericaCuiaba0400: 'America/Cuiaba (-04:00)',
			AmericaDanmarkshavnGMT: 'America/Danmarkshavn (GMT)',
			AmericaDawsonMST: 'America/Dawson (MST)',
			AmericaDawson_CreekMST: 'America/Dawson_Creek (MST)',
			AmericaDenverMSTMDT: 'America/Denver (MST/MDT)',
			AmericaDetroitESTEDT: 'America/Detroit (EST/EDT)',
			AmericaEdmontonMSTMDT: 'America/Edmonton (MST/MDT)',
			AmericaEirunepe0500: 'America/Eirunepe (-05:00)',
			AmericaEl_SalvadorCST: 'America/El_Salvador (CST)',
			AmericaFort_NelsonMST: 'America/Fort_Nelson (MST)',
			AmericaFortaleza0300: 'America/Fortaleza (-03:00)',
			AmericaGlace_BayASTADT: 'America/Glace_Bay (AST/ADT)',
			AmericaGoose_BayASTADT: 'America/Goose_Bay (AST/ADT)',
			AmericaGrand_TurkESTEDT: 'America/Grand_Turk (EST/EDT)',
			AmericaGuatemalaCST: 'America/Guatemala (CST)',
			AmericaGuayaquil0500: 'America/Guayaquil (-05:00)',
			AmericaGuyana0400: 'America/Guyana (-04:00)',
			AmericaHalifaxASTADT: 'America/Halifax (AST/ADT)',
			AmericaHavanaCSTCDT: 'America/Havana (CST/CDT)',
			AmericaHermosilloMST: 'America/Hermosillo (MST)',
			AmericaIndianaIndianapolisESTEDT: 'America/Indiana/Indianapolis (EST/EDT)',
			AmericaIndianaKnoxCSTCDT: 'America/Indiana/Knox (CST/CDT)',
			AmericaIndianaMarengoESTEDT: 'America/Indiana/Marengo (EST/EDT)',
			AmericaIndianaPetersburgESTEDT: 'America/Indiana/Petersburg (EST/EDT)',
			AmericaIndianaTell_CityCSTCDT: 'America/Indiana/Tell_City (CST/CDT)',
			AmericaIndianaVevayESTEDT: 'America/Indiana/Vevay (EST/EDT)',
			AmericaIndianaVincennesESTEDT: 'America/Indiana/Vincennes (EST/EDT)',
			AmericaIndianaWinamacESTEDT: 'America/Indiana/Winamac (EST/EDT)',
			AmericaInuvikMSTMDT: 'America/Inuvik (MST/MDT)',
			AmericaIqaluitESTEDT: 'America/Iqaluit (EST/EDT)',
			AmericaJamaicaEST: 'America/Jamaica (EST)',
			AmericaJuneauAKSTAKDT: 'America/Juneau (AKST/AKDT)',
			AmericaKentuckyLouisvilleESTEDT: 'America/Kentucky/Louisville (EST/EDT)',
			AmericaKentuckyMonticelloESTEDT: 'America/Kentucky/Monticello (EST/EDT)',
			AmericaLa_Paz0400: 'America/La_Paz (-04:00)',
			AmericaLima0500: 'America/Lima (-05:00)',
			AmericaLos_AngelesPSTPDT: 'America/Los_Angeles (PST/PDT)',
			AmericaMaceio0300: 'America/Maceio (-03:00)',
			AmericaManaguaCST: 'America/Managua (CST)',
			AmericaManaus0400: 'America/Manaus (-04:00)',
			AmericaMartiniqueAST: 'America/Martinique (AST)',
			AmericaMatamorosCSTCDT: 'America/Matamoros (CST/CDT)',
			AmericaMazatlanMST: 'America/Mazatlan (MST)',
			AmericaMenomineeCSTCDT: 'America/Menominee (CST/CDT)',
			AmericaMeridaCST: 'America/Merida (CST)',
			AmericaMetlakatlaAKSTAKDT: 'America/Metlakatla (AKST/AKDT)',
			AmericaMexico_CityCST: 'America/Mexico_City (CST)',
			AmericaMiquelon03000200: 'America/Miquelon (-03:00/-02:00)',
			AmericaMonctonASTADT: 'America/Moncton (AST/ADT)',
			AmericaMonterreyCST: 'America/Monterrey (CST)',
			AmericaMontevideo0300: 'America/Montevideo (-03:00)',
			AmericaNew_YorkESTEDT: 'America/New_York (EST/EDT)',
			AmericaNomeAKSTAKDT: 'America/Nome (AKST/AKDT)',
			AmericaNoronha0200: 'America/Noronha (-02:00)',
			AmericaNorth_DakotaBeulahCSTCDT: 'America/North_Dakota/Beulah (CST/CDT)',
			AmericaNorth_DakotaCenterCSTCDT: 'America/North_Dakota/Center (CST/CDT)',
			AmericaNorth_DakotaNew_SalemCSTCDT: 'America/North_Dakota/New_Salem (CST/CDT)',
			AmericaNuuk0300: 'America/Nuuk (-03:00)',
			AmericaOjinagaCSTCDT: 'America/Ojinaga (CST/CDT)',
			AmericaPanamaEST: 'America/Panama (EST)',
			AmericaParamaribo0300: 'America/Paramaribo (-03:00)',
			AmericaPhoenixMST: 'America/Phoenix (MST)',
			AmericaPortauPrinceESTEDT: 'America/Port-au-Prince (EST/EDT)',
			AmericaPorto_Velho0400: 'America/Porto_Velho (-04:00)',
			AmericaPuerto_RicoAST: 'America/Puerto_Rico (AST)',
			AmericaPunta_Arenas0300: 'America/Punta_Arenas (-03:00)',
			AmericaRankin_InletCSTCDT: 'America/Rankin_Inlet (CST/CDT)',
			AmericaRecife0300: 'America/Recife (-03:00)',
			AmericaReginaCST: 'America/Regina (CST)',
			AmericaResoluteCSTCDT: 'America/Resolute (CST/CDT)',
			AmericaRio_Branco0500: 'America/Rio_Branco (-05:00)',
			AmericaSantarem0300: 'America/Santarem (-03:00)',
			AmericaSantiago04000300: 'America/Santiago (-04:00/-03:00)',
			AmericaSanto_DomingoAST: 'America/Santo_Domingo (AST)',
			AmericaSao_Paulo0300: 'America/Sao_Paulo (-03:00)',
			AmericaScoresbysund01000000: 'America/Scoresbysund (-01:00/+00:00)',
			AmericaSitkaAKSTAKDT: 'America/Sitka (AKST/AKDT)',
			AmericaSt_JohnsNSTNDT: 'America/St_Johns (NST/NDT)',
			AmericaSwift_CurrentCST: 'America/Swift_Current (CST)',
			AmericaTegucigalpaCST: 'America/Tegucigalpa (CST)',
			AmericaThuleASTADT: 'America/Thule (AST/ADT)',
			AmericaTijuanaPSTPDT: 'America/Tijuana (PST/PDT)',
			AmericaTorontoESTEDT: 'America/Toronto (EST/EDT)',
			AmericaVancouverPSTPDT: 'America/Vancouver (PST/PDT)',
			AmericaWhitehorseMST: 'America/Whitehorse (MST)',
			AmericaWinnipegCSTCDT: 'America/Winnipeg (CST/CDT)',
			AmericaYakutatAKSTAKDT: 'America/Yakutat (AKST/AKDT)',
			AmericaYellowknifeMSTMDT: 'America/Yellowknife (MST/MDT)',
			AntarcticaCasey1100: 'Antarctica/Casey (+11:00)',
			AntarcticaDavis0700: 'Antarctica/Davis (+07:00)',
			AntarcticaMacquarieAESTAEDT: 'Antarctica/Macquarie (AEST/AEDT)',
			AntarcticaMawson0500: 'Antarctica/Mawson (+05:00)',
			AntarcticaPalmer0300: 'Antarctica/Palmer (-03:00)',
			AntarcticaRothera0300: 'Antarctica/Rothera (-03:00)',
			AntarcticaTroll00000200: 'Antarctica/Troll (+00:00/+02:00)',
			AsiaAlmaty0600: 'Asia/Almaty (+06:00)',
			AsiaAmman0300: 'Asia/Amman (+03:00)',
			AsiaAnadyr1200: 'Asia/Anadyr (+12:00)',
			AsiaAqtau0500: 'Asia/Aqtau (+05:00)',
			AsiaAqtobe0500: 'Asia/Aqtobe (+05:00)',
			AsiaAshgabat0500: 'Asia/Ashgabat (+05:00)',
			AsiaAtyrau0500: 'Asia/Atyrau (+05:00)',
			AsiaBaghdad0300: 'Asia/Baghdad (+03:00)',
			AsiaBaku0400: 'Asia/Baku (+04:00)',
			AsiaBangkok0700: 'Asia/Bangkok (+07:00)',
			AsiaBarnaul0700: 'Asia/Barnaul (+07:00)',
			AsiaBeirutEETEEST: 'Asia/Beirut (EET/EEST)',
			AsiaBishkek0600: 'Asia/Bishkek (+06:00)',
			AsiaChita0900: 'Asia/Chita (+09:00)',
			AsiaChoibalsan0800: 'Asia/Choibalsan (+08:00)',
			AsiaColombo0530: 'Asia/Colombo (+05:30)',
			AsiaDamascus0300: 'Asia/Damascus (+03:00)',
			AsiaDhaka0600: 'Asia/Dhaka (+06:00)',
			AsiaDili0900: 'Asia/Dili (+09:00)',
			AsiaDubai0400: 'Asia/Dubai (+04:00)',
			AsiaDushanbe0500: 'Asia/Dushanbe (+05:00)',
			AsiaFamagustaEETEEST: 'Asia/Famagusta (EET/EEST)',
			AsiaGazaEETEEST: 'Asia/Gaza (EET/EEST)',
			AsiaHebronEETEEST: 'Asia/Hebron (EET/EEST)',
			AsiaHo_Chi_Minh0700: 'Asia/Ho_Chi_Minh (+07:00)',
			AsiaHong_KongHKT: 'Asia/Hong_Kong (HKT)',
			AsiaHovd0700: 'Asia/Hovd (+07:00)',
			AsiaIrkutsk0800: 'Asia/Irkutsk (+08:00)',
			AsiaJakartaWIB: 'Asia/Jakarta (WIB)',
			AsiaJayapuraWIT: 'Asia/Jayapura (WIT)',
			AsiaJerusalemISTIDT: 'Asia/Jerusalem (IST/IDT)',
			AsiaKabul0430: 'Asia/Kabul (+04:30)',
			AsiaKamchatka1200: 'Asia/Kamchatka (+12:00)',
			AsiaKarachiPKT: 'Asia/Karachi (PKT)',
			AsiaKathmandu0545: 'Asia/Kathmandu (+05:45)',
			AsiaKhandyga0900: 'Asia/Khandyga (+09:00)',
			AsiaKolkataIST: 'Asia/Kolkata (IST)',
			AsiaKrasnoyarsk0700: 'Asia/Krasnoyarsk (+07:00)',
			AsiaKuching0800: 'Asia/Kuching (+08:00)',
			AsiaMacauCST: 'Asia/Macau (CST)',
			AsiaMagadan1100: 'Asia/Magadan (+11:00)',
			AsiaMakassarWITA: 'Asia/Makassar (WITA)',
			AsiaManilaPST: 'Asia/Manila (PST)',
			AsiaNicosiaEETEEST: 'Asia/Nicosia (EET/EEST)',
			AsiaNovokuznetsk0700: 'Asia/Novokuznetsk (+07:00)',
			AsiaNovosibirsk0700: 'Asia/Novosibirsk (+07:00)',
			AsiaOmsk0600: 'Asia/Omsk (+06:00)',
			AsiaOral0500: 'Asia/Oral (+05:00)',
			AsiaPontianakWIB: 'Asia/Pontianak (WIB)',
			AsiaPyongyangKST: 'Asia/Pyongyang (KST)',
			AsiaQatar0300: 'Asia/Qatar (+03:00)',
			AsiaQostanay0600: 'Asia/Qostanay (+06:00)',
			AsiaQyzylorda0500: 'Asia/Qyzylorda (+05:00)',
			AsiaRiyadh0300: 'Asia/Riyadh (+03:00)',
			AsiaSakhalin1100: 'Asia/Sakhalin (+11:00)',
			AsiaSamarkand0500: 'Asia/Samarkand (+05:00)',
			AsiaSeoulKST: 'Asia/Seoul (KST)',
			AsiaShanghaiCST: 'Asia/Shanghai (CST)',
			AsiaSingapore0800: 'Asia/Singapore (+08:00)',
			AsiaSrednekolymsk1100: 'Asia/Srednekolymsk (+11:00)',
			AsiaTaipeiCST: 'Asia/Taipei (CST)',
			AsiaTashkent0500: 'Asia/Tashkent (+05:00)',
			AsiaTbilisi0400: 'Asia/Tbilisi (+04:00)',
			AsiaTehran0330: 'Asia/Tehran (+03:30)',
			AsiaThimphu0600: 'Asia/Thimphu (+06:00)',
			AsiaTokyoJST: 'Asia/Tokyo (JST)',
			AsiaTomsk0700: 'Asia/Tomsk (+07:00)',
			AsiaUlaanbaatar0800: 'Asia/Ulaanbaatar (+08:00)',
			AsiaUrumqi0600: 'Asia/Urumqi (+06:00)',
			AsiaUstNera1000: 'Asia/Ust-Nera (+10:00)',
			AsiaVladivostok1000: 'Asia/Vladivostok (+10:00)',
			AsiaYakutsk0900: 'Asia/Yakutsk (+09:00)',
			AsiaYangon0630: 'Asia/Yangon (+06:30)',
			AsiaYekaterinburg0500: 'Asia/Yekaterinburg (+05:00)',
			AsiaYerevan0400: 'Asia/Yerevan (+04:00)',
			AtlanticAzores01000000: 'Atlantic/Azores (-01:00/+00:00)',
			AtlanticBermudaASTADT: 'Atlantic/Bermuda (AST/ADT)',
			AtlanticCanaryWETWEST: 'Atlantic/Canary (WET/WEST)',
			AtlanticCape_Verde0100: 'Atlantic/Cape_Verde (-01:00)',
			AtlanticFaroeWETWEST: 'Atlantic/Faroe (WET/WEST)',
			AtlanticMadeiraWETWEST: 'Atlantic/Madeira (WET/WEST)',
			AtlanticSouth_Georgia0200: 'Atlantic/South_Georgia (-02:00)',
			AtlanticStanley0300: 'Atlantic/Stanley (-03:00)',
			AustraliaAdelaideACSTACDT: 'Australia/Adelaide (ACST/ACDT)',
			AustraliaBrisbaneAEST: 'Australia/Brisbane (AEST)',
			AustraliaBroken_HillACSTACDT: 'Australia/Broken_Hill (ACST/ACDT)',
			AustraliaDarwinACST: 'Australia/Darwin (ACST)',
			AustraliaEucla0845: 'Australia/Eucla (+08:45)',
			AustraliaHobartAESTAEDT: 'Australia/Hobart (AEST/AEDT)',
			AustraliaLindemanAEST: 'Australia/Lindeman (AEST)',
			AustraliaLord_Howe10301100: 'Australia/Lord_Howe (+10:30/+11:00)',
			AustraliaMelbourneAESTAEDT: 'Australia/Melbourne (AEST/AEDT)',
			AustraliaPerthAWST: 'Australia/Perth (AWST)',
			AustraliaSydneyAESTAEDT: 'Australia/Sydney (AEST/AEDT)',
			EuropeAndorraCETCEST: 'Europe/Andorra (CET/CEST)',
			EuropeAstrakhan0400: 'Europe/Astrakhan (+04:00)',
			EuropeAthensEETEEST: 'Europe/Athens (EET/EEST)',
			EuropeBelgradeCETCEST: 'Europe/Belgrade (CET/CEST)',
			EuropeBerlinCETCEST: 'Europe/Berlin (CET/CEST)',
			EuropeBrusselsCETCEST: 'Europe/Brussels (CET/CEST)',
			EuropeBucharestEETEEST: 'Europe/Bucharest (EET/EEST)',
			EuropeBudapestCETCEST: 'Europe/Budapest (CET/CEST)',
			EuropeChisinauEETEEST: 'Europe/Chisinau (EET/EEST)',
			EuropeDublinISTGMT: 'Europe/Dublin (IST/GMT)',
			EuropeGibraltarCETCEST: 'Europe/Gibraltar (CET/CEST)',
			EuropeHelsinkiEETEEST: 'Europe/Helsinki (EET/EEST)',
			EuropeIstanbul0300: 'Europe/Istanbul (+03:00)',
			EuropeKaliningradEET: 'Europe/Kaliningrad (EET)',
			EuropeKirov0300: 'Europe/Kirov (+03:00)',
			EuropeKyivEETEEST: 'Europe/Kyiv (EET/EEST)',
			EuropeLisbonWETWEST: 'Europe/Lisbon (WET/WEST)',
			EuropeLondonGMTBST: 'Europe/London (GMT/BST)',
			EuropeMadridCETCEST: 'Europe/Madrid (CET/CEST)',
			EuropeMaltaCETCEST: 'Europe/Malta (CET/CEST)',
			EuropeMinsk0300: 'Europe/Minsk (+03:00)',
			EuropeMoscowMSK: 'Europe/Moscow (MSK)',
			EuropeParisCETCEST: 'Europe/Paris (CET/CEST)',
			EuropePragueCETCEST: 'Europe/Prague (CET/CEST)',
			EuropeRigaEETEEST: 'Europe/Riga (EET/EEST)',
			EuropeRomeCETCEST: 'Europe/Rome (CET/CEST)',
			EuropeSamara0400: 'Europe/Samara (+04:00)',
			EuropeSaratov0400: 'Europe/Saratov (+04:00)',
			EuropeSimferopolMSK: 'Europe/Simferopol (MSK)',
			EuropeSofiaEETEEST: 'Europe/Sofia (EET/EEST)',
			EuropeTallinnEETEEST: 'Europe/Tallinn (EET/EEST)',
			EuropeTiraneCETCEST: 'Europe/Tirane (CET/CEST)',
			EuropeUlyanovsk0400: 'Europe/Ulyanovsk (+04:00)',
			EuropeViennaCETCEST: 'Europe/Vienna (CET/CEST)',
			EuropeVilniusEETEEST: 'Europe/Vilnius (EET/EEST)',
			EuropeVolgograd0300: 'Europe/Volgograd (+03:00)',
			EuropeWarsawCETCEST: 'Europe/Warsaw (CET/CEST)',
			EuropeZurichCETCEST: 'Europe/Zurich (CET/CEST)',
			IndianChagos0600: 'Indian/Chagos (+06:00)',
			IndianMaldives0500: 'Indian/Maldives (+05:00)',
			IndianMauritius0400: 'Indian/Mauritius (+04:00)',
			PacificApia1300: 'Pacific/Apia (+13:00)',
			PacificAucklandNZSTNZDT: 'Pacific/Auckland (NZST/NZDT)',
			PacificBougainville1100: 'Pacific/Bougainville (+11:00)',
			PacificChatham12451345: 'Pacific/Chatham (+12:45/+13:45)',
			PacificEaster06000500: 'Pacific/Easter (-06:00/-05:00)',
			PacificEfate1100: 'Pacific/Efate (+11:00)',
			PacificFakaofo1300: 'Pacific/Fakaofo (+13:00)',
			PacificFiji1200: 'Pacific/Fiji (+12:00)',
			PacificGalapagos0600: 'Pacific/Galapagos (-06:00)',
			PacificGambier0900: 'Pacific/Gambier (-09:00)',
			PacificGuadalcanal1100: 'Pacific/Guadalcanal (+11:00)',
			PacificGuamChST: 'Pacific/Guam (ChST)',
			PacificHonoluluHST: 'Pacific/Honolulu (HST)',
			PacificKanton1300: 'Pacific/Kanton (+13:00)',
			PacificKiritimati1400: 'Pacific/Kiritimati (+14:00)',
			PacificKosrae1100: 'Pacific/Kosrae (+11:00)',
			PacificKwajalein1200: 'Pacific/Kwajalein (+12:00)',
			PacificMarquesas0930: 'Pacific/Marquesas (-09:30)',
			PacificNauru1200: 'Pacific/Nauru (+12:00)',
			PacificNiue1100: 'Pacific/Niue (-11:00)',
			PacificNorfolk11001200: 'Pacific/Norfolk (+11:00/+12:00)',
			PacificNoumea1100: 'Pacific/Noumea (+11:00)',
			PacificPago_PagoSST: 'Pacific/Pago_Pago (SST)',
			PacificPalau0900: 'Pacific/Palau (+09:00)',
			PacificPitcairn0800: 'Pacific/Pitcairn (-08:00)',
			PacificPort_Moresby1000: 'Pacific/Port_Moresby (+10:00)',
			PacificRarotonga1000: 'Pacific/Rarotonga (-10:00)',
			PacificTahiti1000: 'Pacific/Tahiti (-10:00)',
			PacificTarawa1200: 'Pacific/Tarawa (+12:00)',
			PacificTongatapu1300: 'Pacific/Tongatapu (+13:00)',
			UTCGMT: 'UTC (GMT)',
		},

		Values: [
			new LookupValue({ CodeValue: 'Africa/Abidjan', NameValue: 'Africa/Abidjan (GMT)' }),
			new LookupValue({ CodeValue: 'Africa/Algiers', NameValue: 'Africa/Algiers (CET)' }),
			new LookupValue({ CodeValue: 'Africa/Bissau', NameValue: 'Africa/Bissau (GMT)' }),
			new LookupValue({ CodeValue: 'Africa/Cairo', NameValue: 'Africa/Cairo (EET)' }),
			new LookupValue({ CodeValue: 'Africa/Casablanca', NameValue: 'Africa/Casablanca (+01:00/+00:00)' }),
			new LookupValue({ CodeValue: 'Africa/Ceuta', NameValue: 'Africa/Ceuta (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Africa/El_Aaiun', NameValue: 'Africa/El_Aaiun (+01:00/+00:00)' }),
			new LookupValue({ CodeValue: 'Africa/Johannesburg', NameValue: 'Africa/Johannesburg (SAST)' }),
			new LookupValue({ CodeValue: 'Africa/Juba', NameValue: 'Africa/Juba (CAT)' }),
			new LookupValue({ CodeValue: 'Africa/Khartoum', NameValue: 'Africa/Khartoum (CAT)' }),
			new LookupValue({ CodeValue: 'Africa/Lagos', NameValue: 'Africa/Lagos (WAT)' }),
			new LookupValue({ CodeValue: 'Africa/Maputo', NameValue: 'Africa/Maputo (CAT)' }),
			new LookupValue({ CodeValue: 'Africa/Monrovia', NameValue: 'Africa/Monrovia (GMT)' }),
			new LookupValue({ CodeValue: 'Africa/Nairobi', NameValue: 'Africa/Nairobi (EAT)' }),
			new LookupValue({ CodeValue: 'Africa/Ndjamena', NameValue: 'Africa/Ndjamena (WAT)' }),
			new LookupValue({ CodeValue: 'Africa/Sao_Tome', NameValue: 'Africa/Sao_Tome (GMT)' }),
			new LookupValue({ CodeValue: 'Africa/Tripoli', NameValue: 'Africa/Tripoli (EET)' }),
			new LookupValue({ CodeValue: 'Africa/Tunis', NameValue: 'Africa/Tunis (CET)' }),
			new LookupValue({ CodeValue: 'Africa/Windhoek', NameValue: 'Africa/Windhoek (CAT)' }),
			new LookupValue({ CodeValue: 'America/Adak', NameValue: 'America/Adak (HST/HDT)' }),
			new LookupValue({ CodeValue: 'America/Anchorage', NameValue: 'America/Anchorage (AKST/AKDT)' }),
			new LookupValue({ CodeValue: 'America/Araguaina', NameValue: 'America/Araguaina (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/Buenos_Aires', NameValue: 'America/Argentina/Buenos_Aires (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/Catamarca', NameValue: 'America/Argentina/Catamarca (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/Cordoba', NameValue: 'America/Argentina/Cordoba (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/Jujuy', NameValue: 'America/Argentina/Jujuy (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/La_Rioja', NameValue: 'America/Argentina/La_Rioja (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/Mendoza', NameValue: 'America/Argentina/Mendoza (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/Rio_Gallegos', NameValue: 'America/Argentina/Rio_Gallegos (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/Salta', NameValue: 'America/Argentina/Salta (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/San_Juan', NameValue: 'America/Argentina/San_Juan (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/San_Luis', NameValue: 'America/Argentina/San_Luis (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/Tucuman', NameValue: 'America/Argentina/Tucuman (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Argentina/Ushuaia', NameValue: 'America/Argentina/Ushuaia (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Asuncion', NameValue: 'America/Asuncion (-04:00/-03:00)' }),
			new LookupValue({ CodeValue: 'America/Bahia', NameValue: 'America/Bahia (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Bahia_Banderas', NameValue: 'America/Bahia_Banderas (CST)' }),
			new LookupValue({ CodeValue: 'America/Barbados', NameValue: 'America/Barbados (AST)' }),
			new LookupValue({ CodeValue: 'America/Belem', NameValue: 'America/Belem (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Belize', NameValue: 'America/Belize (CST)' }),
			new LookupValue({ CodeValue: 'America/Boa_Vista', NameValue: 'America/Boa_Vista (-04:00)' }),
			new LookupValue({ CodeValue: 'America/Bogota', NameValue: 'America/Bogota (-05:00)' }),
			new LookupValue({ CodeValue: 'America/Boise', NameValue: 'America/Boise (MST/MDT)' }),
			new LookupValue({ CodeValue: 'America/Cambridge_Bay', NameValue: 'America/Cambridge_Bay (MST/MDT)' }),
			new LookupValue({ CodeValue: 'America/Campo_Grande', NameValue: 'America/Campo_Grande (-04:00)' }),
			new LookupValue({ CodeValue: 'America/Cancun', NameValue: 'America/Cancun (EST)' }),
			new LookupValue({ CodeValue: 'America/Caracas', NameValue: 'America/Caracas (-04:00)' }),
			new LookupValue({ CodeValue: 'America/Cayenne', NameValue: 'America/Cayenne (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Chicago', NameValue: 'America/Chicago (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Chihuahua', NameValue: 'America/Chihuahua (CST)' }),
			new LookupValue({ CodeValue: 'America/Ciudad_Juarez', NameValue: 'America/Ciudad_Juarez (MST/MDT)' }),
			new LookupValue({ CodeValue: 'America/Costa_Rica', NameValue: 'America/Costa_Rica (CST)' }),
			new LookupValue({ CodeValue: 'America/Cuiaba', NameValue: 'America/Cuiaba (-04:00)' }),
			new LookupValue({ CodeValue: 'America/Danmarkshavn', NameValue: 'America/Danmarkshavn (GMT)' }),
			new LookupValue({ CodeValue: 'America/Dawson', NameValue: 'America/Dawson (MST)' }),
			new LookupValue({ CodeValue: 'America/Dawson_Creek', NameValue: 'America/Dawson_Creek (MST)' }),
			new LookupValue({ CodeValue: 'America/Denver', NameValue: 'America/Denver (MST/MDT)' }),
			new LookupValue({ CodeValue: 'America/Detroit', NameValue: 'America/Detroit (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Edmonton', NameValue: 'America/Edmonton (MST/MDT)' }),
			new LookupValue({ CodeValue: 'America/Eirunepe', NameValue: 'America/Eirunepe (-05:00)' }),
			new LookupValue({ CodeValue: 'America/El_Salvador', NameValue: 'America/El_Salvador (CST)' }),
			new LookupValue({ CodeValue: 'America/Fort_Nelson', NameValue: 'America/Fort_Nelson (MST)' }),
			new LookupValue({ CodeValue: 'America/Fortaleza', NameValue: 'America/Fortaleza (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Glace_Bay', NameValue: 'America/Glace_Bay (AST/ADT)' }),
			new LookupValue({ CodeValue: 'America/Goose_Bay', NameValue: 'America/Goose_Bay (AST/ADT)' }),
			new LookupValue({ CodeValue: 'America/Grand_Turk', NameValue: 'America/Grand_Turk (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Guatemala', NameValue: 'America/Guatemala (CST)' }),
			new LookupValue({ CodeValue: 'America/Guayaquil', NameValue: 'America/Guayaquil (-05:00)' }),
			new LookupValue({ CodeValue: 'America/Guyana', NameValue: 'America/Guyana (-04:00)' }),
			new LookupValue({ CodeValue: 'America/Halifax', NameValue: 'America/Halifax (AST/ADT)' }),
			new LookupValue({ CodeValue: 'America/Havana', NameValue: 'America/Havana (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Hermosillo', NameValue: 'America/Hermosillo (MST)' }),
			new LookupValue({ CodeValue: 'America/Indiana/Indianapolis', NameValue: 'America/Indiana/Indianapolis (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Indiana/Knox', NameValue: 'America/Indiana/Knox (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Indiana/Marengo', NameValue: 'America/Indiana/Marengo (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Indiana/Petersburg', NameValue: 'America/Indiana/Petersburg (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Indiana/Tell_City', NameValue: 'America/Indiana/Tell_City (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Indiana/Vevay', NameValue: 'America/Indiana/Vevay (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Indiana/Vincennes', NameValue: 'America/Indiana/Vincennes (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Indiana/Winamac', NameValue: 'America/Indiana/Winamac (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Inuvik', NameValue: 'America/Inuvik (MST/MDT)' }),
			new LookupValue({ CodeValue: 'America/Iqaluit', NameValue: 'America/Iqaluit (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Jamaica', NameValue: 'America/Jamaica (EST)' }),
			new LookupValue({ CodeValue: 'America/Juneau', NameValue: 'America/Juneau (AKST/AKDT)' }),
			new LookupValue({ CodeValue: 'America/Kentucky/Louisville', NameValue: 'America/Kentucky/Louisville (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Kentucky/Monticello', NameValue: 'America/Kentucky/Monticello (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/La_Paz', NameValue: 'America/La_Paz (-04:00)' }),
			new LookupValue({ CodeValue: 'America/Lima', NameValue: 'America/Lima (-05:00)' }),
			new LookupValue({ CodeValue: 'America/Los_Angeles', NameValue: 'America/Los_Angeles (PST/PDT)' }),
			new LookupValue({ CodeValue: 'America/Maceio', NameValue: 'America/Maceio (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Managua', NameValue: 'America/Managua (CST)' }),
			new LookupValue({ CodeValue: 'America/Manaus', NameValue: 'America/Manaus (-04:00)' }),
			new LookupValue({ CodeValue: 'America/Martinique', NameValue: 'America/Martinique (AST)' }),
			new LookupValue({ CodeValue: 'America/Matamoros', NameValue: 'America/Matamoros (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Mazatlan', NameValue: 'America/Mazatlan (MST)' }),
			new LookupValue({ CodeValue: 'America/Menominee', NameValue: 'America/Menominee (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Merida', NameValue: 'America/Merida (CST)' }),
			new LookupValue({ CodeValue: 'America/Metlakatla', NameValue: 'America/Metlakatla (AKST/AKDT)' }),
			new LookupValue({ CodeValue: 'America/Mexico_City', NameValue: 'America/Mexico_City (CST)' }),
			new LookupValue({ CodeValue: 'America/Miquelon', NameValue: 'America/Miquelon (-03:00/-02:00)' }),
			new LookupValue({ CodeValue: 'America/Moncton', NameValue: 'America/Moncton (AST/ADT)' }),
			new LookupValue({ CodeValue: 'America/Monterrey', NameValue: 'America/Monterrey (CST)' }),
			new LookupValue({ CodeValue: 'America/Montevideo', NameValue: 'America/Montevideo (-03:00)' }),
			new LookupValue({ CodeValue: 'America/New_York', NameValue: 'America/New_York (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Nome', NameValue: 'America/Nome (AKST/AKDT)' }),
			new LookupValue({ CodeValue: 'America/Noronha', NameValue: 'America/Noronha (-02:00)' }),
			new LookupValue({ CodeValue: 'America/North_Dakota/Beulah', NameValue: 'America/North_Dakota/Beulah (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/North_Dakota/Center', NameValue: 'America/North_Dakota/Center (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/North_Dakota/New_Salem', NameValue: 'America/North_Dakota/New_Salem (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Nuuk', NameValue: 'America/Nuuk (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Ojinaga', NameValue: 'America/Ojinaga (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Panama', NameValue: 'America/Panama (EST)' }),
			new LookupValue({ CodeValue: 'America/Paramaribo', NameValue: 'America/Paramaribo (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Phoenix', NameValue: 'America/Phoenix (MST)' }),
			new LookupValue({ CodeValue: 'America/Port-au-Prince', NameValue: 'America/Port-au-Prince (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Porto_Velho', NameValue: 'America/Porto_Velho (-04:00)' }),
			new LookupValue({ CodeValue: 'America/Puerto_Rico', NameValue: 'America/Puerto_Rico (AST)' }),
			new LookupValue({ CodeValue: 'America/Punta_Arenas', NameValue: 'America/Punta_Arenas (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Rankin_Inlet', NameValue: 'America/Rankin_Inlet (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Recife', NameValue: 'America/Recife (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Regina', NameValue: 'America/Regina (CST)' }),
			new LookupValue({ CodeValue: 'America/Resolute', NameValue: 'America/Resolute (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Rio_Branco', NameValue: 'America/Rio_Branco (-05:00)' }),
			new LookupValue({ CodeValue: 'America/Santarem', NameValue: 'America/Santarem (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Santiago', NameValue: 'America/Santiago (-04:00/-03:00)' }),
			new LookupValue({ CodeValue: 'America/Santo_Domingo', NameValue: 'America/Santo_Domingo (AST)' }),
			new LookupValue({ CodeValue: 'America/Sao_Paulo', NameValue: 'America/Sao_Paulo (-03:00)' }),
			new LookupValue({ CodeValue: 'America/Scoresbysund', NameValue: 'America/Scoresbysund (-01:00/+00:00)' }),
			new LookupValue({ CodeValue: 'America/Sitka', NameValue: 'America/Sitka (AKST/AKDT)' }),
			new LookupValue({ CodeValue: 'America/St_Johns', NameValue: 'America/St_Johns (NST/NDT)' }),
			new LookupValue({ CodeValue: 'America/Swift_Current', NameValue: 'America/Swift_Current (CST)' }),
			new LookupValue({ CodeValue: 'America/Tegucigalpa', NameValue: 'America/Tegucigalpa (CST)' }),
			new LookupValue({ CodeValue: 'America/Thule', NameValue: 'America/Thule (AST/ADT)' }),
			new LookupValue({ CodeValue: 'America/Tijuana', NameValue: 'America/Tijuana (PST/PDT)' }),
			new LookupValue({ CodeValue: 'America/Toronto', NameValue: 'America/Toronto (EST/EDT)' }),
			new LookupValue({ CodeValue: 'America/Vancouver', NameValue: 'America/Vancouver (PST/PDT)' }),
			new LookupValue({ CodeValue: 'America/Whitehorse', NameValue: 'America/Whitehorse (MST)' }),
			new LookupValue({ CodeValue: 'America/Winnipeg', NameValue: 'America/Winnipeg (CST/CDT)' }),
			new LookupValue({ CodeValue: 'America/Yakutat', NameValue: 'America/Yakutat (AKST/AKDT)' }),
			new LookupValue({ CodeValue: 'America/Yellowknife', NameValue: 'America/Yellowknife (MST/MDT)' }),
			new LookupValue({ CodeValue: 'Antarctica/Casey', NameValue: 'Antarctica/Casey (+11:00)' }),
			new LookupValue({ CodeValue: 'Antarctica/Davis', NameValue: 'Antarctica/Davis (+07:00)' }),
			new LookupValue({ CodeValue: 'Antarctica/Macquarie', NameValue: 'Antarctica/Macquarie (AEST/AEDT)' }),
			new LookupValue({ CodeValue: 'Antarctica/Mawson', NameValue: 'Antarctica/Mawson (+05:00)' }),
			new LookupValue({ CodeValue: 'Antarctica/Palmer', NameValue: 'Antarctica/Palmer (-03:00)' }),
			new LookupValue({ CodeValue: 'Antarctica/Rothera', NameValue: 'Antarctica/Rothera (-03:00)' }),
			new LookupValue({ CodeValue: 'Antarctica/Troll', NameValue: 'Antarctica/Troll (+00:00/+02:00)' }),
			new LookupValue({ CodeValue: 'Asia/Almaty', NameValue: 'Asia/Almaty (+06:00)' }),
			new LookupValue({ CodeValue: 'Asia/Amman', NameValue: 'Asia/Amman (+03:00)' }),
			new LookupValue({ CodeValue: 'Asia/Anadyr', NameValue: 'Asia/Anadyr (+12:00)' }),
			new LookupValue({ CodeValue: 'Asia/Aqtau', NameValue: 'Asia/Aqtau (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Aqtobe', NameValue: 'Asia/Aqtobe (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Ashgabat', NameValue: 'Asia/Ashgabat (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Atyrau', NameValue: 'Asia/Atyrau (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Baghdad', NameValue: 'Asia/Baghdad (+03:00)' }),
			new LookupValue({ CodeValue: 'Asia/Baku', NameValue: 'Asia/Baku (+04:00)' }),
			new LookupValue({ CodeValue: 'Asia/Bangkok', NameValue: 'Asia/Bangkok (+07:00)' }),
			new LookupValue({ CodeValue: 'Asia/Barnaul', NameValue: 'Asia/Barnaul (+07:00)' }),
			new LookupValue({ CodeValue: 'Asia/Beirut', NameValue: 'Asia/Beirut (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Asia/Bishkek', NameValue: 'Asia/Bishkek (+06:00)' }),
			new LookupValue({ CodeValue: 'Asia/Chita', NameValue: 'Asia/Chita (+09:00)' }),
			new LookupValue({ CodeValue: 'Asia/Choibalsan', NameValue: 'Asia/Choibalsan (+08:00)' }),
			new LookupValue({ CodeValue: 'Asia/Colombo', NameValue: 'Asia/Colombo (+05:30)' }),
			new LookupValue({ CodeValue: 'Asia/Damascus', NameValue: 'Asia/Damascus (+03:00)' }),
			new LookupValue({ CodeValue: 'Asia/Dhaka', NameValue: 'Asia/Dhaka (+06:00)' }),
			new LookupValue({ CodeValue: 'Asia/Dili', NameValue: 'Asia/Dili (+09:00)' }),
			new LookupValue({ CodeValue: 'Asia/Dubai', NameValue: 'Asia/Dubai (+04:00)' }),
			new LookupValue({ CodeValue: 'Asia/Dushanbe', NameValue: 'Asia/Dushanbe (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Famagusta', NameValue: 'Asia/Famagusta (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Asia/Gaza', NameValue: 'Asia/Gaza (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Asia/Hebron', NameValue: 'Asia/Hebron (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Asia/Ho_Chi_Minh', NameValue: 'Asia/Ho_Chi_Minh (+07:00)' }),
			new LookupValue({ CodeValue: 'Asia/Hong_Kong', NameValue: 'Asia/Hong_Kong (HKT)' }),
			new LookupValue({ CodeValue: 'Asia/Hovd', NameValue: 'Asia/Hovd (+07:00)' }),
			new LookupValue({ CodeValue: 'Asia/Irkutsk', NameValue: 'Asia/Irkutsk (+08:00)' }),
			new LookupValue({ CodeValue: 'Asia/Jakarta', NameValue: 'Asia/Jakarta (WIB)' }),
			new LookupValue({ CodeValue: 'Asia/Jayapura', NameValue: 'Asia/Jayapura (WIT)' }),
			new LookupValue({ CodeValue: 'Asia/Jerusalem', NameValue: 'Asia/Jerusalem (IST/IDT)' }),
			new LookupValue({ CodeValue: 'Asia/Kabul', NameValue: 'Asia/Kabul (+04:30)' }),
			new LookupValue({ CodeValue: 'Asia/Kamchatka', NameValue: 'Asia/Kamchatka (+12:00)' }),
			new LookupValue({ CodeValue: 'Asia/Karachi', NameValue: 'Asia/Karachi (PKT)' }),
			new LookupValue({ CodeValue: 'Asia/Kathmandu', NameValue: 'Asia/Kathmandu (+05:45)' }),
			new LookupValue({ CodeValue: 'Asia/Khandyga', NameValue: 'Asia/Khandyga (+09:00)' }),
			new LookupValue({ CodeValue: 'Asia/Kolkata', NameValue: 'Asia/Kolkata (IST)' }),
			new LookupValue({ CodeValue: 'Asia/Krasnoyarsk', NameValue: 'Asia/Krasnoyarsk (+07:00)' }),
			new LookupValue({ CodeValue: 'Asia/Kuching', NameValue: 'Asia/Kuching (+08:00)' }),
			new LookupValue({ CodeValue: 'Asia/Macau', NameValue: 'Asia/Macau (CST)' }),
			new LookupValue({ CodeValue: 'Asia/Magadan', NameValue: 'Asia/Magadan (+11:00)' }),
			new LookupValue({ CodeValue: 'Asia/Makassar', NameValue: 'Asia/Makassar (WITA)' }),
			new LookupValue({ CodeValue: 'Asia/Manila', NameValue: 'Asia/Manila (PST)' }),
			new LookupValue({ CodeValue: 'Asia/Nicosia', NameValue: 'Asia/Nicosia (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Asia/Novokuznetsk', NameValue: 'Asia/Novokuznetsk (+07:00)' }),
			new LookupValue({ CodeValue: 'Asia/Novosibirsk', NameValue: 'Asia/Novosibirsk (+07:00)' }),
			new LookupValue({ CodeValue: 'Asia/Omsk', NameValue: 'Asia/Omsk (+06:00)' }),
			new LookupValue({ CodeValue: 'Asia/Oral', NameValue: 'Asia/Oral (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Pontianak', NameValue: 'Asia/Pontianak (WIB)' }),
			new LookupValue({ CodeValue: 'Asia/Pyongyang', NameValue: 'Asia/Pyongyang (KST)' }),
			new LookupValue({ CodeValue: 'Asia/Qatar', NameValue: 'Asia/Qatar (+03:00)' }),
			new LookupValue({ CodeValue: 'Asia/Qostanay', NameValue: 'Asia/Qostanay (+06:00)' }),
			new LookupValue({ CodeValue: 'Asia/Qyzylorda', NameValue: 'Asia/Qyzylorda (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Riyadh', NameValue: 'Asia/Riyadh (+03:00)' }),
			new LookupValue({ CodeValue: 'Asia/Sakhalin', NameValue: 'Asia/Sakhalin (+11:00)' }),
			new LookupValue({ CodeValue: 'Asia/Samarkand', NameValue: 'Asia/Samarkand (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Seoul', NameValue: 'Asia/Seoul (KST)' }),
			new LookupValue({ CodeValue: 'Asia/Shanghai', NameValue: 'Asia/Shanghai (CST)' }),
			new LookupValue({ CodeValue: 'Asia/Singapore', NameValue: 'Asia/Singapore (+08:00)' }),
			new LookupValue({ CodeValue: 'Asia/Srednekolymsk', NameValue: 'Asia/Srednekolymsk (+11:00)' }),
			new LookupValue({ CodeValue: 'Asia/Taipei', NameValue: 'Asia/Taipei (CST)' }),
			new LookupValue({ CodeValue: 'Asia/Tashkent', NameValue: 'Asia/Tashkent (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Tbilisi', NameValue: 'Asia/Tbilisi (+04:00)' }),
			new LookupValue({ CodeValue: 'Asia/Tehran', NameValue: 'Asia/Tehran (+03:30)' }),
			new LookupValue({ CodeValue: 'Asia/Thimphu', NameValue: 'Asia/Thimphu (+06:00)' }),
			new LookupValue({ CodeValue: 'Asia/Tokyo', NameValue: 'Asia/Tokyo (JST)' }),
			new LookupValue({ CodeValue: 'Asia/Tomsk', NameValue: 'Asia/Tomsk (+07:00)' }),
			new LookupValue({ CodeValue: 'Asia/Ulaanbaatar', NameValue: 'Asia/Ulaanbaatar (+08:00)' }),
			new LookupValue({ CodeValue: 'Asia/Urumqi', NameValue: 'Asia/Urumqi (+06:00)' }),
			new LookupValue({ CodeValue: 'Asia/Ust-Nera', NameValue: 'Asia/Ust-Nera (+10:00)' }),
			new LookupValue({ CodeValue: 'Asia/Vladivostok', NameValue: 'Asia/Vladivostok (+10:00)' }),
			new LookupValue({ CodeValue: 'Asia/Yakutsk', NameValue: 'Asia/Yakutsk (+09:00)' }),
			new LookupValue({ CodeValue: 'Asia/Yangon', NameValue: 'Asia/Yangon (+06:30)' }),
			new LookupValue({ CodeValue: 'Asia/Yekaterinburg', NameValue: 'Asia/Yekaterinburg (+05:00)' }),
			new LookupValue({ CodeValue: 'Asia/Yerevan', NameValue: 'Asia/Yerevan (+04:00)' }),
			new LookupValue({ CodeValue: 'Atlantic/Azores', NameValue: 'Atlantic/Azores (-01:00/+00:00)' }),
			new LookupValue({ CodeValue: 'Atlantic/Bermuda', NameValue: 'Atlantic/Bermuda (AST/ADT)' }),
			new LookupValue({ CodeValue: 'Atlantic/Canary', NameValue: 'Atlantic/Canary (WET/WEST)' }),
			new LookupValue({ CodeValue: 'Atlantic/Cape_Verde', NameValue: 'Atlantic/Cape_Verde (-01:00)' }),
			new LookupValue({ CodeValue: 'Atlantic/Faroe', NameValue: 'Atlantic/Faroe (WET/WEST)' }),
			new LookupValue({ CodeValue: 'Atlantic/Madeira', NameValue: 'Atlantic/Madeira (WET/WEST)' }),
			new LookupValue({ CodeValue: 'Atlantic/South_Georgia', NameValue: 'Atlantic/South_Georgia (-02:00)' }),
			new LookupValue({ CodeValue: 'Atlantic/Stanley', NameValue: 'Atlantic/Stanley (-03:00)' }),
			new LookupValue({ CodeValue: 'Australia/Adelaide', NameValue: 'Australia/Adelaide (ACST/ACDT)' }),
			new LookupValue({ CodeValue: 'Australia/Brisbane', NameValue: 'Australia/Brisbane (AEST)' }),
			new LookupValue({ CodeValue: 'Australia/Broken_Hill', NameValue: 'Australia/Broken_Hill (ACST/ACDT)' }),
			new LookupValue({ CodeValue: 'Australia/Darwin', NameValue: 'Australia/Darwin (ACST)' }),
			new LookupValue({ CodeValue: 'Australia/Eucla', NameValue: 'Australia/Eucla (+08:45)' }),
			new LookupValue({ CodeValue: 'Australia/Hobart', NameValue: 'Australia/Hobart (AEST/AEDT)' }),
			new LookupValue({ CodeValue: 'Australia/Lindeman', NameValue: 'Australia/Lindeman (AEST)' }),
			new LookupValue({ CodeValue: 'Australia/Lord_Howe', NameValue: 'Australia/Lord_Howe (+10:30/+11:00)' }),
			new LookupValue({ CodeValue: 'Australia/Melbourne', NameValue: 'Australia/Melbourne (AEST/AEDT)' }),
			new LookupValue({ CodeValue: 'Australia/Perth', NameValue: 'Australia/Perth (AWST)' }),
			new LookupValue({ CodeValue: 'Australia/Sydney', NameValue: 'Australia/Sydney (AEST/AEDT)' }),
			new LookupValue({ CodeValue: 'Europe/Andorra', NameValue: 'Europe/Andorra (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Astrakhan', NameValue: 'Europe/Astrakhan (+04:00)' }),
			new LookupValue({ CodeValue: 'Europe/Athens', NameValue: 'Europe/Athens (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Europe/Belgrade', NameValue: 'Europe/Belgrade (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Berlin', NameValue: 'Europe/Berlin (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Brussels', NameValue: 'Europe/Brussels (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Bucharest', NameValue: 'Europe/Bucharest (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Europe/Budapest', NameValue: 'Europe/Budapest (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Chisinau', NameValue: 'Europe/Chisinau (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Europe/Dublin', NameValue: 'Europe/Dublin (IST/GMT)' }),
			new LookupValue({ CodeValue: 'Europe/Gibraltar', NameValue: 'Europe/Gibraltar (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Helsinki', NameValue: 'Europe/Helsinki (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Europe/Istanbul', NameValue: 'Europe/Istanbul (+03:00)' }),
			new LookupValue({ CodeValue: 'Europe/Kaliningrad', NameValue: 'Europe/Kaliningrad (EET)' }),
			new LookupValue({ CodeValue: 'Europe/Kirov', NameValue: 'Europe/Kirov (+03:00)' }),
			new LookupValue({ CodeValue: 'Europe/Kyiv', NameValue: 'Europe/Kyiv (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Europe/Lisbon', NameValue: 'Europe/Lisbon (WET/WEST)' }),
			new LookupValue({ CodeValue: 'Europe/London', NameValue: 'Europe/London (GMT/BST)' }),
			new LookupValue({ CodeValue: 'Europe/Madrid', NameValue: 'Europe/Madrid (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Malta', NameValue: 'Europe/Malta (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Minsk', NameValue: 'Europe/Minsk (+03:00)' }),
			new LookupValue({ CodeValue: 'Europe/Moscow', NameValue: 'Europe/Moscow (MSK)' }),
			new LookupValue({ CodeValue: 'Europe/Paris', NameValue: 'Europe/Paris (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Prague', NameValue: 'Europe/Prague (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Riga', NameValue: 'Europe/Riga (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Europe/Rome', NameValue: 'Europe/Rome (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Samara', NameValue: 'Europe/Samara (+04:00)' }),
			new LookupValue({ CodeValue: 'Europe/Saratov', NameValue: 'Europe/Saratov (+04:00)' }),
			new LookupValue({ CodeValue: 'Europe/Simferopol', NameValue: 'Europe/Simferopol (MSK)' }),
			new LookupValue({ CodeValue: 'Europe/Sofia', NameValue: 'Europe/Sofia (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Europe/Tallinn', NameValue: 'Europe/Tallinn (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Europe/Tirane', NameValue: 'Europe/Tirane (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Ulyanovsk', NameValue: 'Europe/Ulyanovsk (+04:00)' }),
			new LookupValue({ CodeValue: 'Europe/Vienna', NameValue: 'Europe/Vienna (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Vilnius', NameValue: 'Europe/Vilnius (EET/EEST)' }),
			new LookupValue({ CodeValue: 'Europe/Volgograd', NameValue: 'Europe/Volgograd (+03:00)' }),
			new LookupValue({ CodeValue: 'Europe/Warsaw', NameValue: 'Europe/Warsaw (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Europe/Zurich', NameValue: 'Europe/Zurich (CET/CEST)' }),
			new LookupValue({ CodeValue: 'Indian/Chagos', NameValue: 'Indian/Chagos (+06:00)' }),
			new LookupValue({ CodeValue: 'Indian/Maldives', NameValue: 'Indian/Maldives (+05:00)' }),
			new LookupValue({ CodeValue: 'Indian/Mauritius', NameValue: 'Indian/Mauritius (+04:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Apia', NameValue: 'Pacific/Apia (+13:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Auckland', NameValue: 'Pacific/Auckland (NZST/NZDT)' }),
			new LookupValue({ CodeValue: 'Pacific/Bougainville', NameValue: 'Pacific/Bougainville (+11:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Chatham', NameValue: 'Pacific/Chatham (+12:45/+13:45)' }),
			new LookupValue({ CodeValue: 'Pacific/Easter', NameValue: 'Pacific/Easter (-06:00/-05:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Efate', NameValue: 'Pacific/Efate (+11:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Fakaofo', NameValue: 'Pacific/Fakaofo (+13:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Fiji', NameValue: 'Pacific/Fiji (+12:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Galapagos', NameValue: 'Pacific/Galapagos (-06:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Gambier', NameValue: 'Pacific/Gambier (-09:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Guadalcanal', NameValue: 'Pacific/Guadalcanal (+11:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Guam', NameValue: 'Pacific/Guam (ChST)' }),
			new LookupValue({ CodeValue: 'Pacific/Honolulu', NameValue: 'Pacific/Honolulu (HST)' }),
			new LookupValue({ CodeValue: 'Pacific/Kanton', NameValue: 'Pacific/Kanton (+13:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Kiritimati', NameValue: 'Pacific/Kiritimati (+14:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Kosrae', NameValue: 'Pacific/Kosrae (+11:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Kwajalein', NameValue: 'Pacific/Kwajalein (+12:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Marquesas', NameValue: 'Pacific/Marquesas (-09:30)' }),
			new LookupValue({ CodeValue: 'Pacific/Nauru', NameValue: 'Pacific/Nauru (+12:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Niue', NameValue: 'Pacific/Niue (-11:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Norfolk', NameValue: 'Pacific/Norfolk (+11:00/+12:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Noumea', NameValue: 'Pacific/Noumea (+11:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Pago_Pago', NameValue: 'Pacific/Pago_Pago (SST)' }),
			new LookupValue({ CodeValue: 'Pacific/Palau', NameValue: 'Pacific/Palau (+09:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Pitcairn', NameValue: 'Pacific/Pitcairn (-08:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Port_Moresby', NameValue: 'Pacific/Port_Moresby (+10:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Rarotonga', NameValue: 'Pacific/Rarotonga (-10:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Tahiti', NameValue: 'Pacific/Tahiti (-10:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Tarawa', NameValue: 'Pacific/Tarawa (+12:00)' }),
			new LookupValue({ CodeValue: 'Pacific/Tongatapu', NameValue: 'Pacific/Tongatapu (+13:00)' }),
			new LookupValue({ CodeValue: 'Etc/GMT', NameValue: 'UTC (GMT)' }),
		],
	};

	public static UserPermission = {
		AreaExport: 'AREA_EXPORT',
		AreaUpdate: 'AREA_UPDATE',
		AreaView: 'AREA_VIEW',
		AssetExport: 'ASSET_EXPORT',
		AssetUpdate: 'ASSET_UPDATE',
		AssetView: 'ASSET_VIEW',
		BusinessEntityExport: 'BUSINESSENTITY_EXPORT',
		BusinessEntityUpdate: 'BUSINESSENTITY_UPDATE',
		BusinessEntityView: 'BUSINESSENTITY_VIEW',
		CalendarExport: 'CALENDAR_EXPORT',
		CalendarUpdate: 'CALENDAR_UPDATE',
		CalendarView: 'CALENDAR_VIEW',
		ClientExport: 'CLIENT_EXPORT',
		ClientUpdate: 'CLIENT_UPDATE',
		ClientView: 'CLIENT_VIEW',
		CommonActivityView: 'COMMON_ACTIVITY_VIEW',
		CommonAttachmentsCreate: 'COMMON_ATTACH_CREATE',
		CommonAttachmentsEdit: 'COMMON_ATTACH_EDIT',
		CommonAttachmentsRemove: 'COMMON_ATTACH_REMOVE',
		CommonContactsRemove: 'COMMON_CONTACTS_REMOVE',
		CommonContactsUpdate: 'COMMON_CONTACTS_UPDATE',
		CommonFinancialSettingsEdit: 'COMMON_FINANCE_EDIT',
		CommonMapsCreate: 'COMMON_MAPS_CREATE',
		CommonMapsEdit: 'COMMON_MAPS_EDIT',
		CommonMapsRemove: 'COMMON_MAPS_REMOVE',
		CommonNotesCreate: 'COMMON_NOTES_CREATE',
		CommonNotesEdit: 'COMMON_NOTES_EDIT',
		CommonNotesRemove: 'COMMON_NOTES_REMOVE',
		CommonRateGroupEdit: 'COMMON_RATEGROUP_EDIT',
		CommonTaxCodesEdit: 'COMMON_TAX_EDIT',
		CommonViewPrices: 'COMMON_FINANCE_PRICES',
		CompanyExport: 'COMPANY_EXPORT',
		CompanyUpdate: 'COMPANY_UPDATE',
		CompanyView: 'COMPANY_VIEW',
		DashboardContractorStatistics: 'DASHBOARD_CONTRACTORSTATS',
		DashboardInterfaceEvents: 'DASHBOARD_INTERFACE',
		DashboardLicenceInsuranceExpiry: 'DASHBOARD_LICINSURANCE',
		DashboardMap: 'DASHBOARD_MAP',
		DashboardOpenServiceRequests: 'DASHBOARD_SRQ',
		DashboardPOBreakdown: 'DASHBOARD_POBREAKDOWN',
		DashboardPOVolumebyPriority: 'DASHBOARD_POVOLUME',
		DashboardReadyforAction: 'DASHBOARD_RFA',
		DashboardScorecard: 'DASHBOARD_SCORECARD',
		DashboardSpendTrend: 'DASHBOARD_SPENDTREND',
		DashboardTimeKPI: 'DASHBOARD_TIMEKPI',
		DashboardUnpaidInvoices: 'DASHBOARD_INVOICE',
		DashboardView: 'DASHBOARD_VIEW',
		DashboardWorkTypeAnalysis: 'DASHBOARD_WORKTYPE',
		DomainValueExport: 'DOMAINVALUE_EXPORT',
		DomainValueUpdate: 'DOMAINVALUE_UPDATE',
		DomainValueView: 'DOMAINVALUE_VIEW',
		IntegrationModuleUpdate: 'INTMODULE_UPDATE',
		IntegrationModuleView: 'INTMODULE_VIEW',
		InterfaceExport: 'INTERFACE_EXPORT',
		InterfaceUpdate: 'INTERFACE_UPDATE',
		InterfaceView: 'INTERFACE_VIEW',
		InvoiceCreate: 'INVOICE_CREATE',
		InvoiceEdit: 'INVOICE_EDIT',
		InvoiceExport: 'INVOICE_EXPORT',
		InvoiceView: 'INVOICE_VIEW',
		OrderCreate: 'ORDER_CREATE',
		OrderEdit: 'ORDER_EDIT',
		OrderExport: 'ORDER_EXPORT',
		OrderPriceOverrides: 'ORDER_PRICE_OVERRIDE',
		OrderReview: 'ORDER_REVIEW',
		OrderView: 'ORDER_VIEW',
		ProjectExport: 'PROJECT_EXPORT',
		ProjectUpdate: 'PROJECT_UPDATE',
		ProjectView: 'PROJECT_VIEW',
		QuoteCreate: 'QUOTE_CREATE',
		QuoteEdit: 'QUOTE_EDIT',
		QuoteExport: 'QUOTE_EXPORT',
		QuoteView: 'QUOTE_VIEW',
		ReportView: 'REPORT_VIEW',
		ScopeCreate: 'SCOPE_CREATE',
		ScopeEdit: 'SCOPE_EDIT',
		ScopeExport: 'SCOPE_EXPORT',
		ScopeReview: 'SCOPE_REVIEW',
		ScopeView: 'SCOPE_VIEW',
		ServiceRequestExport: 'SRQ_EXPORT',
		ServiceRequestUpdate: 'SRQ_UPDATE',
		ServiceRequestUpdateBasic: 'SRQ_UPDATE_BASIC',
		ServiceRequestView: 'SRQ_VIEW',
		SorExport: 'SOR_EXPORT',
		SorUpdate: 'SOR_UPDATE',
		SorView: 'SOR_VIEW',
		SubscriberEdit: 'SUBSCRIBER_EDIT',
		SubscriberView: 'SUBSCRIBER_VIEW',
		UserExport: 'USER_EXPORT',
		UserUpdate: 'USER_UPDATE',
		UserView: 'USER_VIEW',
		UserRoleExport: 'USERROLE_EXPORT',
		UserRoleUpdate: 'USERROLE_UPDATE',
		UserRoleView: 'USERROLE_VIEW',

		Names: {
			AreaExport: 'Area - Export',
			AreaUpdate: 'Area - Update',
			AreaView: 'Area - View',
			AssetExport: 'Asset - Export',
			AssetUpdate: 'Asset - Update',
			AssetView: 'Asset - View',
			BusinessEntityExport: 'Business Contact - Export',
			BusinessEntityUpdate: 'Business Contact - Update',
			BusinessEntityView: 'Business Contact - View',
			CalendarExport: 'Calendar - Export',
			CalendarUpdate: 'Calendar - Update',
			CalendarView: 'Calendar - View',
			ClientExport: 'Client - Export',
			ClientUpdate: 'Client - Update',
			ClientView: 'Client - View',
			CommonActivityView: 'Common - Activity View',
			CommonAttachmentsCreate: 'Common - Attachments Create',
			CommonAttachmentsEdit: 'Common - Attachments Edit',
			CommonAttachmentsRemove: 'Common - Attachments Remove',
			CommonContactsRemove: 'Common - Contacts Remove',
			CommonContactsUpdate: 'Common - Contacts Update',
			CommonFinancialSettingsEdit: 'Common - Financial Settings Edit',
			CommonMapsCreate: 'Common - Plans Create',
			CommonMapsEdit: 'Common - Plans Edit',
			CommonMapsRemove: 'Common - Plans Remove',
			CommonNotesCreate: 'Common - Notes Create',
			CommonNotesEdit: 'Common - Notes Edit',
			CommonNotesRemove: 'Common - Notes Remove',
			CommonRateGroupEdit: 'Common - Rate Group Edit',
			CommonTaxCodesEdit: 'Common - Tax Codes Edit',
			CommonViewPrices: 'Common - View Prices',
			CompanyExport: 'Company - Export',
			CompanyUpdate: 'Company - Update',
			CompanyView: 'Company - View',
			DashboardContractorStatistics: 'Dashboard - Contractor Statistics',
			DashboardInterfaceEvents: 'Dashboard - Interface Events',
			DashboardLicenceInsuranceExpiry: 'Dashboard - Licence & Insurance Expiry',
			DashboardMap: 'Dashboard - Map',
			DashboardOpenServiceRequests: 'Dashboard - Open Service Requests',
			DashboardPOBreakdown: 'Dashboard - PO Breakdown',
			DashboardPOVolumebyPriority: 'Dashboard - PO Volume by Priority',
			DashboardReadyforAction: 'Dashboard - Ready for Action',
			DashboardScorecard: 'Dashboard - Scorecard',
			DashboardSpendTrend: 'Dashboard - Spend Trend',
			DashboardTimeKPI: 'Dashboard - Time KPI %',
			DashboardUnpaidInvoices: 'Dashboard - Unpaid Invoices',
			DashboardView: 'Dashboard - View',
			DashboardWorkTypeAnalysis: 'Dashboard - Work Type Analysis',
			DomainValueExport: 'Field - Export',
			DomainValueUpdate: 'Field - Update',
			DomainValueView: 'Field - View',
			IntegrationModuleUpdate: 'Integration Module - Update',
			IntegrationModuleView: 'Integration Module - View',
			InterfaceExport: 'Interface - Export',
			InterfaceUpdate: 'Interface - Update',
			InterfaceView: 'Interface - View',
			InvoiceCreate: 'Invoice - Create',
			InvoiceEdit: 'Invoice - Edit',
			InvoiceExport: 'Invoice - Export',
			InvoiceView: 'Invoice - View',
			OrderCreate: 'Order - Create',
			OrderEdit: 'Order - Edit',
			OrderExport: 'Order - Export',
			OrderPriceOverrides: 'Order - Price Overrides',
			OrderReview: 'Order - Review',
			OrderView: 'Order - View',
			ProjectExport: 'Project - Export',
			ProjectUpdate: 'Project - Update',
			ProjectView: 'Project - View',
			QuoteCreate: 'Quote - Create',
			QuoteEdit: 'Quote - Edit',
			QuoteExport: 'Quote - Export',
			QuoteView: 'Quote - View',
			ReportView: 'Report - View',
			ScopeCreate: 'Scope - Create',
			ScopeEdit: 'Scope - Edit',
			ScopeExport: 'Scope - Export',
			ScopeReview: 'Scope - Review',
			ScopeView: 'Scope - View',
			ServiceRequestExport: 'Service Request - Export',
			ServiceRequestUpdate: 'Service Request - Update (level 2)',
			ServiceRequestUpdateBasic: 'Service Request - Update (level 1)',
			ServiceRequestView: 'Service Request - View',
			SorExport: 'Rate - Export',
			SorUpdate: 'Rate - Update',
			SorView: 'Rate - View',
			SubscriberEdit: 'Subscriber - Edit',
			SubscriberView: 'Subscriber - View',
			UserExport: 'User - Export',
			UserUpdate: 'User - Update',
			UserView: 'User - View',
			UserRoleExport: 'User Role - Export',
			UserRoleUpdate: 'User Role - Update',
			UserRoleView: 'User Role - View',
		},

		Values: [
			new LookupValue({ CodeValue: 'AREA_EXPORT', NameValue: 'Area - Export' }),
			new LookupValue({ CodeValue: 'AREA_UPDATE', NameValue: 'Area - Update' }),
			new LookupValue({ CodeValue: 'AREA_VIEW', NameValue: 'Area - View' }),
			new LookupValue({ CodeValue: 'ASSET_EXPORT', NameValue: 'Asset - Export' }),
			new LookupValue({ CodeValue: 'ASSET_UPDATE', NameValue: 'Asset - Update' }),
			new LookupValue({ CodeValue: 'ASSET_VIEW', NameValue: 'Asset - View' }),
			new LookupValue({ CodeValue: 'BUSINESSENTITY_EXPORT', NameValue: 'Business Contact - Export' }),
			new LookupValue({ CodeValue: 'BUSINESSENTITY_UPDATE', NameValue: 'Business Contact - Update' }),
			new LookupValue({ CodeValue: 'BUSINESSENTITY_VIEW', NameValue: 'Business Contact - View' }),
			new LookupValue({ CodeValue: 'CALENDAR_EXPORT', NameValue: 'Calendar - Export' }),
			new LookupValue({ CodeValue: 'CALENDAR_UPDATE', NameValue: 'Calendar - Update' }),
			new LookupValue({ CodeValue: 'CALENDAR_VIEW', NameValue: 'Calendar - View' }),
			new LookupValue({ CodeValue: 'CLIENT_EXPORT', NameValue: 'Client - Export' }),
			new LookupValue({ CodeValue: 'CLIENT_UPDATE', NameValue: 'Client - Update' }),
			new LookupValue({ CodeValue: 'CLIENT_VIEW', NameValue: 'Client - View' }),
			new LookupValue({ CodeValue: 'COMMON_ACTIVITY_VIEW', NameValue: 'Common - Activity View' }),
			new LookupValue({ CodeValue: 'COMMON_ATTACH_CREATE', NameValue: 'Common - Attachments Create' }),
			new LookupValue({ CodeValue: 'COMMON_ATTACH_EDIT', NameValue: 'Common - Attachments Edit' }),
			new LookupValue({ CodeValue: 'COMMON_ATTACH_REMOVE', NameValue: 'Common - Attachments Remove' }),
			new LookupValue({ CodeValue: 'COMMON_CONTACTS_REMOVE', NameValue: 'Common - Contacts Remove' }),
			new LookupValue({ CodeValue: 'COMMON_CONTACTS_UPDATE', NameValue: 'Common - Contacts Update' }),
			new LookupValue({ CodeValue: 'COMMON_FINANCE_EDIT', NameValue: 'Common - Financial Settings Edit' }),
			new LookupValue({ CodeValue: 'COMMON_MAPS_CREATE', NameValue: 'Common - Plans Create' }),
			new LookupValue({ CodeValue: 'COMMON_MAPS_EDIT', NameValue: 'Common - Plans Edit' }),
			new LookupValue({ CodeValue: 'COMMON_MAPS_REMOVE', NameValue: 'Common - Plans Remove' }),
			new LookupValue({ CodeValue: 'COMMON_NOTES_CREATE', NameValue: 'Common - Notes Create' }),
			new LookupValue({ CodeValue: 'COMMON_NOTES_EDIT', NameValue: 'Common - Notes Edit' }),
			new LookupValue({ CodeValue: 'COMMON_NOTES_REMOVE', NameValue: 'Common - Notes Remove' }),
			new LookupValue({ CodeValue: 'COMMON_RATEGROUP_EDIT', NameValue: 'Common - Rate Group Edit' }),
			new LookupValue({ CodeValue: 'COMMON_TAX_EDIT', NameValue: 'Common - Tax Codes Edit' }),
			new LookupValue({ CodeValue: 'COMMON_FINANCE_PRICES', NameValue: 'Common - View Prices' }),
			new LookupValue({ CodeValue: 'COMPANY_EXPORT', NameValue: 'Company - Export' }),
			new LookupValue({ CodeValue: 'COMPANY_UPDATE', NameValue: 'Company - Update' }),
			new LookupValue({ CodeValue: 'COMPANY_VIEW', NameValue: 'Company - View' }),
			new LookupValue({ CodeValue: 'DASHBOARD_CONTRACTORSTATS', NameValue: 'Dashboard - Contractor Statistics' }),
			new LookupValue({ CodeValue: 'DASHBOARD_INTERFACE', NameValue: 'Dashboard - Interface Events' }),
			new LookupValue({ CodeValue: 'DASHBOARD_LICINSURANCE', NameValue: 'Dashboard - Licence & Insurance Expiry' }),
			new LookupValue({ CodeValue: 'DASHBOARD_MAP', NameValue: 'Dashboard - Map' }),
			new LookupValue({ CodeValue: 'DASHBOARD_SRQ', NameValue: 'Dashboard - Open Service Requests' }),
			new LookupValue({ CodeValue: 'DASHBOARD_POBREAKDOWN', NameValue: 'Dashboard - PO Breakdown' }),
			new LookupValue({ CodeValue: 'DASHBOARD_POVOLUME', NameValue: 'Dashboard - PO Volume by Priority' }),
			new LookupValue({ CodeValue: 'DASHBOARD_RFA', NameValue: 'Dashboard - Ready for Action' }),
			new LookupValue({ CodeValue: 'DASHBOARD_SCORECARD', NameValue: 'Dashboard - Scorecard' }),
			new LookupValue({ CodeValue: 'DASHBOARD_SPENDTREND', NameValue: 'Dashboard - Spend Trend' }),
			new LookupValue({ CodeValue: 'DASHBOARD_TIMEKPI', NameValue: 'Dashboard - Time KPI %' }),
			new LookupValue({ CodeValue: 'DASHBOARD_INVOICE', NameValue: 'Dashboard - Unpaid Invoices' }),
			new LookupValue({ CodeValue: 'DASHBOARD_VIEW', NameValue: 'Dashboard - View' }),
			new LookupValue({ CodeValue: 'DASHBOARD_WORKTYPE', NameValue: 'Dashboard - Work Type Analysis' }),
			new LookupValue({ CodeValue: 'DOMAINVALUE_EXPORT', NameValue: 'Field - Export' }),
			new LookupValue({ CodeValue: 'DOMAINVALUE_UPDATE', NameValue: 'Field - Update' }),
			new LookupValue({ CodeValue: 'DOMAINVALUE_VIEW', NameValue: 'Field - View' }),
			new LookupValue({ CodeValue: 'INTMODULE_UPDATE', NameValue: 'Integration Module - Update' }),
			new LookupValue({ CodeValue: 'INTMODULE_VIEW', NameValue: 'Integration Module - View' }),
			new LookupValue({ CodeValue: 'INTERFACE_EXPORT', NameValue: 'Interface - Export' }),
			new LookupValue({ CodeValue: 'INTERFACE_UPDATE', NameValue: 'Interface - Update' }),
			new LookupValue({ CodeValue: 'INTERFACE_VIEW', NameValue: 'Interface - View' }),
			new LookupValue({ CodeValue: 'INVOICE_CREATE', NameValue: 'Invoice - Create' }),
			new LookupValue({ CodeValue: 'INVOICE_EDIT', NameValue: 'Invoice - Edit' }),
			new LookupValue({ CodeValue: 'INVOICE_EXPORT', NameValue: 'Invoice - Export' }),
			new LookupValue({ CodeValue: 'INVOICE_VIEW', NameValue: 'Invoice - View' }),
			new LookupValue({ CodeValue: 'ORDER_CREATE', NameValue: 'Order - Create' }),
			new LookupValue({ CodeValue: 'ORDER_EDIT', NameValue: 'Order - Edit' }),
			new LookupValue({ CodeValue: 'ORDER_EXPORT', NameValue: 'Order - Export' }),
			new LookupValue({ CodeValue: 'ORDER_PRICE_OVERRIDE', NameValue: 'Order - Price Overrides' }),
			new LookupValue({ CodeValue: 'ORDER_REVIEW', NameValue: 'Order - Review' }),
			new LookupValue({ CodeValue: 'ORDER_VIEW', NameValue: 'Order - View' }),
			new LookupValue({ CodeValue: 'PROJECT_EXPORT', NameValue: 'Project - Export' }),
			new LookupValue({ CodeValue: 'PROJECT_UPDATE', NameValue: 'Project - Update' }),
			new LookupValue({ CodeValue: 'PROJECT_VIEW', NameValue: 'Project - View' }),
			new LookupValue({ CodeValue: 'QUOTE_CREATE', NameValue: 'Quote - Create' }),
			new LookupValue({ CodeValue: 'QUOTE_EDIT', NameValue: 'Quote - Edit' }),
			new LookupValue({ CodeValue: 'QUOTE_EXPORT', NameValue: 'Quote - Export' }),
			new LookupValue({ CodeValue: 'QUOTE_VIEW', NameValue: 'Quote - View' }),
			new LookupValue({ CodeValue: 'REPORT_VIEW', NameValue: 'Report - View' }),
			new LookupValue({ CodeValue: 'SCOPE_CREATE', NameValue: 'Scope - Create' }),
			new LookupValue({ CodeValue: 'SCOPE_EDIT', NameValue: 'Scope - Edit' }),
			new LookupValue({ CodeValue: 'SCOPE_EXPORT', NameValue: 'Scope - Export' }),
			new LookupValue({ CodeValue: 'SCOPE_REVIEW', NameValue: 'Scope - Review' }),
			new LookupValue({ CodeValue: 'SCOPE_VIEW', NameValue: 'Scope - View' }),
			new LookupValue({ CodeValue: 'SRQ_EXPORT', NameValue: 'Service Request - Export' }),
			new LookupValue({ CodeValue: 'SRQ_UPDATE', NameValue: 'Service Request - Update (level 2)' }),
			new LookupValue({ CodeValue: 'SRQ_UPDATE_BASIC', NameValue: 'Service Request - Update (level 1)' }),
			new LookupValue({ CodeValue: 'SRQ_VIEW', NameValue: 'Service Request - View' }),
			new LookupValue({ CodeValue: 'SOR_EXPORT', NameValue: 'Rate - Export' }),
			new LookupValue({ CodeValue: 'SOR_UPDATE', NameValue: 'Rate - Update' }),
			new LookupValue({ CodeValue: 'SOR_VIEW', NameValue: 'Rate - View' }),
			new LookupValue({ CodeValue: 'SUBSCRIBER_EDIT', NameValue: 'Subscriber - Edit' }),
			new LookupValue({ CodeValue: 'SUBSCRIBER_VIEW', NameValue: 'Subscriber - View' }),
			new LookupValue({ CodeValue: 'USER_EXPORT', NameValue: 'User - Export' }),
			new LookupValue({ CodeValue: 'USER_UPDATE', NameValue: 'User - Update' }),
			new LookupValue({ CodeValue: 'USER_VIEW', NameValue: 'User - View' }),
			new LookupValue({ CodeValue: 'USERROLE_EXPORT', NameValue: 'User Role - Export' }),
			new LookupValue({ CodeValue: 'USERROLE_UPDATE', NameValue: 'User Role - Update' }),
			new LookupValue({ CodeValue: 'USERROLE_VIEW', NameValue: 'User Role - View' }),
		],
	};

	public static UserProfileStatus = {
		Active: 'Active',
		Disabled: 'Disabled',
		Invited: 'Invited',

		Names: {
			Active: 'Active',
			Disabled: 'Disabled',
			Invited: 'Invited',
		},

		Values: [
			new LookupValue({ CodeValue: 'Active', NameValue: 'Active' }),
			new LookupValue({ CodeValue: 'Disabled', NameValue: 'Disabled' }),
			new LookupValue({ CodeValue: 'Invited', NameValue: 'Invited' }),
		],
	};

	public static UserRelationType = {
		Client: 'CLIENT',
		Subscriber: 'SUBSCRIBER',

		Names: {
			Client: 'Client',
			Subscriber: 'Subscriber',
		},

		Values: [new LookupValue({ CodeValue: 'CLIENT', NameValue: 'Client' }), new LookupValue({ CodeValue: 'SUBSCRIBER', NameValue: 'Subscriber' })],
	};

	public static WeeklyRecurrenceOption = {
		First: 'FIRST',
		Fourth: 'FOURTH',
		Last: 'LAST',
		Second: 'SECOND',
		Third: 'THIRD',

		Names: {
			First: 'First',
			Fourth: 'Fourth',
			Last: 'Last',
			Second: 'Second',
			Third: 'Third',
		},

		Values: [
			new LookupValue({ CodeValue: 'FIRST', NameValue: 'First' }),
			new LookupValue({ CodeValue: 'FOURTH', NameValue: 'Fourth' }),
			new LookupValue({ CodeValue: 'LAST', NameValue: 'Last' }),
			new LookupValue({ CodeValue: 'SECOND', NameValue: 'Second' }),
			new LookupValue({ CodeValue: 'THIRD', NameValue: 'Third' }),
		],
	};

	public static WorkOrderStatus = {
		Allocated: 'ALLOCATED',
		Cancelled: 'CANCELLED',
		Created: 'CREATED',
		InProgress: 'INPROGRESS',
		Paused: 'PAUSED',
		ReviewRequired: 'REVIEWREQ',
		ReviewedApproved: 'REVIEWED',

		Names: {
			Allocated: 'Allocated',
			Cancelled: 'Cancelled',
			Created: 'Created',
			InProgress: 'In Progress',
			Paused: 'Paused',
			ReviewRequired: 'Review Required',
			ReviewedApproved: 'Reviewed & Approved',
		},

		Values: [
			new LookupValue({ CodeValue: 'ALLOCATED', NameValue: 'Allocated' }),
			new LookupValue({ CodeValue: 'CANCELLED', NameValue: 'Cancelled' }),
			new LookupValue({ CodeValue: 'CREATED', NameValue: 'Created' }),
			new LookupValue({ CodeValue: 'INPROGRESS', NameValue: 'In Progress' }),
			new LookupValue({ CodeValue: 'PAUSED', NameValue: 'Paused' }),
			new LookupValue({ CodeValue: 'REVIEWREQ', NameValue: 'Review Required' }),
			new LookupValue({ CodeValue: 'REVIEWED', NameValue: 'Reviewed & Approved' }),
		],
	};

	public static WorkOrderType = {
		Credit: 'CREDIT',
		Recovery: 'RECOVERY',
		Standard: 'STANDARD',

		Names: {
			Credit: 'Credit',
			Recovery: 'Recovery',
			Standard: 'Standard',
		},

		Values: [
			new LookupValue({ CodeValue: 'CREDIT', NameValue: 'Credit' }),
			new LookupValue({ CodeValue: 'RECOVERY', NameValue: 'Recovery' }),
			new LookupValue({ CodeValue: 'STANDARD', NameValue: 'Standard' }),
		],
	};

	// #endregion
}
