import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Table } from 'primeng/table';
import { Observable } from 'rxjs';
import { RateGroupDetail } from 'src/app/features/rate/rate-group/models';
import { RateGroupService } from 'src/app/features/rate/rate-group/services';
import { Globals } from 'src/app/globals';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { RegionSettings } from 'src/app/shared/models/settings';
import { DataTableBaseComponent } from 'src/app/core/components/data-table.base.component';
import { AppContextService, NavigationService, UtilService } from 'src/app/core/services';
import { LazyLoadArgs, PagedResponse } from '../../../models/api';

@Component({
	selector: 'app-related-rategroup',
	templateUrl: './related-rategroup.component.html',
	styleUrls: ['./related-rategroup.component.scss'],
})
export class RelatedRateGroupComponent extends DataTableBaseComponent<RateGroupDetail> implements OnInit {
	@ViewChild('relatedRateGroupTable') relatedRateGroupTable: Table;
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;

	relatedRateGroupDetails: RateGroupDetail[] = [];
	totalRecords = 0;

	item: RateGroupDetail;
	showList = true;
	refreshRequired = false;
	timeZoneCode: string;
	isClientUser: boolean;
	public util: UtilService;
	region: RegionSettings;
	constructor(
		appContext: AppContextService,
		globals: Globals,
		config: PrimeNGConfig,
		private rateGroupService: RateGroupService,
		private router: Router,
		private navigationService: NavigationService,
		private changeDetector: ChangeDetectorRef
	) {
		super(globals, appContext, config);
	}

	ngOnInit(): void {
		this.region = this.appContext.getRegion();
	}

	lazyLoad(event: LazyLoadArgs): Observable<PagedResponse<RateGroupDetail>> {
		this.changeDetector.detectChanges();
		return this.rateGroupService.getPagedListForReference(event, this.referenceTypeCode, this.referenceId);
	}

	canNavigateToDetails(dto: RateGroupDetail) {
		return this.getNavigationRoute(dto) != null;
	}

	handleRowClick(dto: RateGroupDetail) {
		this.navigationService.navigateToReference(Constants.ReferenceType.RateGroup, dto.RateGroupId);
	}

	getNavigationRoute(dto: RateGroupDetail) {
		if (dto.RateGroupId != null) return true;
		return null;
	}

	showLink(): boolean {
		return !this.isClientUser;
	}
}
