import { Component, Input } from '@angular/core';
import { Constants } from '../../constants/constants.constant';
import {
	RELATED_TAB_CLIENT_DISPLAY_FILTER_LIST,
	RELATED_TAB_DEFAULT_FILTER_LIST,
	RelatedTabDisplayFilterType,
} from '../../enums/related-tab-display-filter.enum';
import { FormMode } from '../../models/forms';

@Component({
	selector: 'app-related-tab',
	templateUrl: './related-tab.component.html',
	styleUrls: ['./related-tab.component.scss'],
})
export class RelatedTabComponent {
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;
	formMode = FormMode.View;
	formModes = FormMode;

	displayFilterOptions: any;
	displayFilterTypes = RelatedTabDisplayFilterType;
	selectedFilterOption: any;

	ngOnInit(): void {
		this.formMode = this.formModes.View;
		this.displayFilterOptions = this.getFilterOptions();
	}

	getFilterOptions() {
		switch (this.referenceTypeCode) {
			case Constants.ReferenceType.Client:
				return RELATED_TAB_CLIENT_DISPLAY_FILTER_LIST;
			default:
				return RELATED_TAB_DEFAULT_FILTER_LIST;
		}
	}

	changeFilterOption(event: any) {
		this.selectedFilterOption = this.displayFilterOptions[this.displayFilterTypes[event.value]].value;
	}

	isSelectedSubTab(subTab: string) {
		return this.selectedFilterOption == subTab;
	}
}
