<div class="div-bulk-action-dropdown">
	<span class="p-float-label" *ngIf="showEdit">
		<p-dropdown
			data-testid="bulkAssetActions"
			inputId="dropdown"
			[autoDisplayFirst]="false"
			[options]="bulkActionService.filterActions"
			[(ngModel)]="bulkActionService.filterActionSelected"
			optionLabel="NameValue"
			optionValue="CodeValue"
			appendTo="body"
			(onChange)="onfilterActionChange($event)">
		</p-dropdown>
		<label for="dropdown">Select action</label>
	</span>
	<p-button
		data-testid="bulkActionApply"
		*ngIf="showEdit"
		label="APPLY"
		icon=""
		class="p-m-1"
		[disabled]="!bulkActionService.allowApply()"
		(onClick)="bulkActionService.handleApplyClick()"
		pTooltip="Apply"
		tooltipPosition="bottom">
	</p-button>
	<p-button
		data-testid="bulkActionRefresh"
		*ngIf="bulkActionService.showRefresh"
		label="REFRESH"
		icon=""
		class="p-m-1"
		(onClick)="bulkActionService.handleRefreshClick()"
		pTooltip="Refresh"
		tooltipPosition="bottom">
	</p-button>
	<p-button
		data-testid="bulkActionClearAll"
		*ngIf="bulkActionService.showClearAll()"
		label="CANCEL"
		icon=""
		class="p-m-1"
		(onClick)="bulkActionService.handleClearAllClick()"
		pTooltip="Cancel"
		tooltipPosition="bottom">
	</p-button>
	<span data-testid="bulkActionRecordsCount" *ngIf="showEdit" class="p-input-icon-right p-mr-1 p-ml-3">
		{{ bulkActionService.getSelectedItemCount() }}
	</span>
</div>
