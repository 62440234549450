export const INPUT_INFO_MESSAGE = {
	SUBSCRIBER_REGION_SETTING: {
		CountryCodes: {
			message: 'When using the Google address search, the entries will be restricted to these countries.',
		},
		AddressTypes: {
			message: 'When using the Google address search, the entries will be restricted to these address types.',
		},
		AddressPrefixComponents: {
			message: 'When using the Google address search, these component values will be treated as the unit number when populating the address fields.',
		},
		AddressStreetNumberComponents: {
			message: 'When using the Google address search, these component values will be treated as the street number when populating the address fields.',
		},
		AddressStreetNameComponents: {
			message: 'When using the Google address search, these component values will be treated as the street name when populating the address fields.',
		},
		AddressSuburbComponents: {
			message: 'When using the Google address search, these component values will be treated as the suburb when populating the address fields.',
		},
		AddressPostcodeComponents: {
			message: 'When using the Google address search, these component values will be treated as the postcode when populating the address fields.',
		},
		AddressStateComponents: {
			message: 'When using the Google address search, these component values will be treated as the state when populating the address fields.',
		},
		AddressCountryComponents: {
			message: 'When using the Google address search, these component values will be treated as the country when populating the address fields.',
		},
		DefaultMapLocation: {
			message: 'The starting position of the dashboard map widget.',
		},
	},
	SUBSCRIBER_WORKFLOW_SETTING: {
		ScorecardRefresh: {
			message: 'How often the Scorecard dashboard widget waits before refreshing.',
		},
		ReadyForActionRefresh: {
			message: 'How often the Ready For Action dashboard widget waits before refreshing.',
		},
		TimeKpiRefresh: {
			message: 'How often the Time KPI dashboard widget waits before refreshing.',
		},
		SpendTrendRefresh: {
			message: 'How often the Spend Trend dashboard widget waits before refreshing.',
		},
		LicenseInsuranceRefresh: {
			message: 'How often the License Insurance dashboard widget waits before refreshing.',
		},
		ContractorStatisticsRefresh: {
			message: 'How often the Contractor Statistics dashboard widget waits before refreshing.',
		},
		PurchaseOrderBreakdownRefresh: {
			message: 'How often the Purchase Order Breakdown dashboard widget waits before refreshing.',
		},
		PurchaseOrderVolumeRefresh: {
			message: 'How often the Purchase Order Volume dashboard widget waits before refreshing.',
		},
		WorkTypeAnalysisRefresh: {
			message: 'How often the Work Type Analysis dashboard widget waits before refreshing.',
		},
		UnpaidInvoicesRefresh: {
			message: 'How often the Unpaid Invoice dashboard widget waits before refreshing.',
		},
		InterfaceEventsRefresh: {
			message: 'How often the Interface Events dashboard widget waits before refreshing.',
		},
		ScopeIsTargetDateOptional: {
			message: 'If checked, when creating a new or editing an existing scope the "Due" date can be left blank.',
		},
		QuoteRequestIsTargetDateOptional: {
			message: 'If checked, when creating a new or editing an existing quote request the "Due" date can be left blank.',
		},
		DefectRecoverMoneyByDefault: {
			message:
				'If checked, when raising a defect on a work order item the "Recovery Money?" field will be automatically selected where the "At Fault Contractor" is different to the "To Resolve Contractor".',
		},
		InvoiceAutomateInvoiceCreationForWorkOrders: {
			message:
				'If checked, when a work order status is changed to "Reviewed & Approved" or "Cancelled", automatically create or cancel invoices as required to align to the current sell of the work order.',
		},
		InvoiceAutomateInvoiceCreationFoPurchaseOrders: {
			message:
				'If checked, when a purchase order status is changed to "Reviewed & Approved" or "Cancelled", automatically create or cancel invoices as required to align to the current cost of the purchase order.',
		},
		InvoiceBlockCustomPaymentDates: {
			message:
				'If checked, when creating a new invoice or editing an invoice in "Created" status, prevent the payment date from being manually changed by the user.',
		},
		InvoiceDefaultPaymentTermCode: {
			message: 'The default payment terms to use when creating an ad-hoc invoice, or an invoice against a client without configured payment terms.',
		},
		InvoiceAllowManualPayments: {
			message: 'If checked, allows for invoices to be marked as paid and for payments to be added to invoices within SpyderFlow.',
		},
		AssetAllowQrBarcodeEditing: {
			message: 'If checked, allows for QR and barcodes to be edited if the user has the required permission.',
		},
	},
	SUBSCRIBER_COMMUNICATION_SETTING: {
		ReplyTo_System: {
			message: 'The "Reply-To" e-mail address for any system-related e-mails sent from Spyderflow, such as user invites.',
		},
		ReplyTo_ServiceRequests: {
			message: 'The "Reply-To" e-mail address for any service request-related e-mails sent from Spyderflow.',
		},
		ReplyTo_Scopes: {
			message: 'The "Reply-To" e-mail address for any scope-related e-mails sent from Spyderflow.',
		},
		ReplyTo_QuoteRequests: {
			message: 'The "Reply-To" e-mail address for any quote-related e-mails sent from Spyderflow.',
		},
		ReplyTo_PurchaseOrders: {
			message: 'The "Reply-To" e-mail address for any purchase order-related e-mails sent from Spyderflow.',
		},
		ReplyTo_DefectPurchaseOrders: {
			message: 'The "Reply-To" e-mail address for any defect-related e-mails sent from Spyderflow.',
		},
		ReplyTo_CreditRecoveryPurchaseOrders: {
			message: 'The "Reply-To" e-mail address for any credit or recovery-related e-mails sent from Spyderflow.',
		},
		ReplyTo_Invoices: {
			message: 'The "Reply-To" e-mail address for any invoice-related e-mails sent from Spyderflow.',
		},
		DefaultSendTo_ServiceRequests: {
			message:
				'The "To" e-mail address for any service request-related e-mails sent from Spyderflow when the SRQ is not assigned to an owner, or that owner does not have an e-mail address.',
		},
		DefaultSendTo_ReceivableInvoices: {
			message: 'The "To" e-mail addresses for any e-mails sent when a receivable invoice is approved or voided.',
		},
		DefaultSendTo_PayableInvoices: {
			message: 'The "To" e-mail addresses for any e-mails sent when a payable invoice is approved or voided.',
		},
		DefaultSendTo_SalesCreditNotes: {
			message: 'The "To" e-mail addresses for any e-mails sent when a sales credit note is approved or voided.',
		},
		DefaultSendTo_PurchasesCreditNotes: {
			message: 'The "To" e-mail addresses for any e-mails sent when a purchases credit note is approved or voided.',
		},
	},
	INTEGRATION_MODULE_XERO: {
		XeroTenantId: {
			message: 'The tenant ID provided by Xero. This is automatically retrieved from Xero when updating the client ID and secret.',
		},
		XeroClientId: {
			message: 'The client ID provided by Xero as part of the custom connection for SpyderFlow.',
		},
		XeroClientSecret: {
			message: 'The client secret generated by Xero as part of the custom connection for SpyderFlow.',
		},
		XeroScope: {
			message: 'The scopes which must be selected in Xero when creating or updating the custom connection for SpyderFlow.',
		},
		XeroWebhookKey: {
			message: 'The Webhook key provided by Xero after validating the delivery URL as part of the Webhook section of the custom connection.',
		},
		XeroHeader: {
			message:
				'To set up a Xero connection with SpyderFlow, the Custom Connections module in Xero is required. Refer to Xero help for requirements and instructions on how to set up a new custom connection. The below details should align with the configuration for the SpyderFlow custom connection set up within Xero.',
		},
		WebhookNotification: {
			message: 'SpyderFlow needs to be notified of changes made to these values when configuring the Webhook section of the Xero custom connection.',
		},
		WebhookDeliveryURL: {
			message: 'The delivery URL which must be entered into the Webhook section of the Xero custom connection.',
		},
		TaxCodesHeader: {
			message:
				'Every tax code used within SpyderFlow should have a corresponding tax code within Xero at the same tax rate. Refer to Xero to obtain the exact codes to be entered for each mapping.',
		},
		AccountCodesHeader: {
			message: 'Refer to Xero for setting up account codes.',
		},
		RestrictionsHeader: {
			message:
				'By default, all invoices and credit notes will be transferred. To restrict which invoices and credit notes should be transferred, select one or more entries below.',
		},
		CompanyRestrictions: {
			message: 'If set, only invoices associated with the selected companies will be transferred to Xero.',
		},
		ClientRestrictions: {
			message: 'If set, only invoices associated with the selected clients will be transferred to Xero.',
		},
		ContractorRestrictions: {
			message: 'If set, only invoices associated with the selected contractors will be transferred to Xero.',
		},
		SupplierRestrictions: {
			message: 'If set, only invoices associated with the selected suppliers will be transferred to Xero.',
		},
	},
	SUBSCRIBER_FINANCIAL_SETTING: {
		BusinessRegistrationNumber: {
			message: 'The default business registration number which is displayed on any generated invoice documents.',
		},
		BillingName: {
			message: 'The default billing name which is displayed on any generated invoice documents.',
		},
		BillingAddress: {
			message: 'The default billing address which is displayed on any generated invoice documents.',
		},
		BillingSuburb: {
			message: 'The default billing suburb which is displayed on any generated invoice documents.',
		},
		BillingState: {
			message: 'The default billing state which is displayed on any generated invoice documents.',
		},
		BillingCountry: {
			message: 'The default billing country which is displayed on any generated invoice documents.',
		},
		BillingPostcode: {
			message: 'The default billing postcode which is displayed on any generated invoice documents.',
		},
	},
	SERVICE_REQUEST_TYPE: {
		SendInternalNotificationsForNew: {
			message:
				'Send an e-mail to the SRQ Owner\'s e-mail address (or Subscription e-mail address, if not set) when a "New Service Request" notification is sent using the "Submit Request" action.',
		},
		SendInternalNotificationsForUpdated: {
			message:
				'Send an e-mail to the SRQ Owner\'s e-mail address (or Subscription e-mail address, if not set) when a "Updated Service Request" notification is sent using the "Submit Request" action.',
		},
		SendInternalNotificationsForReviewed: {
			message:
				'Send an e-mail to the SRQ Owner\'s e-mail address (or Subscription e-mail address, if not set) when a "Reviewed Service Request" notification is sent using the "Submit Request" action.',
		},
		SendInternalNotificationsForClosed: {
			message:
				'Send an e-mail to the SRQ Owner\'s e-mail address (or Subscription e-mail address, if not set) when a "Closed Service Request" notification is sent using the "Submit Request" action.',
		},
		SendInternalNotificationsForCancelled: {
			message:
				'Send an e-mail to the SRQ Owner\'s e-mail address (or Subscription e-mail address, if not set) when a "Cancelled Service Request" notification is sent using the "Submit Request" action or "Cancel SRQ" action.',
		},
		SendClientNotificationsForNew: {
			message:
				'Send an e-mail to the Client\'s e-mail address(es), if set, when a "New Service Request" notification is sent using the "Submit Request" action.',
		},
		SendClientNotificationsForUpdated: {
			message:
				'Send an e-mail to the Client\'s e-mail address(es), if set, when a "Updated Service Request" notification is sent using the "Submit Request" action.',
		},
		SendClientNotificationsForReviewed: {
			message:
				'Send an e-mail to the Client\'s e-mail address(es), if set, when a "Reviewed Service Request" notification is sent using the "Submit Request" action.',
		},
		SendClientNotificationsForClosed: {
			message:
				'Send an e-mail to the Client\'s e-mail address(es), if set, when a "Closed Service Request" notification is sent using the "Submit Request" action.',
		},
		SendClientNotificationsForCancelled: {
			message:
				'Send an e-mail to the Client\'s e-mail address(es), if set, when a "Cancelled Service Request" notification is sent using the "Submit Request" action.',
		},
	},
	CLIENT_NOTIFICATION_SETTINGS: {
		ServiceRequestEmailsForNew: {
			message:
				'Send an e-mail to the Client\'s configured e-mail address(es) when a "New Service Request" notification is sent using the "Send Changes" action.',
		},
		ServiceRequestEmailsForUpdated: {
			message:
				'Send an e-mail to the Client\'s configured e-mail address(es) when a "Updated Service Request" notification is sent using the "Send Changes" action.',
		},
		ServiceRequestEmailsForReviewed: {
			message:
				'Send an e-mail to the Client\'s configured e-mail address(es) when a "Reviewed Service Request" notification is sent using the "Send Changes" action.',
		},
		ServiceRequestEmailsForClosed: {
			message:
				'Send an e-mail to the Client\'s configured e-mail address(es) when a "Closed Service Request" notification is sent using the "Send Changes" action.',
		},
		ServiceRequestEmailsForCancelled: {
			message:
				'Send an e-mail to the Client\'s configured e-mail address(es) when a "Cancelled Service Request" notification is sent using the "Send Changes" action.',
		},
		InvoiceEmailsForApproved: {
			message: "Send an e-mail to the Client's configured e-mail address(es) when an invoice is approved.",
		},
	},
	SUPPLIER_NOTIFICATION_SETTINGS: {
		SendInvoiceRelatedEmails: {
			message: 'The email address(es) to send notifications to for any invoice-related e-mails sent from Spyderflow.',
		},
	},
	CONTRACTOR_COMMUNICATION_SETTING: {
		EmailTo_Scopes: {
			message: 'The e-mail address(es) to send notifications to for any scope-related e-mails sent from Spyderflow.',
		},
		EmailTo_QuoteRequests: {
			message: 'The e-mail address(es) to send notifications to for any quote-related e-mails sent from Spyderflow.',
		},
		EmailTo_PurchaseOrders: {
			message: 'The e-mail address(es) to send notifications to for any purchase order-related e-mails sent from Spyderflow.',
		},
		EmailTo_DefectPurchaseOrders: {
			message: 'The e-mail address(es) to send notifications to for any defect-related e-mails sent from Spyderflow.',
		},
		EmailTo_CreditRecoveryPurchaseOrders: {
			message: 'The e-mail address(es) to send notifications to for any credit or recovery-related e-mails sent from Spyderflow.',
		},
		EmailTo_Invoices: {
			message: 'The e-mail address(es) to send notifications to for any invoice-related e-mails sent from Spyderflow.',
		},

		SendNotificationsForNewScope: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "New Scope" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForUpdatedScope: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "Updated Scope" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForPausedScope: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "Paused Scope" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForCancelledScope: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "Cancelled Scope" notification is sent using the "Save & Send" action.',
		},

		SendNotificationsForNewQuoteRequestRecipient: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "New Quote Request" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForUpdatedQuoteRequestRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Updated Quote Request" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForPreferredQuoteRequestRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Preferred Quote Request" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForUnsuccessfulQuoteRequestRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Unsuccessful Quote Request" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForCancelledQuoteRequestRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Cancelled Quote Request" notification is sent using the "Save & Send" action.',
		},

		SendNotificationsForNewPurchaseOrderRecipient: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "New Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForUpdatedPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Updated Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForReviewedPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Reviewed Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForPausedPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Paused Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForApprovedPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Approved Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForCancelledPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Cancelled Purchase Order" notification is sent using the "Save & Send" action.',
		},
	},
	EMPLOYEE_COMMUNICATION_SETTING: {
		EmailTo_Scopes: {
			message: 'The e-mail address(es) to send notifications to for any scope-related e-mails sent from Spyderflow.',
		},
		EmailTo_QuoteRequests: {
			message: 'The e-mail address(es) to send notifications to for any quote-related e-mails sent from Spyderflow.',
		},
		EmailTo_PurchaseOrders: {
			message: 'The e-mail address(es) to send notifications to for any purchase order-related e-mails sent from Spyderflow.',
		},
		EmailTo_DefectPurchaseOrders: {
			message: 'The e-mail address(es) to send notifications to for any defect-related e-mails sent from Spyderflow.',
		},
		EmailTo_CreditRecoveryPurchaseOrders: {
			message: 'The e-mail address(es) to send notifications to for any credit or recovery-related e-mails sent from Spyderflow.',
		},

		SendNotificationsForNewScope: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "New Scope" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForUpdatedScope: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "Updated Scope" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForPausedScope: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "Paused Scope" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForCancelledScope: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "Cancelled Scope" notification is sent using the "Save & Send" action.',
		},

		SendNotificationsForNewQuoteRequestRecipient: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "New Quote Request" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForUpdatedQuoteRequestRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Updated Quote Request" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForPreferredQuoteRequestRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Preferred Quote Request" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForUnsuccessfulQuoteRequestRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Unsuccessful Quote Request" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForCancelledQuoteRequestRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Cancelled Quote Request" notification is sent using the "Save & Send" action.',
		},

		SendNotificationsForNewPurchaseOrderRecipient: {
			message: 'Send an e-mail to the contractor\'s configured e-mail address when a "New Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForUpdatedPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Updated Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForReviewedPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Reviewed Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForPausedPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Paused Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForApprovedPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Approved Purchase Order" notification is sent using the "Save & Send" action.',
		},
		SendNotificationsForCancelledPurchaseOrderRecipient: {
			message:
				'Send an e-mail to the contractor\'s configured e-mail address when a "Cancelled Purchase Order" notification is sent using the "Save & Send" action.',
		},
	},
	FINANCIAL_BILLING_SETTINGS: {
		BusinessRegistrationNumber: {
			message:
				'The business registration number which is displayed on any generated invoice documents. If not set, will use the default value from the subscriber settings.',
		},
		BillingName: {
			message: 'The billing name which is displayed on any generated invoice documents. If not set, will use the default value from the subscriber settings.',
		},
		BillingAddress: {
			message:
				'The billing address which is displayed on any generated invoice documents. If not set, will use the default value from the subscriber settings.',
		},
		BillingSuburb: {
			message: 'The billing suburb which is displayed on any generated invoice documents. If not set, will use the default value from the subscriber settings.',
		},
		BillingState: {
			message: 'The billing state which is displayed on any generated invoice documents. If not set, will use the default value from the subscriber settings.',
		},
		BillingCountry: {
			message:
				'The billing country which is displayed on any generated invoice documents. If not set, will use the default value from the subscriber settings.',
		},
		BillingPostcode: {
			message:
				'The billing postcode which is displayed on any generated invoice documents. If not set, will use the default value from the subscriber settings.',
		},
		PleaseNoteMessage: {
			message: 'Any additional comments which are displayed on any generated invoice documents under a Please Note section.',
		},
		CreditAdvicemessage: {
			message: 'Any additional comments which are displayed on any generated credit note documents under a Credit Advice section.',
		},
		BankDetails: { message: 'The bank details to be displayed on any generated invoice documents.' },
		BankName: { message: 'The default bank name which is displayed on any generated invoice documents.' },
	},
};
