import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { AppMenuConstants } from 'src/app/layout/sidebar/Constants/app.menu.constants';
import { Constants } from 'src/app/shared/constants/constants.constant';
import { FormMode } from 'src/app/shared/models/forms';
import { RegionSettings } from 'src/app/shared/models/settings';
import { FlowHistory } from 'src/app/shared/models/shared';
import { FlowHistoryService } from 'src/app/shared/services';
import { BaseComponent } from 'src/app/core/components/base.component';
import { AppContextService, NavigationService } from 'src/app/core/services';
import { ConstantExtensions } from '../../constants/constant-extensions.constant';

@Component({
	selector: 'app-flow-history',
	templateUrl: './flow-history.component.html',
	styleUrls: ['./flow-history.component.scss'],
})
export class FlowHistoryComponent extends BaseComponent implements OnInit {
	@Input() referenceTypeCode: string;
	@Input() referenceId: string;
	@Input() includeCurrent: boolean;

	@ViewChild('flowhistory') flowHistoryTable: Table;

	flowhistorydetails: FlowHistory[] = [];

	workOrderId: string;
	scopeId: string;
	quoteRequestId: string;
	serviceRequestId: string;
	invoiceId: string;

	totalRecords = 0;
	formMode = FormMode.View;
	formModes = FormMode;
	region: RegionSettings;

	item: FlowHistory;
	itemBackup: FlowHistory;

	showList = true;
	refreshRequired = false;
	timeZoneCode: string;
	isClientUser: boolean;

	constructor(appContext: AppContextService, private flowHistoryService: FlowHistoryService, private navigationService: NavigationService) {
		super(appContext);
	}

	ngOnInit(): void {
		try {
			this.formMode = this.formModes.View;
			this.isClientUser = this.appContext.getClientUser();
			this.region = this.appContext.getRegion();
			this.appContext.HasTableBasedData = true;
		} catch (ex) {
			this.util.hideWait();
			console.error(ex);
		}
	}

	public refreshData() {
		try {
			this.item = null;
			this.itemBackup = null;

			if (this.flowHistoryTable) this.flowHistoryTable.editingRowKeys = {};

			this.flowHistoryService.getFlowHistory(this.referenceTypeCode, this.referenceId, this.includeCurrent).subscribe(result => {
				this.flowhistorydetails = result;
				this.showList = true;
				this.appContext.HasTableBasedData = true;
			});
		} catch (ex) {
			this.util.hideWait();
			console.error(ex);
		}
	}

	canNavigateToDetails(dto: FlowHistory) {
		return this.getNavigationRoute(dto) != null;
	}

	handleRowClick(dto: FlowHistory) {
		if (dto.ScopeId != null) this.navigationService.navigateToDetailPage(AppMenuConstants.MenuLinks.Scopes, dto.ScopeId);
		else if (dto.QuoteRequestId != null) this.navigationService.navigateToDetailPage(AppMenuConstants.MenuLinks.Quotes, dto.QuoteRequestId);
		else if (dto.WorkOrderId != null) this.navigationService.navigateToDetailPage(AppMenuConstants.MenuLinks.WorkOrders, dto.WorkOrderId);
		else if (dto.ServiceRequestId != null) this.navigationService.navigateToDetailPage(AppMenuConstants.MenuLinks.ServiceRequests, dto.ServiceRequestId);
		else if (dto.InvoiceId != null) {
			if (ConstantExtensions.IsReceivable(dto.InvoiceTypeCode))
				this.navigationService.navigateToDetailPage(AppMenuConstants.MenuLinks.Receivables, dto.InvoiceId);
			if (ConstantExtensions.IsPayable(dto.InvoiceTypeCode)) this.navigationService.navigateToDetailPage(AppMenuConstants.MenuLinks.Payables, dto.InvoiceId);
		} else if (dto.AssetId != null) {
			if (dto.RecordTypeCode === Constants.RecordType.Asset) this.navigationService.navigateToDetailPage(AppMenuConstants.MenuLinks.Assets, dto.AssetId);
			if (dto.RecordTypeCode === Constants.RecordType.Property) this.navigationService.navigateToDetailPage(AppMenuConstants.MenuLinks.Properties, dto.AssetId);
		}
	}
	getNavigationRoute(dto: FlowHistory) {
		if (dto.ScopeId != null) return true;
		else if (dto.QuoteRequestId != null) return true;
		else if (dto.WorkOrderId != null) return true;
		else if (dto.ServiceRequestId != null) return true;
		else if (dto.InvoiceId != null) {
			if (ConstantExtensions.IsReceivable(dto.InvoiceTypeCode)) return true;
			if (ConstantExtensions.IsPayable(dto.InvoiceTypeCode)) return true;
		} else if (dto.AssetId != null) {
			if (dto.RecordTypeCode === Constants.RecordType.Asset) return true;
			if (dto.RecordTypeCode === Constants.RecordType.Property) return true;
		}

		return null;
	}

	showLink(): boolean {
		return !this.isClientUser;
	}
}
