export class AppMenuConstants {
	public static MenuLinks = {
		DashBoard: 'Dashboard',
		ServiceRequests: 'Service Requests',
		Scopes: 'Scopes',
		Quotes: 'Quotes',
		WorkOrders: 'Work Orders',
		PurchaseOrders: 'Purchase Orders',
		Invoices: 'Invoices',
		Receivables: 'Receivables',
		Payables: 'Payables',
		Assets: 'Assets',
		Properties: 'Properties',
		Reports: 'Reports',
		Calendar: 'Calendar',
		Contacts: 'Contacts',
		Clients: 'Clients',
		Contractors: 'Contractors',
		Employees: 'Employees',
		Suppliers: 'Suppliers',
		Rates: 'Rates',
		RateGroups: 'Rate Groups',
		Items: 'Items',
		Problems: 'Requests',
		Settings: 'Settings',
		Companies: 'Companies',
		Areas: 'Areas',
		Projects: 'Projects',
		Fields: 'Fields',
		SystemInterface: 'System Interface',
		IncomingEvents: 'Incoming Events',
		OutgoingEvents: 'Outgoing Events',
		IntegrationModules: 'Integration Modules',
		AccountAdmin: 'Account Admin',
		SubscriberSettings: 'Subscriber Settings',
		Users: 'Users',
		ExternalUsers: 'External Users',
		UserRoles: 'User Roles',
		DevTools: 'Dev Tools',
	};
}
