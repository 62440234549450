<p-toolbar>
	<ng-template pTemplate="left"></ng-template>
	<ng-template pTemplate="right">
		<div class="select-button-toolbar">
			<p-selectButton
				[options]="displayFilterOptions"
				(onChange)="changeFilterOption($event)"
				[(ngModel)]="selectedFilterOption"
				optionLabel="label"
				optionValue="value"
				data-testid="displayFilterOptionsSelectButton"></p-selectButton>
		</div>
	</ng-template>
</p-toolbar>
<app-related-rategroup [referenceTypeCode]="referenceTypeCode" [referenceId]="referenceId" *ngIf="isSelectedSubTab('RATE_GROUP')"> </app-related-rategroup>
<app-related-work-history [referenceTypeCode]="referenceTypeCode" [referenceId]="referenceId" *ngIf="isSelectedSubTab('WORK_HISTORY')">
</app-related-work-history>
